<!-- <app-modal  *ngIf="modal" class="list-table3" >
  <div class="modal-background" >
    <app-new-user-sidebar-stepper *ngIf="estructures" [idAction]="idAction" [params]="estructures" [relations]="tableRelations" [dataSelected]="selectedElement" (SalidaClickMenu)="closeModal($event)" [idEmpConvCreate]="selectedElement.element.idEmpConv"></app-new-user-sidebar-stepper>
  </div>
</app-modal> -->

<app-modal *ngIf="modal" style="z-index: 1000000; position: fixed; top: 0; left: 0;">
  <div  class="modal-background">
    <app-new-user-sidebar-stepper *ngIf="estructures" [idAction]="idAction" [params]="estructures" [relations]="tableRelations" [dataSelected]="selectedElement" (SalidaClickMenu)="closeModal($event)" [idEmpConvCreate]="selectedElement.element.idEmpConv"></app-new-user-sidebar-stepper>
  </div>
</app-modal>


<div *ngIf="!modal" class="slideout-content modal-body flex-grow-1 add-new-user pt-0 " >
<div class="modal-body flex-grow-1 " style="height: 100%; max-height: calc(100vh - 100px);" style="height: 100%; display: flex; flex-direction: column; background-color: white;">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="sendMessage()">
      <i class="icon ion-close"></i>
    </button>
    
    <app-main-table [TableName]="tableName" [attributes]="Atributos" [optionsDropdown]="optionsMenu" (dataRender)="emitDataSucursales($event)" [dataForSucursales]="dataForSucursales"></app-main-table>
    
    
    <div class="create-btn-container" *ngIf="canCreate">
      <!-- Crear sucursal -->

      <!-- <div class="col-sm-12 col-md-12 col-lg-12 mb-2"> -->
          <button type="submit" class="btn" style="background-color: #0195ec !important; color: white !important;" rippleEffect (click)="createSucursal()">
              <!-- <i class="fa fa-floppy-o" aria-hidden="true"></i> -->
              <!-- <img style="height: 20px;" src="../../../../assets/icons/franquicias.png" alt=""> -->
              <i class="fa fa-plus"></i>
              {{createOption.Accion}}
          </button>
      <!-- </div> -->
      <!-- <button class="btn btn-primary rounded-circle" style="margin-left: 1%;" > -->
        <!-- <i class="fa fa-pencil"></i> -->        
      <!-- </button> -->
    </div>
  </div>
</div>

<!-- <app-master-crud [TableName]="'cb_EmpresaProductos'"></app-master-crud> 
<app-new-user-sidebar [param]="cdata"></app-new-user-sidebar>-->


<!-- Coloco un combo de empresas y la que se seleccione es la que muestro en la lista -->

<!-- <app-master-crud [TableName]="'cb_EmpresaProductos'" [Atributos]="Atributos" [showCreateBtn]="true"></app-master-crud> -->
 
<!-- {
//       TitleHead: "Usuarios",
//       Table: "cb_EmpresaUser",
//       Inputs: [],
//       Value:[]//"idUserEmp_p1YdX_"
//     } -->

<app-main-crud [TableName]="TableName" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [optionsDropdown]="optionsMenu"></app-main-crud>
<div class="bgfibexclub">
  <div class="signpanel-wrapper">
    <div class="signbox signup">
      <div class="signbox-header">
        <h2>Club Fibex</h2>
        <p class="mg-b-0"> Admin </p>
      </div><!-- signbox-header -->
      <div class="signbox-body">
        <form [formGroup]="form" (ngSubmit)="doRegister(form)">
          <div class="form-group">
            <label class="form-control-label">Correo electrónico:</label>
            <input type="email" name="email" formControlName="email" class="form-control" placeholder="Escriba el correo electrónico">
          </div><!-- form-group -->
  
          <div class="row row-xs">
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Nombre:</label>
                <input type="text" name="firstname" formControlName="firstname" class="form-control" placeholder="Escriba el nombre">
              </div><!-- form-group -->
            </div><!-- col -->
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Apellido:</label>
                <input type="text" name="lastname" formControlName="lastname" class="form-control" placeholder="Escriba el apellido">
              </div><!-- form-group -->
            </div><!-- col -->
          </div><!-- row -->
  
          <div class="row row-xs">
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Contraseña:</label>
                <input type="password" name="password" formControlName="password" class="form-control" placeholder="Escriba la contraseña">
              </div><!-- form-group -->
            </div><!-- col -->
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Confirmar contraseña:</label>
                <input type="password" name="conpassword" formControlName="conpassword" class="form-control" placeholder="Vuelva a escribir la contraseña">
              </div><!-- form-group -->
            </div><!-- col -->
          </div><!-- row -->
  
          <div class="form-group">
            <label class="form-control-label">Nombre de la Empresa:</label>
            <input type="text" name="company" formControlName="company" class="form-control" placeholder="Escriba el nombre de la empresa">
          </div><!-- form-group -->
  
          <div class="form-group mg-b-20 tx-12">Al hacer clic en el botón Registrarse a continuación, acepta nuestros <a href="">Condiciones de uso</a> y nuestras <a href="">Políticas de Privacidad</a></div>
  
          <button type="submit" class="btn btn-success btn-block" [disabled]="form.invalid || isSubmitting">
            <span *ngIf="!isSubmitting">Registrarse</span>
            <span *ngIf="isSubmitting">Registrando...</span>
          </button>
          <div class="tx-center bd pd-10 mg-t-40">¿Ya tienes cuenta? <a routerLink="/signin">Iniciar Sesión</a></div>
        </form>
      </div><!-- signbox-body -->
    </div><!-- signbox -->
  </div><!-- signpanel-wrapper -->
</div>

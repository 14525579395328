<div class="modal-background">

    <section class="main_container slideout-content modal-content" >
        <span class="modal-header header" [ngClass]="{'page_header': true}">
       
        <h2 class="ion-text-center">
            Historial de notificaciones
        </h2>
        <button (click)="closeModal()" class="unset_all back_icon" style="border: none;">
            <i class="icon ion-close"></i>
        </button>
        </span>
        <div class=" modal-body flex-grow-1" style="overflow-y: auto; padding-bottom: 50px;">
              <div class="list_container ">
          <div class="div_info_text">
            <h5 class="info_text">
              ¡Estás al día!
            </h5>
          </div>
          <button (click)="handleActiveMessage(notification)" *ngFor="let notification of this.pushMessageService.notificationsRead; index as i;" class="list_item item_closed" [id]="'item_id_'+notification?.id"
       >
            <div class="dot" [ngClass]="{'hide_transparent': notification?.read_at}"></div>
            <!-- <ion-checkbox [id]="'checkbox_id_' + message.id" [checked]="false" [value]="message.id" style="margin: auto; left: 5px;" alignment="start"></ion-checkbox> -->
            <div class="col_info">
              <span class="created_at">
                {{notification?.createdAt | date: "MMM d, h:mm a"}}
              </span>
              <p class="title overflow_ellipsis">
                <strong>
                  {{notification?.title}}
                </strong>
              </p>
              <p class="description overflow_ellipsis">
                {{notification?.message}}
              </p>
    
              <div *ngIf="notification.image" class="noti-img-container description overflow_ellipsis">
                <img [src]="notification.image" alt="IMG">
              </div>
    
              <button (click)="redirectNotification($event,notification.routerLink)"  *ngIf="notification.button" expand="block" class="ion_btn_blue_style_gradient btn btn-primary" mode="ios" [routerLink]="notification.routerLink">
                {{ notification.buttonText }}
              </button>
            </div>
            <div (click)="setModalDelete(notification)" class="col_image" >
              <i  style="color: rgb(231, 33, 33);" class="icon ion-ios-trash-outline"></i>
            </div>
          </button>
          <div  *ngIf="!this.pushMessageService.getMessageIsReadLength() && this.pushMessageService.getMessageIsReadLength() == 0" class="ion-text-center empty">
           <i class="icon ion-android-notifications-off"></i>
            <h2>¡Estás al día!</h2>
          </div>
          <button (click)="showMoreRead()" class="btn_gradient_app see_all_btn btn btn-primary" *ngIf="pushMessageService.canReadMoreMessageReaded" [disabled]="pushMessageService.loadingReaded"  >
            <span *ngIf="this.pushMessageService.loadingReaded">
                
            </span>
            <span  *ngIf="!this.pushMessageService.loadingReaded">
              Ver más
              <ion-icon name="chevron-forward"></ion-icon>
            </span>
          </button>
        </div>
        </div>
      
      </section>
    <div *ngIf="modalDelete" class="modal-background index-max">
      <div class="modal-content" style="max-height: 200px;">
      <div class="modal-header">
        <p>Advertencia</p>
      </div>
      <div class="modal-body">
        Deseas eliminar el  mensaje?      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" (click)="moveTrash()">Si</button>
        <button class="btn btn-primary" (click)="modalDelete=false;">No </button>
      </div>
      </div>
    </div>
</div>


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CleanCacheService {

  constructor() { }

  /*
    * verificamos si existe cache
    * Accedemos a los elementos de cache y si existe alguno lo recorremos para luego borrarlos
  */ 

  clear() {
    if( window.caches ) { 
      console.log('hay cache', window.caches)
      caches.keys().then( keys => {
        console.log(keys)
        if(keys.length > 0) keys.forEach( key => caches.delete(key));
      });

      console.log(window.caches)
    }
  }
}

import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class DecodedtokenService {

  constructor() { }

  DecodedToken(token:string | null): any{
    if(!token ) return null;
    
    var decoded = jwt_decode(token);
    //Aca debemos validar si el token es valido  o no



    return decoded;
  }
}

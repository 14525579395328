import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss']
})
export class MoneyComponent {

  @Input() data: TipoInput = {} as any;
  @Input() value: any = "0.00";
  @Input() formData: IFormData = new IFormData();
  @Output() emitter: EventEmitter<any> = new EventEmitter();
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv
  

  ngOnInit(): void {
    // valor inicial
    Promise.resolve().then(res => this.formData.vars[this.data.Campo] = this.formData.vars[this.data.Campo] || "0.00")
  }

  HandlerOnKey(event: any) {
    delete this.formData.error[this.data.Campo];
  }
  
  HanderOnChange(event: any) {
    delete this.formData.error[this.data.Campo];

    const value: string = event.target.value.replace(/\D+/g, "");
    const decimal = value.substring((value.length - 2) > 0 ? (value.length - 2) : 0, value.length).padStart(2, "0");
    const intenger = value.substring(0, (value.length - 2) > 0 ? (value.length - 2) : 0).replace(/^0+/, "").padStart(1, "0");

    event.target.value = this.value = this.formData.vars[this.data.Campo] = intenger + "." + decimal
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-master-logs',
  templateUrl: './master-logs.component.html',
  styleUrls: ['./master-logs.component.scss']
})
export class MasterLogsComponent {
  tableList = [
    'cb_LogSystem'
  ]

  public optionsMenu =[
    {id:1, name: 'Eliminar', icon:'icon ion-trash-a'},
    {id:2, name:'Editar', icon:'icon ion-edit'}
  ]

  tableRelations = [

  ]

  TableName = 'cb_LogSystem'

  attributes = { title: 'Historial de Logs', description: 'Listado control de los registros e incidencias' }
}

<div class="sh-pagebody">
    <div class="card bd-primary mg-t-20">
      <div class="card-header bg-primary tx-white">{{ FieldAndHead?.title }}</div>
      <div class="card-body pd-sm-30">
        <p class="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>

        <div class="table-wrapper">
            <div [class]="['table-responsive'].concat(tableClasses || '')">
                <table id="datatable1" class="table display responsive nowrap">
                <thead>
                    <tr>
                        <th *ngFor="let head of FieldAndHead?.head" class="wd-15p">{{ head }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let items of FieldAndHead?.data">
                        <td *ngFor="let item of items">{{ item }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div><!-- table-wrapper -->
      </div><!-- card-body -->
    </div><!-- card -->
</div><!-- sh-pagebody -->

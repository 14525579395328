<div>

<div class="mg-t-20 mg-lg-t-0 mb-3">
    <select #selectList class="form-control select2-show-search" id="select2-state" data-placeholder="Choose one (with searchbox)" (click)="setValue(selectList.value)">
      <!-- <option value=""></option> -->
      <option *ngFor="let title of ArrayDataIn" [value]="title.value" [selected]="title.value === valueUserEmp">{{title.datoShow}}</option> 
    </select>
  </div>

  <!--  todo: Añadir select con escritura -->


  <!-- <ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-center">
      <span>
        test text
      </span>

      <button class="btn btn-danger">
        <i class="icon ion-trash-a"></i>
      </button>
    </li>
  </ul> -->
</div>
import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-aprobaciones',
  templateUrl: './master-aprobaciones.component.html',
  styleUrls: ['./master-aprobaciones.component.scss']
})
export class MasterAprobacionesComponent {

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  public TableName: string = 'cb_Aprobaciones';

  public tableList = [
    'cb_Aprobaciones',
    // 'cb_EmpresaSucursal',
    // 'cb_SucursalDir'
  ]
  // public tableListToEdit = [
  //   'cb_Aprobaciones'
  // ]

  tableRelations = [
    // {
    //   tablaorigen: "cb_PromoProductos",
    //   campoorigen: "idProducto",
    //   tabladestino: "cb_ProductosFotos",
    //   campodestino: "idProducto"
    // },
    {
      tablaorigen: "cb_PromoProductos",
      campoorigen: "idPromo",
      tabladestino: "cb_PromoSucursal",
      campodestino: "idPromo"
    },
    {
      tablaorigen: 'cb_Promo',
      campoorigen: 'idPromo',
      tabladestino: 'cb_PromoProductos',
      campodestino: 'idPromo'
    },
    {
      tablaorigen: 'cb_PromoProductos',
      campoorigen: 'idPromo',
      tabladestino: 'cb_Filtro',
      campodestino: 'idPromo'
    },
    // {
    //   tablaorigen: 'cb_EmpresaConvenio',
    //   campoorigen: 'idEmpConv',
    //   tabladestino: 'cb_EmpresaSucursal',
    //   campodestino: 'idEmpConv'
    // },
    // {
    //   tablaorigen: 'cb_EmpresaSucursal',
    //   campoorigen:'idSucursal',
    //   tabladestino: 'cb_SucursalDir',
    //   campodestino: 'idSucursal'
    // }
  ]

  public attributes = { title: 'Convenios', description: 'Listado control de los convenios' };

  // ngOnInit(): void {
  //   const isAdmin = JSON.parse(sessionStorage.getItem("login") || "{ admin: false }").admin;

  //   if(!isAdmin) this.optionsMenu = [{id:3, name:'Sucursales', icon:'fa fa-building'}]
  // }

  public filterBy = [
    {
      name: "idAprobacion",
      value: 0
    }
  ]

  BeforeSubmit = async (vars: {[key: string]: unknown}, tablename: string) => {
    if(tablename === "cb_Aprobaciones") {
      // if(this.authService.isMaster()) {
      //   vars["idAprobacion"] =
      // }
      // else {

      // }
    }

    return vars;
  }

  ClickMenu(ev: any) {
    console.log(ev);
  }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {
    this.initEstructure()
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterViewAprobacionesComponent' && e.Tipo === 'Table')
      const optionsResult = options ? this.helper.sortOptions(options) : []
      console.log("LISTA DE ACCIONES DE","APROBACIONES",": ",options);
      if(optionsResult.length > 0) optionsResult[0] = {...optionsResult[0], tableList: ['cb_Aprobaciones']}
      this.optionsMenu = optionsResult
  }
}

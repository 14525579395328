import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-products',
  templateUrl: './master-products.component.html',
  styleUrls: ['./master-products.component.scss']
})
export class MasterProductsComponent {

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  tableList = [
    'cb_EmpresaProductos',
    'cb_ProductosFotos',
    'cb_ProductosSucursal'
  ]

  tableRelations = [
    {
      tablaorigen: 'cb_EmpresaProductos',
      campoorigen: 'idProducto',
      tabladestino: 'cb_ProductosFotos',
      campodestino: 'idProducto'
    },
    {
      tablaorigen: 'cb_EmpresaProductos',
      campoorigen: 'idProducto',
      tabladestino: 'cb_ProductosSucursal',
      campodestino: 'idProducto'
    }
  ]

  /*
      {
      tablaorigen: 'cb_EmpresaProductos',
      campoorigen:'idProducto',
      tabladestino: 'cb_ProductosSucursal',
      campodestino: 'idProducto'
    }
  */ 

  TableName = 'cb_EmpresaProductos'

  attributes = { title: 'Productos', description: 'Listado control de productos' }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {    
    this.initEstructure()    
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterProductsComponent' && e.Tipo === 'Table')
      this.optionsMenu = options ? this.helper.sortOptions(options) : []      
  }
}

import { IFilterForm } from "src/app/shared/components/filter-form/interfaces/IFilterForm.interface";

export function getMockFilterForms(): Promise<IFilterForm[]> {
  let filterForms: IFilterForm[] = [
    {
      id: 1,
      name: 'Detalle de facturacion',
      forms: [],
      childrenFilterForms: [
        {
          id: 2,
          name: 'Facturacion',
          forms: [
            {
              id: 1,
              name: '...',
              submitTarget: '/api/forms/submit', // just an example endpoint
              formGroups: [
                {
                  id: 1,
                  name: 'filtro de facturacion',
                  order: 1,
                  styleRules: null,
                  formControls: [
                    {
                      id: 1,
                      order: 1,
                      description: null,
                      control: {
                        name: 'franquicia',
                        tag: 'select',
                        label: 'franquicia',
                        style_rules: {
                          width: 3
                        },
                        attributes: {
                          placeholder: 'seleccione...'
                        },
                        data: []
                      },
                      dataSource: null
                    }
                  ]
                }
              ]
            }
          ],
          childrenFilterForms: []
        }
      ]
    },
    {
      id: 3,
      name: 'Resumen de Facturacion',
      forms: [],
      childrenFilterForms: []
    }
  ];

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(filterForms)
    }, 100);
  });
}
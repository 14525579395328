import { Component, Input } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-ext-avatar',
  templateUrl: './ext-avatar.component.html',
  styleUrls: ['./ext-avatar.component.scss']
})
export class ExtAvatarComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: string | undefined = "";
  @Input() formData: IFormData = new IFormData();

  public avatarsData: string[] = [];
  public image: any;
  

  ngOnInit() {
    console.log(this.value)
    console.log(this.formData)
    
    try {
      const valueDefault = JSON.parse(this.value || "");
      if(valueDefault instanceof Array) {
        // Preparar el valor por defecto
        this.avatarsData = valueDefault;
        this.image = valueDefault;
      }
    }
    catch(err) {
      console.log("No se logró hacer el JSON.parce()... ignorando...");
    }
    this.ordenar();
    this.eliminar();
    console.log('se ejecuto el eliminar...', this.avatarsData);
  }

  HanderOnChange(url: string, index: number) {
  
    
    this.avatarsData[index] = url;
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = JSON.stringify(this.avatarsData);
    
  }

  HandlerOnChangeAdd(url: string) {
    
    this.avatarsData.push(url);
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = JSON.stringify(this.avatarsData);

  }

  onDelete(event: any, i:number): void {
    console.log('aca paso')
    this.HanderOnChange('', i);
    this.avatarsData = this.avatarsData.filter((_, idx) => idx !== i);    
    
  }


  ordenar() {
     this.avatarsData.sort((a, b) => {
      if (a === '' && b !== '') {
          return 1;
      } else if (a !== '' && b === '') {
          return -1;
      } else {
          return 0;
      }
      });
  }

  eliminar() {
    for (var index = 0; index < this.avatarsData.length; index++) {
      if (this.avatarsData[index] === '') {
          this.avatarsData = this.avatarsData.filter((_, idx) => idx !== index);
      }
    }
  }
}

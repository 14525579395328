import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import axios from "axios";
import { AuthService } from './auth.service';
import { dbFullService } from './dbFull.service';
import { Icb_LogSystem } from '../models/tables';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private authS: AuthService,
    private _dbFullService: dbFullService
  ) { }
  private pkLog = environment.audit.pkLog; //primary Key Log
  private tableName = environment.audit.tableLog;  //table name LogSsytem
  
  async createLog(idUser: string, action: string, detail: string){
    //!TODO: obtener la direccion ip del usuario
    const IP = '127.0.0.1'


    const body = {
      idUserEmp:idUser,
      idAccion:action,
      Detail: detail,
      IP
    }

    console.log(body)

    return new Promise(async(resolve,reject)=>{
      
        let config = {
            method: 'post',
            url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${environment.logs.table}`,
            headers: { 
            'x-keys-to-add-id': `["${environment.logs.id}"]`, 
            'x-keys-of-arrays': '[]', 
            'x-relations': 'false', 
            'Content-Type': 'application/json'
            },
            data : body
        };
        axios.request(config)
        .then((response) =>{
          // console.log(response)
          resolve(response.data)
        })
        .catch((error) =>{
          // console.log(error)
          reject(error)
        });
    })
  }


 async generateAuditory(idAccion: string, detail?: string): Promise<any>{
  
    let idUser = "123456";
    (this.authS.isMaster()) ? idUser=this.authS.GetUserMaster()?.idUser! : idUser = this.authS.GetUserEmpresa()?.idUserEmp!;
    console.log(idUser);
    let ip="";
     await this.authS.getIP().then(resp=>{ip=resp});
  

    //POR AHORA SOLO QUIERO PROBAR
    let logElement: Icb_LogSystem = await {
       idAccion: idAccion,
       idUserEmp: idUser,
       IP: ip,
       Status: true,
       Detail: detail
    } ;
    return await logElement;
  }
  async createLogs(idAction: string,detail: string){
    //#1 CREAMOS EL OBJETO DEL LOG
    const logData = await this.generateAuditory(idAction,detail)

    console.log(logData);
     //#2 CONSULTA
  await this._dbFullService.CreateData(this.tableName,this.pkLog,logData)
  .then((resp:any)=>{
    console.log("response",resp);
    //this.getAllLogsById();
    return resp;
  })
  .catch((error:any)=>{
    return error;
  })
 



  }
  async getAllLogsById(): Promise<any>{
  await this._dbFullService.GetAllDataCampo(this.tableName,"idUserEmp","123456")
  .then((resp:any)=>{
    console.log(resp);
    return resp})
  .catch(error=>{return error})
  ; 
  }
}

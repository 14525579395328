
<div>
    <div *ngIf="openCreateElement" class="list-table2 list-table2-showsize">
        <app-new-user-sidebar  (SalidaClickMenu)="toCloseCreateElement($event)" [param]="DataFormOpen"></app-new-user-sidebar>
    </div>
    <div *ngIf="!openCreateElement && !openCreateELementInit" class=" list-table2 list-table2-hiddensize">
        <app-new-user-sidebar  (SalidaClickMenu)="toCloseCreateElement($event)"></app-new-user-sidebar>
    </div>
    <div *ngIf="openCreateElement" (click)="toCloseCreateElement()" class="list-table2-show" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: absolute; width: 100%; height: 100%; z-index: 80;">
        aqui1
    </div>
    <div *ngIf="!openCreateElement && !openCreateELementInit" (click)="toCloseCreateElement()" class="list-table2-hidden" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: absolute; width: 100%; height: 100%; z-index: 80;">
        aqui2
    </div>


    <!-- <app-pagetitle></app-pagetitle> -->
    <!-- <div style="overflow: auto; margin-right: 20px; margin-bottom: -10px;" *ngIf="showCreateBtn">
        <button (click)="toOpenCreateElement()"class="btn btn-primary mg-b-10" style="float:right;"><i class="fa fa-plus mg-r-10"></i>Crear Elemento</button>
    </div>  -->

    <!-- Lista Simple de una tabla-->
    <app-list-table2 *ngIf="Atributos?.tipo!='lista'" [TableName]="TableName" [options]="optionsMenu" [Atributos]="Atributos" [Estructura]="DataFormOpen" [isNecesaryUpdate]="updateData"></app-list-table2>
    <!-- Lista Simple de una Vista
    <app-list-table2 *ngIf="Atributos?.tipo!='lista_vista'" [TableName]="TableName" [options]="options" [Atributos]="Atributos"></app-list-table2>
    <-- Agrega en la misma lista se puede usar para los proctos
    <app-list-table-crud *ngIf="Atributos?.tipo=='lista_insert'" [TableName]="TableName" [options]="options" [Atributos]="Atributos"></app-list-table-crud>
    -->
</div>

import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-promotions-new',
  templateUrl: './master-promotions-new.component.html',
  styleUrls: ['./master-promotions-new.component.scss']
})
export class MasterPromotionsNewComponent {

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  tableList = [
    'cb_Promo',
    'cb_PromoProductos',
    'cb_Filtro'
  ]

  tableRelations = [
    {
      tablaorigen: 'cb_Promo',
      campoorigen: 'idPromo',
      tabladestino: 'cb_PromoProductos',
      campodestino: 'idPromo'
    },
    {
      tablaorigen: 'cb_PromoProductos',
      campoorigen: 'idPromo',
      tabladestino: 'cb_Filtro',
      campodestino: 'idPromo'
    }
  ]

    //Referencia
    tableAsociacion = [
      {
        table: 'cb_PromoProductos',
        campoSearch: 'idEmpConv',
        tablavalor:'cb_Promo'
      }
    ]

  TableName = 'cb_Promo'

  attributes = { title: 'Promociones', description: 'Listado control de las promociones' }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {    
    this.initEstructure()    
  }
  
  async initEstructure() {
    const { Data } = this._SesionstorageService.GetSesionStorage('login')
    const actions = await this.auth.retrieveActions(Data.idRol)
    const options = actions.filter( (e: any) => e.Componente === 'MasterPromotionsComponent' && e.Tipo === 'Table')
    this.optionsMenu = options ? this.helper.sortOptions(options) : []    
  }
}

// TypeScript

import { CdkPortal, DomPortalOutlet } from "@angular/cdk/portal";
import { AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, Injector, OnDestroy, ViewChild } from "@angular/core";

@Component({
	selector: 'app-portal',
	template: `<ng-template #templatePortal cdk-portal>
	<ng-content></ng-content>
  </ng-template>`,
	// styleUrls: ['./portal.component.scss']
  })
// ActionButtonComponent
export class PortalComponent implements AfterViewInit, OnDestroy{

	@ViewChild(CdkPortal)
	private portal!: CdkPortal;
	private host!: DomPortalOutlet;
  
	constructor(
	  private cfr: ComponentFactoryResolver,
	  private appRef: ApplicationRef,
	  private injector: Injector
	) {}

    ngOnDestroy(): void {
        // throw new Error("Method not implemented.");
		this.host.detach();
    }
	
	ngAfterViewInit(): void {
	  this.host = new DomPortalOutlet(
		document.body,
		this.cfr,
		this.appRef,
		this.injector
	  );
	  this.host.attach(this.portal);
	}
  }
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Itm_notifications_clients } from 'src/app/models/tables';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { PushNotificationService } from 'src/app/services/pushNotification.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-modal-notifications',
  // standalone: true,
  // imports: [
  //   CommonModule,
  // ],
  templateUrl: './modalNotifications.component.html',
  styleUrls: ['./modalNotifications.component.scss'],
})
export class ModalNotificationsComponent { 
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  public modalDelete = false;
  public currentMessageSelected!: Itm_notifications_clients | Itm_notifications_clients[];
  constructor(public pushMessageService: PushNotificationService, private _sessionStorage: SesionstorageService, 
    private obsServer: ObservableComponentsService, private Router: Router

  ){
    
  }
  showMoreRead() {
    try {

      const user = this._sessionStorage.GetSesionStorage("login").Data;
      console.log(user);
      
      if(user) {
        this.pushMessageService.loadMoreMessagesReaded(user.idUser ?? user.idEmpConv);
        // this.cdr.detectChanges();
      }
      
    }
    catch(err) {
        console.error(err);
    }
  }
  handleActiveMessage(message: Itm_notifications_clients) {
    const content: any = document.getElementById(`item_id_${message.id}`);

    //Abre el contenido del item seleccionado
    if (content.className.includes('item_closed')) {
      content.classList.remove('item_closed')
      content.classList.add('item_active')
    }
    else {
      content.classList.remove('item_active')
      content.classList.add('item_closed')
    }
  }
  moveAllTrash() {
    if (this.pushMessageService.notificationsRead.length > 0) {
      this.pushMessageService.notificationsRead.forEach((message, index: number) => {
        this.pushMessageService.deleteMessages(message);
        if (this.pushMessageService.notificationsRead.length - 1 === index) this.pushMessageService.notificationsRead = [];
      });
    }
  }
  moveTrash(){
    this.pushMessageService.deleteMessages(this.currentMessageSelected)
    this.modalDelete = false;
  }
  public closeModal(){
    this.SalidaClickMenu.emit();
  }
  redirectNotification(event: Event,url: string){
    event.stopPropagation();
    console.log(url);
    
    //DESESTRUCTURAMOS EL URL OBTENIDO DEL ADMINISTRADOR
    const [urlAdmin,qParams] = url.split("?--");
    if(qParams){
      const dataConcatenada = qParams.split(`=`);
      //CREAMOS EL OBJETO NECESARIO PARA DESESTRUCTURAR
      let object = {[dataConcatenada[0]]: dataConcatenada[1]};
      console.log(object);
      //REESTRUCUTURACION
     this._sessionStorage.SetSesionStorage("dataRedirection",JSON.stringify(object));
      this.Router.navigate([`./dashboard/${urlAdmin}`])
      this.obsServer.notificationButton$.emit(object);
      this.closeModal();
    }
  }
  setModalDelete(message: Itm_notifications_clients){
  this.currentMessageSelected = message;
  this.modalDelete = true;
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { dbFullService } from './dbFull.service'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  $userListener = new EventEmitter<any>()

  constructor(
    private dbFull: dbFullService

  ) {}

  /**
 * Formatea una fecha en formato 'aaaa-mm-dd' o 'dd-mm-aaaa'.
 * @param date La fecha a formatear en formato de string (opcional).
 *   Si no se proporciona, se utiliza la fecha actual.
 * @param typeFormat El tipo de formato de fecha a devolver (opcional):
 *   0: formato 'aaaa-mm-dd' (por defecto).
 *   1: formato 'dd-mm-aaaa'.
 * @returns La fecha formateada como string en el formato especificado.
 */
  formatDate(date: string = '', typeFormat: number = 0): string {
    const d = date ? new Date(date) : new Date();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear();

    if(isNaN(+month) || isNaN(+day) || isNaN(+year)) {
      return 'S/I'
    }

    switch(typeFormat) {
      case 2: // básico para el uso de fechas únicas aaaa-mm-dd
        return [year.toString().padStart(4, "0"), month.padStart(2, "0"), day.padStart(2, "0")].join('-');

      case 1: // dd/mm/aaaa
        return [day.padStart(2, "0"), month.padStart(2, "0"), year.toString().padStart(4, "0")].join('/');

      default: // aaaa/mm/dd
        return [year.toString().padStart(4, "0"), month.padStart(2, "0"), day.padStart(2, "0")].join('/');
    }
  }

  /**
   * Sanea una cadena de texto para evitar inyección de código malicioso.
   * @param value La cadena de texto a sanear.
   * @returns La cadena de texto saneada.
   */
  sanitizeString(value: string): string {
    const sanitizedValue = value.replace(/[^\w\s]/gi, '');
    return sanitizedValue.trim();
  }

  /**
 * Recupera la acción de un módulo y ruta específicos.
 * @param route La ruta de la aplicación (por ejemplo, 'empresa/franquicias').
 * @returns El identificador de la acción en la base de datos.
 */
  async retrieveAction(route: string): Promise<any> {
    const moduleMatch = route.match(/empresa|configuracion|promociones|Login|Logout/);

    if (moduleMatch) {
      const module = moduleMatch[0];
      const urlMatch = route.match(/franquicias|cat_empresas|cate_prod|consulta|usuarios|nueva|productos/);
      const url = urlMatch ? urlMatch[0] : '';
      const qRoute = `${module}/${url}`;
      const result = await this.dbFull.GetAllDataCampo('cb_Accion', 'Modulo', module);
      const action = result.find(element => element.Url === qRoute);

      return action ? action.idAccion : 0;
    } else {
      return 0;
    }
  }

  sortOptions( options:any ){
    // console.log(options)

     let emptyOptions: any = options.map((e: any, i: any) => {
      const element = this.restructureOption(e.Accion,e.idAccion)
      element!.id = i
      return element
    })

    // for (const option of options) {
    //   emptyOptions.push(this.restructureOption(option.Accion));
    // }

    return emptyOptions.sort((a:any, b:any)=>{
      return a.name > b.name ? 1 : -1
    })
  }

  restructureOption(action: string,idAccion: string){

    if(action.includes('EDITAR')) return {id:0, name:action, icon:'icon ion-edit',idAccion: idAccion}
    if(action.includes('ELIMINAR')) return {id:0, name:action, icon:'icon ion-trash-a',idAccion: idAccion}
    if(action.includes('SUCURSALES')) return {id:0, name:action, icon:'fa fa-building',idAccion: idAccion}
    if(action.includes('DETALLE')) return {id:0, name:action, icon:'fa fa-eye',idAccion: idAccion}
    if(action.includes('DESTACAR')) return {id:0, name:action, icon:'fa fa-star',idAccion: idAccion}
    if(action.includes('ELIMINAR DESTACADO')) return {id:0, name:action, icon:'icon ion-trash-a',idAccion: idAccion}

    return
  }

  getDate(str: string){
    if(!this.isValidDate(str)) return ' ';
    return new Date(str).toLocaleDateString()
  }

  getTime(str: string){
    return new Date(str).toLocaleTimeString('en-US')
  }

  isValidDate(date: string){
    return !isNaN(new Date(date).getTime())
  }
  formatDatehyphen(date: string){
    return date.replace(/-/g, '/');
  }
  formateTimeAMPM(data: string){
    const [hora, minuto, segundo] =  data.split(':');
    console.log("FECHA HORAAAA: "+data);
    // Convertir la hora a un número
    const horaNum = parseInt(hora, 10);

    // Determinar si es AM o PM
    const periodo = horaNum >= 12 ? 'PM' : 'AM';

    // Ajustar la hora si es PM
    const hora12 = horaNum > 12 ? horaNum - 12 : horaNum;
    const horaFormateada = hora12.toString().padStart(2, '0');

    // Crear la cadena formateada en formato "hh:mm:ss AM/PM"
    return `${horaFormateada}:${minuto}:${segundo} ${periodo}`;


  }
}

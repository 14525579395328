import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import {FeaturedStructureComponent} from '../featured-structure/featured-structure-component'

@Component({
  selector: 'app-featured-companies',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName"  [dragable]="true" [idColName]="'idFeatured'"  [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [optionsDropdown]="optionsMenu" [canShowInput]="canShowInputs" [filterBy]="filterCom"></app-main-crud>`,
  styleUrls: [],
  providers: [
    {provide: 'TABLE_NAME',useValue: 'cb_Featured'},
    {provide: 'TABLE_LIST',useValue: [
      'cb_Featured',
     
    ]},
    {provide: 'TABLE_RELATIONS', useValue: []},
    {provide: 'ATTRIBUTES', useValue: { title: 'Aliados Destacados', description: 'Listado control de las aliados destacados' }}
  ]
})
export class FeaturedCompaniesComponent extends FeaturedStructureComponent { public filterCom =[ { name: "type", value: "1" }]}


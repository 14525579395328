import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { IModule } from 'src/app/core/interfaces/IModule.interface';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  // styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public breadcrumb: string[] = [];
  public pagetitleIcon: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    public dashboardModulesService: DashboardModulesService
  ) { }

  ngOnInit(): void {

    // when user first opens the dashboard, gather data about current route and update the service that shares this data
    this.route.firstChild!.data.subscribe((data: any) => {
      this.dashboardModulesService.setCurrentModule(data.module);
      const pathOfModules = this.dashboardModulesService.getPathOfModulesById(this.dashboardModulesService.currentModule.id)!;
      this.updateView(pathOfModules);
    });

    // when the route changes (a different module is selected), update the service that shares module data to reflect the newly selected route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // check new route query params
        this.route.firstChild?.queryParams.subscribe((params: any) => {
          // THIS CASE IS STRICTLY JUST FOR THE /dashboard/modules ROUTE
          console.log("PASA POR AQUI >>>>>>>>>>>> en params")
          if (params.modules) {
            console.log('SI HAY MODULOS', params.modules)
            // get modules from parameter
            const cipherModules = this.route.snapshot.queryParamMap.get('modules')!;
            const decryptedModules = this.encryptionService.decryptRouteParameter<IModule[]>(cipherModules);
            let pathOfModules = this.dashboardModulesService.getPathOfModulesById(decryptedModules[0].id)!;
            pathOfModules.pop(); // do this to get have the parent module as the lead
            this.dashboardModulesService.setCurrentModule(pathOfModules[pathOfModules.length - 1]); // current module is the module that contains the children
            console.log(pathOfModules)
            this.updateView(pathOfModules);
          }
        });

        // check new route data
        this.route.firstChild!.data.subscribe((data: any) => {
          console.log('pasa por aqui en route firstchild subscribe', data)
          if (data.module) {
            this.dashboardModulesService.setCurrentModule(data.module);
            const modules = this.dashboardModulesService.getPathOfModulesById(this.dashboardModulesService.currentModule.id)!;
            this.updateView(modules);
          }
        })
      }
    });
  }

  private updateView(pathOfModules: IModule[]): void {
    // update breadcrumb
    this.breadcrumb = pathOfModules.map((module) => module.name);

    // update pagetitle icon
    for (let i = pathOfModules.length - 1; i >= 0; i--) {
      if (pathOfModules[i].styleRules && pathOfModules[i].styleRules!.icon) {
        // this.pagetitleIcon = pathOfModules[i].styleRules!.icon!;
        this.pagetitleIcon = pathOfModules[i].titleIcon;
        break;
      }
    }
  }
}

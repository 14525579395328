import { Injectable } from '@angular/core';

interface ContrassColor {
  min: number,
  max: number,

  minRed?: number,
  maxRed?: number,

  minGreen?: number,
  maxGreen?: number,

  minBlue?: number,
  maxBlue?: number,

  rangeContrass: number,
}

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

  GetDynamicColor(): string {
    // const red =   Math.round(this.Range(min, max));
    // const green = Math.round(this.Range(min, max));
    // const blue =  Math.round(this.Range(min, max));
    
    // return `rgba(${red}, ${green}, ${blue})`
    return this.GetDynamicContrassColor({
      min: 0,
      max: 255,
      rangeContrass: 100,
      
      minRed: 0,
      maxRed: 150,

      minGreen: 200,
      maxGreen: 222,

      minBlue: 150,
      maxBlue: 255
      
    });
  }

  /**
   * Permite estraer un color que no pueda ser afectado a los contrastes de blanco o negro
   * @param range rango del color el cual no se permite que sean igual con todos los colores
   * @param max intensisad maxima de los colores
   * @param min intencisad minima de los colores
   * @returns color en formato css
   */
  GetDynamicContrassColor({
    min = 0,
    max = 255,
    rangeContrass = 100,
    minRed,
    maxRed,
    minGreen,
    maxGreen,
    minBlue,
    maxBlue
  }: ContrassColor): string {
    let vred =   Math.round(this.Range(minRed ?? min, maxRed ?? max));
    let vgreen = Math.round(this.Range(minGreen ?? min, maxGreen ?? max));
    let vblue =  Math.round(this.Range(minBlue ?? min, maxBlue ?? max));

    const promedio = ((vred + vgreen + vblue) / 3);
    const promedioMin = ((minRed ?? min) + (minGreen ?? min) + (minBlue ?? min) / 3);
    const promedioMax =  ((maxRed ?? max) + (maxGreen ?? max) + (maxBlue ?? max) / 3);

    const priority = (promedio - promedioMin) - (promedio - promedioMax);

    if(priority >= 0) {
      if(
        vred >= ((maxRed ?? max)-rangeContrass) &&
        vgreen >= ((maxGreen ?? max)-rangeContrass) &&
        vblue >= ((maxBlue ?? max)-rangeContrass)
      ) {
        // obtener rango del 0 a 2
        switch(this.Range(1, 3)) {
          case 1: // rojo
              vred -= rangeContrass;
            break;
  
          case 2: // verde
              vgreen -= rangeContrass;
            break;
  
          case 3: // azul
              vblue -= rangeContrass;
            break;
        }
      }
    }
    else {
      if(
        vred <= ((minRed ?? min)+rangeContrass) &&
        vgreen <= ((minGreen ?? min)+rangeContrass) &&
        vblue <= ((minBlue ?? min)+rangeContrass)
      ) {
        // obtener rango del 0 a 2
        switch(this.Range(1, 3)) {
          case 1: // rojo
              vred += rangeContrass;
            break;
  
          case 2: // verde
              vgreen += rangeContrass;
            break;
  
          case 3: // azul
              vblue += rangeContrass;
            break;
        }
      }
    }

    const red = Math.round(vred > 255 ? 255 : (vred < 0 ? 0 : vred));
    const green = Math.round(vgreen > 255 ? 255 : (vgreen < 0 ? 0 : vgreen));
    const blue = Math.round(vblue > 255 ? 255 : (vblue < 0 ? 0 : vblue));
    
    return `rgba(${red}, ${green}, ${blue})`
  }

  private Range(min: number, max: number): number {
      return Math.round((Math.random()*(max-min))+min);
  }
}

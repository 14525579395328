import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();
  @Output() ValueSalida = new EventEmitter<boolean | number>();  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv

  public msgInvalid: string | undefined;
  public status: boolean | undefined;
  public readingMode: boolean = false;
  ngOnInit() {
    // valor inicial
    this.data.Reading && this.data.Reading===1 ?   this.readingMode=true : this.readingMode=false;
    console.log(this.readingMode);
    console.log(this.formData);
    this.formData.vars[this.data.Campo] = this.formData.vars[this.data.Campo] ?? true;
    this.ValueSalida.emit(this.formData.vars[this.data.Campo]);
  }
  
  HandlerOnKey() {
    delete this.formData.error[this.data.Campo];
  }
  
  ToggleValue() {
    console.log(this.readingMode);
    if(!this.readingMode){
        // console.log(this.value);
    // this.outdata.emit({name: this.data.Campo, value: !this.value, isInvalid: Boolean(this.msgInvalid)});
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = !this.formData.vars[this.data.Campo];
    this.ValueSalida.emit(this.formData.vars[this.data.Campo]);
    }
  
  }
}

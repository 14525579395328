<div class="sh-logopanel" style="background-color: #fff; box-shadow: 0px 0px 36px 0px;">
  <!-- <a href="" class="sh-logo-text">Club Fibex</a> -->
  <img width="86%" src="../../../../../assets/fibexClub/fibexClubLogo.svg" alt="" (click)="navigate()" class="image">
  <a id="navicon" href="" class="sh-navicon d-none d-xl-block" style="color: #1c4b6b"><i class="icon ion-navicon"></i></a>
  <a id="naviconMobile" href="" class="sh-navicon d-xl-none" style="color: #1c4b6b"><i class="icon ion-navicon"></i></a>
</div><!-- sh-logopanel -->

<div class="sh-sideleft-menu" style="background-color: #fff;border: var(--border-light);" #sideMenu>
  <label class="sh-sidebar-label text-light" style="border-bottom: 2px solid#1c4b6b; width: 100%; font-weight: 600; font-size: 12px;">Navegador</label>
  <ul class="nav">
    <app-module-option *ngFor="let module of dashboardModulesService.modules" [module]="module"></app-module-option>
  </ul>

</div><!-- sh-sideleft-menu -->

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { ilogin } from 'src/app/core/interfaces/Ilogin.interface';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LogsService } from 'src/app/services/logs.service';
import { CleanCacheService } from 'src/app/services/clean-cache.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  form!: FormGroup;
  private _auth!: ilogin;
  public email: string = '';
  public firstname: string = '';
  public lastname: string = '';
  public password: string = '';
  public conpassword: string = '';
  public company: string = '';
  public msgErr: string = '';
  public isSubmitting: boolean = false;
  public errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private dbFullService: dbFullService,
    private router: Router,
    private confServ: ConfigurationService,
    private helper: HelperService,
    private logs: LogsService,
    private cacheServ: CleanCacheService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      password: ['', Validators.required],
      conpassword: ['', Validators.required],
      company: ['', Validators.required]
    });
  }

  async doRegister(form: FormGroup): Promise<void> {
    try {
      this.isSubmitting = true;
      this.errorMessage = '';
      const { email, firstname, lastname, password, conpassword, company } = form.value;
      const sanitizedEmail: string = this.helper.sanitizeString(email);
      const sanitizedFirstname: string = this.helper.sanitizeString(firstname);
      const sanitizedLastname: string = this.helper.sanitizeString(lastname);
      const sanitizedPassword: string = this.helper.sanitizeString(password);
      const sanitizedConPassword: string = this.helper.sanitizeString(conpassword);
      const sanitizedCompany: string = this.helper.sanitizeString(company);
      // TODO: Joseph. Conectar con servicio de registro y finalizar lógica
      // this.dbFullService.getLoginDataQuery(
      //   'cb_EmpresaUser',
      //   sanitizedEmail,
      //   // sanitizedFirstname,
      //   // sanitizedLastname,
      //   // sanitizedPassword,
      //   // sanitizedConPassword,
      //   sanitizedCompany
      // )
      // .then((res: any) => {
      //   // console.log('cb_EmpresaUser res', res)
      //   if (!res || res.length <= 0) {
      //     this.dbFullService.getLoginDataQuery('cb_UserInterno', sanitizedEmail, sanitizedPassword).then((res: any) => {
      //       // console.log('cb_UserInterno res', res)
      //       if (res.length > 0 && res[0].Status === '1') {
      //         this._auth = res[0];
      //         // console.log("Login get custom string")

      //       // console.log("Authentication:", this._auth);
      //         sessionStorage.setItem('login', this.customStringify({
      //           ...this._auth,
      //           admin: ((res as any)[0].idRol) !== "uiVGBPD5Jcw2cJV8" // <== id del rol de usuario (no adminitrador)
      //         }));
      //         // this.createLog(res[0])
      //         this.router.navigate(['/dashboard/home']);
      //       } else {
      //         this.msgErr = 'Clave Inválida'
      //         // console.log('Clave Inválida')
      //         this.errorMessage = 'Usuario o contraseña incorrectos.';
      //       }
      //     });
      //   } else {
      //     this._auth = res[0];
      //     // this.createLog(res[0])
      //     // console.log(res[0])
      //     this.dbFullService.getPermissionsQuery(res[0].idRol).then(res => {
      //       // console.log("ROL:", res);
      //       // console.log("User cliente:", this._auth);
      //     sessionStorage.setItem('login', this.customStringify({
      //       ...this._auth,
      //       admin: ((res as any)[0].idRol) !== "uiVGBPD5Jcw2cJV8" // <== id del rol de usuario (no adminitrador)
      //     }));
      //       this.confServ.loadConfigurationData(res).then(res=>{
      //         this.router.navigate(['/dashboard/home']);
      //       })
      //     })
      //   }
      // })
    } catch (error) {
      console.error(error);
      this.errorMessage = 'Ha ocurrido un error al iniciar sesión. Por favor, intenta de nuevo más tarde.';
    } finally {
      this.isSubmitting = false;
    }
  }

  customStringify(obj: any): string {
    return JSON.stringify(obj);
  }

}

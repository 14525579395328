import { Component, Input, OnInit } from '@angular/core';
import { IEmpProduct } from 'src/app/models/tables';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: IEmpProduct = {
    ImgURL: '',
    Nombre: '',
    Precio: '',
    Ranking: 0,
    Status: 0,
    createdAt: new Date(),
    idEmpConv: '',
    idProducto: '',
    updatedAt: ''
  }

  ngOnInit(): void {
    this.product.Ranking = Math.floor(Math.random() * 6);
  }


}

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IModule } from 'src/app/core/interfaces/IModule.interface';
import { CleanCacheService } from 'src/app/services/clean-cache.service';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PushNotificationService } from 'src/app/services/pushNotification.service';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';

@Component({
  selector: 'app-profile-options',
  templateUrl: './profile-options.component.html',
  styleUrls: ['./profile-options.component.scss']
})
export class ProfileOptionsComponent {
  @Input() profileBtns!: IModule[];

  avatar: SafeResourceUrl | null = null;
  avatarExist: boolean = false;
  username: string = '';
  email: string = '';


  constructor(
    private router: Router,
    private logs: LogsService,
    private helper: HelperService,
    private cacheServ: CleanCacheService,
    private sanitizer: DomSanitizer,
    private _SesionstorageService:SesionstorageService,
    private _notifications: PushNotificationService,
    private _observer: ObservableComponentsService
  ) {
    this.initSuscriptions()
  }
 
  ngOnInit() {
    this.checkProfileData()

    // console.log(this._SesionstorageService.GetSesionStorage("login"));
  }
  checkProfileData() {
    const { Data, exp, iat } = this._SesionstorageService.GetSesionStorage("login");
    const avatarUrl = Data.Avatar || "assets/img/img1.jpg";
    this.avatar = this.sanitizer.bypassSecurityTrustResourceUrl(avatarUrl);
    this.avatarExist = Boolean(avatarUrl);
    this.username = Data.Nombre || "Lupo";
    this.email = Data.Email || "lupo@gmail.com";
    
    //const avatarUrl = JSON.parse(sessionStorage.getItem("login") || "{}").Avatar || "assets/img/img1.jpg";
    //this.username = JSON.parse(sessionStorage.getItem("login") || "{}").Nombre || "Nombre a cambiar";
    //this.email = JSON.parse(sessionStorage.getItem("login") || "{}").emailContactoPrincipal || "Email a cambiar";    
  }

  logout(): void {
    const user =this._SesionstorageService.GetSesionStorage("login")
    //const user = sessionStorage.getItem('login');
    if (user !== null) {
      // this.createLog(JSON.parse(user));
      sessionStorage.clear();
      this._notifications.stopConnection();
      // this._observer.notificationButton$.unsubscribe();
      this.router.navigate(['/signin']);
      this.cacheServ.clear();
    }
  }

  async createLog(loginParams: any): Promise<void> {
    const { idUser, idUserEmp } = loginParams;
    const action = await this.helper.retrieveAction('Logout');

    try {
      const userId = idUser || idUserEmp;
      if (userId) {
        // await this.logs.createLog(userId, action, 'Sesión finalizada');
      }
    } catch (error) {
      console.log(error);
    }
  }

  initSuscriptions(){
    this.helper.$userListener.subscribe(res => {
      console.log(res)
      this.checkProfileData()
    })
  }

}

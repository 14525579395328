<div class="Image_producto">
    <div *ngFor="let avatar of avatarsData; let i = index" style="margin: 0px 12px;">
        <div style="width: 100px; height: 80px;">
            <app-avatar-generic
                (ValueSalida)="HanderOnChange($event, i)" 
                (onDelete)="onDelete($event,i)"
                [name]="data.Campo" 
                [UrlIMg]="avatar"
            ></app-avatar-generic>
        </div>
    </div>
    
    <div *ngIf="avatarsData.length < 5" style="margin: 0px 12px;">
        <div style="width: 100px; height: 80px;">
            <app-avatar-generic
                (ValueSalida)="HandlerOnChangeAdd($event)" 
                
                [name]="data.Campo"
            ></app-avatar-generic>
        </div>
    </div>
</div>
<div class="button-flex-container">
  <!-- <button type="button" [class]="'nav-link' + (getValue() === tap.value ? ' active' : '')"  style="border-radius: 0px;">
      {{tap.datoShow}}
  </button> -->
  <div class="" *ngFor="let tap of Fields" (click)="onClick(tap.value)" style="flex-grow: 1;">
    <button [disabled]="disabledInput" type="button" [class]="'shortcut-icon button-item' + (getValue() === tap.value ? ' active' : '')">
      <div>
          <i *ngIf="isIcon(tap.icon)" class="{{tap.icon}} icon ion-ios-albums-outline"></i>
          <img *ngIf="isUrl(tap.icon)" src="{{tap.icon}}">
        <span>{{tap.datoShow}}</span>
      </div>
    </button>
  </div><!-- col -->
</div><!-- row -->
import { AfterViewInit, Component, Input } from '@angular/core';
import $ from 'jquery';
import 'src/assets/lib/datatables/jquery.dataTables.js'; // using the one from npm presents issues


@Component({
  selector: 'app-list-table1',
  templateUrl: './list-table1.component.html',
  styleUrls: ['./list-table1.component.scss']
})
export class ListTable1Component implements AfterViewInit {

  @Input() FieldAndHead: any;  // { title: "El titulo", head: ["c1", "c2"], data: [{"value1", "value2"},{"value1", "value2"}] }
/*CODE PARA USO
      const argHead = ["First Name2", "Last name", "Position", "Start date", "Salary", "E-mail"]
      const value = ["Tiger", "Nixon", "System Architect", "2011/04/25", "$320,800", "t.nixon@datatables.net"]
      const value2 = ["Tiger", "Nixon", "System Architect", "2010/04/25", "$320,800", "t.nixon@datatables.net"]
      ArgValues.push(value)
      ArgValues.push(value2)   
      const DatosSend = { title: "El titulo", head: argHead, data: ArgValues }
 
      this.TempTestTableData = DatosSend
*/
  public tableId: string = '';
  public tableClasses: string[] = [];
  public ArrayDataIn: any[]=[] 
  public thead: any[]=[]


  ngAfterViewInit(): void {
    //@ts-ignore
    $('#datatable1').DataTable({
      language: {
        searchPlaceholder: 'Buscar...',
        sSearch: '',
        lengthMenu: 'MOSTRAR _MENU_ REGISTROS',
      }
    });
  }
}

<textarea
    type="text"
    class="form-control"
    [value]="formData.vars[data.Campo] || ''"
    [name]="data.Campo"
    [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
    [required]="data.Requerido ? 'required' : ''"
    (keypressed)="HandlerOnKey()"
    (change)="HanderOnChange($event)"
    (blur)="HanderOnChange($event)"
    rows="3"
    [maxLength]="data.MaxLen"
    [hidden]="!data.ShowInsert"
    [value]="value"
    [disabled]="data.Reading === 1 ? true : false"
>
</textarea>

<!-- the left side of this nullish coalescing operation does no include 'null' -->
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-user-company',
  templateUrl: './master-user-company.component.html',
  styleUrls: ['./master-user-company.component.scss']
})
export class MasterUserCompanyComponent implements OnInit {
  optionsMenu: {id:number, name:string, icon:string}[] = [];
  
  tableList = [
    'cb_EmpresaUser'
  ]  
  
  tableRelations = [
    
  ]
  
  TableName = 'cb_EmpresaUser'
  
  attributes = { title: 'Usuarios', description: 'Listado control de los usuarios' }

  constructor(
    private _SesionstorageService:SesionstorageService, 
    private auth: AuthService,
    private helper: HelperService
    ){

    }

  ngOnInit(): void {    
    this.initEstructure()    
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterUserCompanyComponent' && e.Tipo === 'Table')
      this.optionsMenu = options ? this.helper.sortOptions(options) : []      
    }
}

import { Component } from '@angular/core';
import { IChartData } from 'src/app/shared/components/chart/chart.component';
import { dbFullDataBase, dbFullTable } from 'src/assets/lib/dbfullv2';
import { Icb_CantAliadosActivos, Icb_CantCupones, Icb_CantCuponesDisponibles, Icb_CantCuponesMonto, Icb_CantCuponesUsados, Icb_CantEmpresasAliados, Icb_CantPromocionesActivas, Icb_CantPromoEmpresasAliados, Icb_PromocionesInactivas } from 'src/app/models/tables';
import { ColorService } from 'src/app/services/color.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { environment } from '../../../environments/environment';
import { PushNotificationService } from 'src/app/services/pushNotification.service';

interface ChartItem {
  title: string,
  loading: boolean,
  formatter?: (value: number) => string,
  data: IChartData[]
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  // Datos de los charts generados
  public charts: ChartItem[] = [];
  public cards: {
    title: string,
    icon: string,
    cant: number | string
  }[] = [];
  // Tablas
  public cb_CantCupones: dbFullTable<Icb_CantCupones>;
  public cb_CantCuponesDisponibles: dbFullTable<Icb_CantCuponesDisponibles>;
  public cb_CantCuponesMonto: dbFullTable<Icb_CantCuponesMonto>;
  public cb_CantCuponesUsados: dbFullTable<Icb_CantCuponesUsados>;
  public cb_CantEmpresasAliados: dbFullTable<Icb_CantEmpresasAliados>;
  public cb_CantPromoEmpresasAliados: dbFullTable<Icb_CantPromoEmpresasAliados>;
  public cb_CantAliadosActivos: dbFullTable<Icb_CantAliadosActivos>;
  public cb_CantPromocionesActivas: dbFullTable<Icb_CantPromocionesActivas>

  private masterPermission: boolean = false;

  constructor(public colorService: ColorService, private _SesionstorageService:SesionstorageService, private _notfication: PushNotificationService) {
    const db = new dbFullDataBase(environment.bd);
    
    this.cb_CantCupones             = db.CreateChild("cb_CantCupones");
    this.cb_CantCuponesDisponibles  = db.CreateChild("cb_CantCuponesDisponibles");
    this.cb_CantCuponesMonto        = db.CreateChild("cb_CantCuponesMonto");
    this.cb_CantCuponesUsados       = db.CreateChild("cb_CantCuponesUsados");
    this.cb_CantEmpresasAliados     = db.CreateChild("cb_CantEmpresasAliados");
    this.cb_CantPromoEmpresasAliados = db.CreateChild("cb_CantPromoEmpresasAliados");
    this.cb_CantAliadosActivos = db.CreateChild("cb_CantAliadosActivos")
    this.cb_CantPromocionesActivas = db.CreateChild("cb_CantPromocionesActivas");
  }

  ngOnInit() {
    // const ROLE = this._SesionstorageService.GetSesionStorage('login').Data.idRol
    const ROLETYPE = this._SesionstorageService.GetSesionStorage('login').Data.Type
    //const ROLE = JSON.parse(sessionStorage.getItem('login') || '{}').idRol
    // console.log("ROLETYPE >>>> ", ROLETYPE)
    if(ROLETYPE === 0) this.masterPermission = true;

    this.InitData();
  }

  async InitData() {
    const { idEmpConv, idUser } =  this._SesionstorageService.GetSesionStorage('login').Data//JSON.parse(sessionStorage.getItem("login") || "{ admin: false }");
    
    // console.log("idEmpConv",idEmpConv)
    //console.log("idEmpConv",this._SesionstorageService.GetSesionStorage('login'))
    // Datos de la cantidad de cupones
    const leng = await this._notfication.getMessageNotReadLength();
    console.log(leng);
    let CantCuponesData: IChartData = {
      tag: "Cupones Generados",
      color: this.colorService.GetDynamicColor(),
      data: [
        {
          id: "",
          value: 1
        },
        {
          id: "2",
          value: 8
        }
      ],
    }
    let CantCupones: ChartItem = {
      title: "Cupones Generados",
      loading: true,
      data: [ CantCuponesData ]
    };


    // Datos de los cupones disponibles
    let CantCuponesDisponiblesData: IChartData = {
      tag: "Cupones Disponibles",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantCuponesDisponibles: ChartItem = {
      title: "Cupones Disponibles",
      loading: true,
      data: [ CantCuponesDisponiblesData ]
    };


    // Datos de los cupones usados
    let CantCuponesUsadosData: IChartData = {
      tag: "Cupones Canjeados",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantCuponesUsados: ChartItem = {
      title: "Cupones Canjeados",
      loading: true,
      data: [ CantCuponesUsadosData ]
    };

    // Datos de los montos de los cupones
    let CantCuponesMontoDataUsados: IChartData = {
      tag: "Monto Canjeado",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantCuponesMontoDataDisponibles: IChartData = {
      tag: "Monto Generado",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantCuponesMonto: ChartItem = {
      title: "Montos Cupones",
      loading: true,
      formatter: this.getDigitalMoney,
      data: [ CantCuponesMontoDataUsados, CantCuponesMontoDataDisponibles ]
    };


    // Datos de la cantidad de aliados
    let CantEmpresasAliadosData: IChartData = {
      tag: "Empresas aliadas",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantEmpresasAliados: ChartItem = {
      title: "Empresas aliadas",
      loading: true,
      data: [ CantEmpresasAliadosData ]
    };


    // Datos de las promociones de las empresas aliadas
    let CantPromoEmpresasAliadosData: IChartData = {
      tag: "Promociones de las empresas aliadas",
      color: this.colorService.GetDynamicColor(),
      data: []
    }
    let CantPromoEmpresasAliados: ChartItem = {
      title: "Promociones de las empresas aliadas",
      loading: true,
      data: [ CantPromoEmpresasAliadosData ]
    };
    let CantAliadosActivos: ChartItem = {
      title: " Empresas activas",
      loading: true,
      // color: this.colorService.GetDynamicColor(),
      data: []
    }
    let  CantPromocionesActivas: ChartItem = {
      title: "Promociones activas",
      loading: true,
      // color: this.colorService.GetDynamicColor(),
      data: []
    }
    const cardsReference: HomeComponent["cards"] = this.cards = [];
    this.charts = []; // Reiniciar datos
    //this.charts.push(CantCupones);
    //this.charts.push(CantCuponesDisponibles);
    //this.charts.push(CantCuponesUsados);
    //this.charts.push(CantCuponesMonto);
    // if(this.masterPermission) {
    //   this.charts.push(CantEmpresasAliados);
    // }

    // console.log("charts",this.charts)

    // console.log(this.charts)
    // this.charts.push(CantPromoEmpresasAliados);

    // Cargar cupones ***************************************************************************************
    this.cb_CantCupones.GET_ANY_QUERY<{
      Fecha: string,
      Cantidad: number
    }>({
      query: `SELECT
                ccc.Fecha,
                SUM(Cantidad) as Cantidad
              FROM
                cb_CantCupones ccc
              ${!this.masterPermission ? `WHERE ccc.idEmpConv='${idEmpConv}'` : ''}
              GROUP BY
                ccc.Fecha`
    })
      .then((data: any) => {
        CantCupones.loading = false;
        CantCuponesData.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }));

        // console.log("CantCuponesData",data)
        if(CantCuponesData.data.length>0) {
          this.charts.push(CantCupones);
          }
        if(data.length) {
          cardsReference.push({
            title: "Cupones Generados",
            icon:"../assets/icons/icon-coupon.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })

    // Cargar cupones disponibles *****************************************************************************
    this.cb_CantCuponesDisponibles.GET_ANY_QUERY<{
      Fecha: string,
      Cantidad: number
    }>({
      query: `SELECT
                cccd.Fecha,
                SUM(cccd.Cantidad) as Cantidad
              FROM
                cb_CantCuponesDisponibles cccd
              ${!this.masterPermission ? `WHERE cccd.idEmpConv='${idEmpConv}'` : ''}
              GROUP BY
                cccd.Fecha`
    })
      .then((data: any) => {
        CantCuponesDisponibles.loading = false;
        CantCuponesDisponiblesData.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }))

        // console.log("cantcuponesDisponibles",data)
          if(CantCuponesDisponiblesData.data.length>0) {
            this.charts.push(CantCuponesDisponibles);
          }

          if(data.length) {
          cardsReference.push({
            title: "Cupones Disponibles",
            icon:"../assets/icons/icon-coupon.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.log(err);
      })

    // Cargar cupones usados ***********************************************************************************
    this.cb_CantCuponesUsados.GET_ANY_QUERY<{
      Fecha: string,
      Cantidad: number
    }>({
      query: `SELECT
                cccu.Fecha,
                SUM(cccu.Cantidad) as Cantidad
              FROM
                cb_CantCuponesUsados cccu
              ${!this.masterPermission ? `WHERE cccu.idEmpConv='${idEmpConv}'` : ''}
              GROUP BY
                cccu.Fecha`
    })
      .then((data: any) => {
        CantCuponesUsados.loading = false;
        CantCuponesUsadosData.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }))

        if(CantCuponesUsadosData.data.length>0) {
          this.charts.push(CantCuponesUsados);
        }
        if(data.length) {
          cardsReference.push({
            title: "Cantidad Canjeados",
            icon:"../assets/icons/icon-coupon.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })

    // Cargar cupones usados ***********************************************************************************
    this.cb_CantCuponesMonto.GET_ANY_QUERY<{
      Fecha: string,
      CantidadDisponibles: number,
      CantidadUsados: number
    }>({
      query: `SELECT
                cccm.Fecha,
                SUM(cccm.CantidadDisponibles) as CantidadDisponibles,
                SUM(cccm.CantidadUsados) as CantidadUsados
              FROM
                cb_CantCuponesMonto cccm
              ${!this.masterPermission ? `WHERE cccm.idEmpConv='${idEmpConv}'` : ''}
              GROUP BY
                cccm.Fecha `
    })
      .then((data: any) => {
        CantCuponesMonto.loading = false;
        CantCuponesMontoDataUsados.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.CantidadUsados
        }))
        CantCuponesMontoDataDisponibles.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.CantidadDisponibles
        }))
        if(CantCuponesMontoDataUsados.data.length>0 || CantCuponesMontoDataDisponibles.data.length>0) {
          this.charts.push(CantCuponesMonto);
        }
        if(data.length) {
        //   console.log("EXPLOTE:",data);
          cardsReference.push({
            title: "Monto Cupones Generados",
            icon:"../assets/icons/icons8-cash-48.png",
            cant: this.getDigitalMoney(data[data.length-1].CantidadDisponibles) // ultimo valor
          })
          cardsReference.push({
            title: "Monto Cupones Canjeados",
            icon:"../assets/icons/icons8-cash-48.png",
            cant: this.getDigitalMoney(data[data.length-1].CantidadUsados) // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })


    if(this.masterPermission) {
      this.cb_CantEmpresasAliados.GET_ANY_QUERY<{
        Fecha: string,
        Cantidad: number,
      }>({
        query: `SELECT
                  ccea.Fecha,
                  SUM(ccea.Cantidad) as Cantidad
                FROM
                  cb_CantEmpresasAliados ccea
                GROUP BY
                  ccea.Fecha`
      })
        .then((data: any) => {
          CantEmpresasAliados.loading = false;
          CantEmpresasAliadosData.data = data.map((dataItem: any) => ({
            id: dataItem.Fecha,
            value: dataItem.Cantidad
          }))
          if(CantEmpresasAliadosData.data.length>0 && this.masterPermission) {
            this.charts.push(CantEmpresasAliados);
          }
          if(data.length) {
            cardsReference.push({
              title: "Total empresas",
              icon:"../assets/icons/aliado2.png",
              cant: data[data.length-1].Cantidad // ultimo valor
            })
          }
        })
        .catch((err: any) => {
          console.error(err);
        })
    }


    this.cb_CantPromoEmpresasAliados.GET_ANY_QUERY<{
      Fecha: string,
      Cantidad: number,
    }>({
      query: `SELECT
                ccpea.Fecha,
                SUM(ccpea.Cantidad) as Cantidad
              FROM
                cb_CantPromoEmpresasAliados ccpea
              ${!this.masterPermission ? `WHERE ccpea.idEmpConv='${idEmpConv}'` : ''}
              GROUP BY
                ccpea.Fecha`
    })
      .then((data: any) => {
        CantPromoEmpresasAliados.loading = false;
        CantPromoEmpresasAliadosData.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }))
        if(data.length && this.masterPermission) {
          cardsReference.push({
            title: "Cantidad promos empresas aliadas",
            icon:"../assets/icons/aliado2.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })
    if(idUser){
      this.cb_CantAliadosActivos.GET_ANY_QUERY<Icb_CantAliadosActivos>({
        query: `SELECT
                 DATE(ccaa.createdAt) as 'Fecha',
                 ccaa.Cantidad
                FROM
                  cb_CantAliadosActivos ccaa
                GROUP BY Fecha`
      })
      .then((data: any) => {
        CantAliadosActivos.loading = false;
        CantAliadosActivos.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }))
        if(data.length && this.masterPermission) {
          cardsReference.push({
            title: "Empresas activas",
            icon:"../assets/icons/aliado2.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })
      this.cb_CantPromocionesActivas.GET_ANY_QUERY<Icb_CantPromocionesActivas>({
        query: `SELECT
                 DATE(ccaa.createdAt) as 'Fecha',
                 ccaa.Cantidad
                FROM
                  cb_CantPromocionesActivas ccaa
                GROUP BY Fecha`
      })
      .then((data: any) => {
        CantPromocionesActivas.loading = false;
        CantPromocionesActivas.data = data.map((dataItem: any) => ({
          id: dataItem.Fecha,
          value: dataItem.Cantidad
        }))
        if(data.length && this.masterPermission) {
          cardsReference.push({
            title: "Promociones Activas",
            icon:"../assets/icons/promociones2.png",
            cant: data[data.length-1].Cantidad // ultimo valor
          })
        }
      })
      .catch((err: any) => {
        console.error(err);
      })  
    }

  }




  getDigitalMoney(money: number): string {
    // restar 3 significa quitarle el sufijo al texto ".00", un texto de ejemplo: "150.00" <<-- se quita .00
    const textFixed = Number(money).toFixed(2);
    const textSliced = textFixed.toString().slice(0, textFixed.toString().length-3);
    const fragText: string[] = [];


    for(let x = textSliced.length; x > 0; x-=3) {
      fragText.unshift(textSliced.slice(x-3 >= 0 ? x-3 : 0, x));
    }

    return fragText.join(".") + "," + textFixed.toString().slice(textFixed.toString().length-2, textFixed.toString().length) + " $";
  }

  formatterToInt(value: number): string {
    return Math.round(Number(value)).toString();
  }















  // dataInputCards: any[] = [];
  // empresaDataChart: any;
  // cuponesDataChart: any;
  // productosDataChart: any;

  // /*
  //   ! Configuracion estatica enviada a cada chart para renderizar texto y determinar el tipo de chart
  // */
  // configEmpresaChart: any[] = [{
  //     id: 'empresa-chart',
  //     title: 'Aliados activos por mes',
  //     typeChart: 'line',
  //     label: 'Registros por mes'
  // }];
  // idCuponesChart: any[] = [
  //   {
  //     id: 'cupones-chart',
  //     title: 'Cupones generados',
  //     typeChart: 'bar',
  //     label: 'Registros por mes'
  //   },
  //   {
  //     title: 'Cupones Usados',
  //     label: 'Registros por mes'
  //   },
  // ];
  // idProductosChart: any[] = [{
  //     id: 'productos-chart',
  //     title: 'Total de productos',
  //     typeChart: 'bar',
  //     label: 'Registros por mes'
  // }];


  // constructor( private dbFull: dbFullService ) { }

  // ngOnInit(): void {
  //   this.getDataChartsCards();
  // }

  // getDataChartsCards() {
  //   this.getDataEmpresas();
  //   this.getDataCupones();
  //   this.getDataProductos();
  // }


  // getDataEmpresas() {
  //   this.dbFull.getCountDataQuery('cb_EmpresaConvenio')
  //   .then((data: any) => {
  //     const dataCards = {
  //       title: 'Aliados activos',
  //       icon: '../assets/icons/aliado2.png',
  //       cant: 0
  //     };

  //     const filterDataCard =this.filterDataToCard( data, dataCards );
  //     // * Sumar para obtener total de empresas activas en bbdd
  //     // this.dataInputCards.push( filterDataCard );

  //     // * Organizar data para grafica
  //     this.empresaDataChart = this.handleMonth(data);
  //   })
  //   .catch(err => console.log(err));
  // }

  // getDataCupones() {
  //   /*
  //     * Data Estatica para generar ejemplos
  //   */
  //   const dataCards = {
  //     title: 'Cupones activos',
  //     icon: '../assets/icons/promociones2.png',
  //     cant: 0
  //   };

  //   const objTest = {
  //     cuponesGenerados: [
  //       { mes: 4, cantidad: 30, },
  //       { mes: 5, cantidad: 20, },
  //       { mes: 6, cantidad: 80, },
  //       { mes: 7, cantidad: 100, },
  //       { mes: 8, cantidad: 20, }
  //     ],

  //     cuponesUsados: [
  //       { mes: 4, cantidad: 20, },
  //       { mes: 5, cantidad: 10, },
  //       { mes: 6, cantidad: 80, },
  //       { mes: 7, cantidad: 50, },
  //       { mes: 8, cantidad: 15, }
  //     ]
  //   }

  //   const filterData = this.filterDataToCard(objTest.cuponesGenerados, dataCards)
  //   this.dataInputCards.push(filterData);

  //   console.log(this.dataInputCards)

  //   const data1 = this.handleMonth(objTest.cuponesGenerados);
  //   const data2 = this.handleMonth(objTest.cuponesUsados);


  //   // ! Se coloco este timeout con el fin de simular tiempos de la petición
  //   setTimeout(() => {
  //     this.cuponesDataChart = {
  //       cuponesGenerados: data1,
  //       cuponesUsados: data2
  //     }
  //   },500);
  // }

  // getDataProductos() {
  //   this.dbFull.getCountDataQuery('cb_EmpresaProductos').then((res: any) => {
  //     this.productosDataChart = this.handleMonth( res )
  //   });
  // }


  // /*
  //   ! Filtrar data para las cards y sumar
  // */
  // filterDataToCard( data: any[], dataCards: any ) {
  //   for( let mount of data ) {
  //     dataCards.cant += mount.cantidad;
  //   };

  //   return dataCards;
  // }

  // /*
  //   ! Filtrado y cambio de los meses
  // */
  // handleMonth(data: any[]) {
  //   const dataChart = data.map((month: any) => {
  //     month.mes = MONTHS[ month.mes - 1 ];
  //     return month;
  //   });

  //   return dataChart;
  // }
}

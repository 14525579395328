import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ICoreEstructure } from 'src/app/models/Estructure';
import { AuthService } from 'src/app/services/auth.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

const steps = [
  'cb_EmpresaSucursal',
  'cb_SucursalDir'
];


@Component({
  selector: 'app-modal-sucursales',
  templateUrl: './modal-sucursales.component.html',
  styleUrls: ['./modal-sucursales.component.scss']
})
export class ModalSucursalesComponent implements OnInit {
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  @Output() deleteSucursales: EventEmitter<any> = new EventEmitter();

  @Input() params: any;
  @Input() selectedElement: any;
  @Input() tableRelations: any[] = [];
  @Input() attributes: any;
  
  public dataForSucursales: any;
  public dataSelectedItem!: any;
  public modal: boolean = false;
  public showDelete: boolean = false;
  public tableName: string = 'cb_EmpresaSucursal';
  public Atributos: any = { 'title': 'Sucursales', 'Description': 'Listado control de las sucursales de empresas' }

  optionsMenu: {id:number, name:string, icon:string,idAccion: string}[] = [];

  estructures: ICoreEstructure[] = [];
  estructure: ICoreEstructure[] = [];
  canCreate: boolean = false;
  createOption: any;

  public tableList: string[] = [];
  relations = [];
  //VARIABLE PARA GUADAR EL VALOR DEL ID DE LA ACCION
  public idAction: string = "";
  public idCreateAction: string = "9OqS_XCLROpFnmSk";
  constructor( 
    private dbFull: dbFullService, 
    private router: Router, 
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService,
    private obsService: ObservableComponentsService
    ) {
      
    }
  
  ngOnInit(): void {

    this.initEstructure()

    const { Campo } = this.getPk(this.params[0]);
    this.dataForSucursales = {
      table: 'cb_EmpresaSucursal',
      Campo,
      id: this.selectedElement.element[Campo]
    }

    //INICIALIZANDO OBSERVABLES
    this.initObservables();
  }

  async initEstructure() {
    const { Data } = this._SesionstorageService.GetSesionStorage('login')
    const actions = await this.auth.retrieveActions(Data.idRol)
    const options = actions.filter( (e: any) => e.Componente === 'MasterCompanyComponent' && e.Tipo === 'Sidebar' && e.idAccion !== this.idCreateAction)
    this.optionsMenu = options ? this.helper.sortOptions(options) : []
    this.createOption = actions.find((e: any) => e.Componente === 'MasterCompanyComponent' && e.Tipo === 'Sidebar' && e.idAccion === this.idCreateAction)
    if(this.createOption) this.canCreate = true;
    

    console.log("Acciones: ",options );
    console.log("Crear Sucursal: ",this.createOption);
  }

  async initEstructuration(): Promise<ICoreEstructure[]>  {
    const arr: ICoreEstructure[] = [];
    for (const [i, table] of Object.entries(this.tableList)) {
      const estructure = await this.dbFull.GetAllDataCampo("cb_Estructura", "TableName", table)

      const core = {
        TitleHead: this.attributes.title,
        Table: table,
        Estructura: estructure,
        Inputs: [],
        Value:[]//"idUserEmp_3jKgVz"
      }

      if(i === '0') this.estructure.push(core);
      arr.push(core);
    }

    return arr
  }
  public initObservables(){
    this.obsService.dataIdAction$.subscribe(resp=>{
      this.idAction=resp;
    })
  }
  sendMessage() {
    this.SalidaClickMenu.emit();
  }

  /*
    * Se Migraron funciones del componente main-crud debido a que se debe comunicar valores diferentes en el @Input() estructures
  */ 
  closeModal( element?: any ) {
    if( element && element.data[0] instanceof Object ) {
      this.reloadCurrentRoute();
    } else {
      this.modal = false;
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  };
  
  /*
    * Se determina que accion estamos recibiendo desde el componente table-actions 
  */ 
  emitDataSucursales( event: any ) {
    if( event && event instanceof Object ) {
      this.selectedElement = event;

      const valuesToCheck: any = {
        'EDITAR': () => this.setEstructure(),
        'ELIMINAR': () => this.openDeleteModal()
      }

      valuesToCheck[event.type.trim()] ? valuesToCheck[event.type.trim()]() : null;
    }
  };

  setEstructure() {
    let updateElement = this.optionsMenu.find((e:any)=>e.name==='EDITAR');
    this.obsService.dataIdAction$.emit(updateElement?.idAccion);
    this.tableList = ['cb_EmpresaSucursal', 'cb_SucursalDir'];

    this.initEstructuration().then(res => {
      this.estructures = res.map( e => {
        const { Campo } = this.getPk(e);
        console.log("this.selectedElement:", this.selectedElement);
        e.Value = [(`${Campo}_${this.selectedElement.element[Campo]}`)];
        return e;
      });

      this.modal = true
    });
  }

  createSucursal() {
    this.obsService.dataIdAction$.emit(this.idCreateAction);
    this.tableList = ['cb_EmpresaSucursal','cb_SucursalDir'];
    this.initEstructuration().then(res => {
      this.estructures = res;
      this.modal = true;
    })
  }

  /*
    @function openDeleteModal() Se encarga de renderizar el modal de borrado
  */ 
  openDeleteModal() {
    let deleteElement = this.optionsMenu.find((e:any)=>e.name==='ELIMINAR');
    console.log(deleteElement);
    this.obsService.dataIdAction$.emit(deleteElement?.idAccion)
    this.showDelete = true;
    console.log(this.selectedElement)
    this.deleteSucursales.emit( this.selectedElement )
  }

  getPk(value: any) {
    console.log(value)
    const pk = value.Estructura.filter((element: any) => element.PK === 1);
    return pk[0];
  }
}

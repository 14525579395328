import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { SesionstorageService } from './sesionstorage.service';
import { Icb_QR } from '../models/tables';

interface IQrCompany {
  rif: string,
  idEmpConv: string;
  name: string
}

@Injectable({
  providedIn: 'root',
})
export class ApiClubService {
  private url: string = environment.URLApiLocal;

  constructor(public _sessionStorage: SesionstorageService) {}

  async generateQrCompany(data: IQrCompany): Promise<any> {
    try {
      let token = this._sessionStorage.GetSesionStorage('token');
      const headers = new HttpHeaders().set('tknlg', `Bearer ${token}`);
      let config = {
        headers: {
          tknlg: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      axios
        .post(
          `${this.url}/GeneratedQRCompany`,
          data,
          { headers: { 'X-API-Key': environment.apiKey } }
        )
        .then((res) => {
          
          console.log(res);
          return Promise.resolve({status: true, message: "Se ha generado correctamente el QR"})
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject({status: false, message: "Ha habido problemas al generar el QR"})
        });
    } catch (error) {
      console.error(error);
      return Promise.reject({status: false, message: "Ha habido problemas al generar el QR"});
      
    }
    
  }

  async ValidateCodiF(data: { CodigoFijo:string, idEmpConv:string, Action:string}) {

    return new Promise(async (resolve, reject) => {

      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateCod`, data , { headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async ValidateQREnterprise( idEmpConv: any, idQR?: any) {

    return new Promise(async (resolve, reject) => {
      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateAllyQR`, {idEmpConv,idQR} ,{ headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async validateOrden(dataFeatured: any) {

    return new Promise(async (resolve, reject) => {
      const token = this._sessionStorage.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${environment.URLApiLocal}/ValidateFeatureElement`, dataFeatured ,{ headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          console.log(resp.data);
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
  async generateQR_Redirection(dataQR: Icb_QR | any): Promise<any>{
    try {
      const responseQR: any= await axios.post(`${this.url}/GetQRredirection`,dataQR);
      console.log(responseQR);
      if(responseQR.data.status){
      
        return Promise.resolve(responseQR.data.img)
      }else{
        return Promise.reject(" ")
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(" ");
    }
  }
  }

  {
    
}

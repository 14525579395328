import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, Input, Type, ViewContainerRef, inject, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { Class } from 'leaflet';


@Component({
  selector: 'app-tab-element',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentComponent implements OnChanges{ 


    @Input() component !: ComponentType<Class> ;
  
  public vcr = inject(ViewContainerRef);

  ngOnChanges(changes: SimpleChanges){
    if(changes['component']){
      if(changes['component'].currentValue!=null || changes['component'].currentValue!=undefined ){
        this.vcr.clear();
        this.containerComponent=this.component as Type<Component> & ComponentRef<Component>;
      }
     
    }
  }
  

  public containerComponent !: Type<Component> & ComponentRef<Component>;
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-promotions',
  templateUrl: './master-promotions.component.html',
  styleUrls: ['./master-promotions.component.scss']
})
export class MasterPromotionsComponent implements OnInit {

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  tableList = [
    'cb_Promo',
    'cb_PromoProductos',
    'cb_PromoSucursal',
    'cb_Filtro',
    // "cb_Aprobaciones"
  ]

  tableRelations = [
    {
      tablaorigen: "cb_PromoProductos",
      campoorigen: "idPromo",
      tabladestino: "cb_PromoSucursal",
      campodestino: "idPromo"
    },
    {
      tablaorigen: 'cb_Promo',
      campoorigen: 'idPromo',
      tabladestino: 'cb_PromoProductos',
      campodestino: 'idPromo'
    },
    {
      tablaorigen: 'cb_PromoProductos',
      campoorigen: 'idPromo',
      tabladestino: 'cb_Filtro',
      campodestino: 'idPromo'
    },
    // {
    //   tablaorigen: 'cb_Promo',
    //   campoorigen: 'idPromo',
    //   tabladestino: 'cb_Aprobaciones',
    //   campodestino: 'idPromo'
    // },
  ]

  TableName = 'cb_Promo'
  attributes = { title: 'Promociones', description: 'Listado control de las promociones' }

  canShowInputs = [
    // {
    //   name: "Aprobado",
    //   check: () => {
    //     console.log("@@@CHECK::: Aprobado",  Boolean(JSON.parse(sessionStorage.getItem("login") || "{admin:false}")?.admin))
    //     return Boolean(JSON.parse(sessionStorage.getItem("login") || "{admin:false}")?.admin);
    //   }
    // },
    // {
    //   name: "DescripcionRechazado",
    //   check: () => {
    //     console.log("@@@CHECK::: DescripcionRechazado",  Boolean(JSON.parse(sessionStorage.getItem("login") || "{admin:false}")?.admin))
    //     return Boolean(JSON.parse(sessionStorage.getItem("login") || "{admin:false}")?.admin);
    //   }
    // },
    // {
    //   name: "DescripcionRechazado",
    //   check: (vars: any) => {
    //     return vars.Aprobado === 2;
    //   }
    // }
  ]

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {
    this.initEstructure()
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterPromotionsComponent' && e.Tipo === 'Table')
      // options?.push({
      //   Accion: "DESTACAR",
      //   Componente: "MasterPromotionsComponent",
      //   Tipo: "Table",
      //   Url: "promociones/consulta",
      //   idAccion: "ExBv_0W2dNWjkpqF2",
      //   idPermiso: "LFd7WNeeJFWtGbm12",
      //   idRol: "tMaCv6CZjU1yx_hp2",
      // })
      // console.log('options: ', options)
      this.optionsMenu = options ? this.helper.sortOptions(options) : []
  }
}

<div class="product-container" [lazyLoad]="product.ImgURL" [defaultImage]="'../../../../assets/img/box-product.png'">
    <div class="product-overlay">

        <div class="product-content">
            <h4 class="product-title text_ellipsis">
                {{product.Nombre}}
            </h4>
            <h4 class="product-price">
                {{product.Precio | currency}}
            </h4>
            <span class="product-stars-container">
                <span class="material-icons" *ngFor="let star of [1,2,3,4,5]; index as i" [class.yellow-star-icon]="product.Ranking && i < product.Ranking" > star </span>
            </span>
        </div>
    </div>
</div>
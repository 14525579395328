import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './components/data-table/data-table.component';
import { JsonFormComponent } from './components/json-form/json-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicControlComponent } from './components/json-form/components/dynamic-control/dynamic-control.component';
import { FilterFormComponent } from './components/filter-form/filter-form.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { TabsComponent } from './components/filter-form/tabs/tabs.component';
import { TabComponent } from './components/filter-form/tabs/tab.component';
import { TableComponent } from './components/table/table.component';
import { SelectListComponent } from './components/select-list/select-list.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { RadioListComponent } from './components/radio-list/radio-list.component';
import { FieldDinamicComponent } from './components/field-dinamic/field-dinamic.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ListTable1Component } from './components/list-table1/list-table1.component';
import { ListTable2Component } from './components/list-table2/list-table2.component';
import { NewUserSidebarComponent } from './components/new-user-sidebar/new-user-sidebar.component';
import { AvatarGenericComponent } from './components/avatar-generic/avatar-generic.component';
import { FormsModule }   from '@angular/forms';
import { MasterCrudComponent } from './components/master-crud/master-crud.component';
import { StringComponent } from './components/inputs/string/string.component';
import { NameComponent } from './components/inputs/name/name.component';
import { PasswordComponent } from './components/inputs/password/password.component';
import { MovilComponent } from './components/inputs/movil/movil.component';
import { EmailComponent } from './components/inputs/email/email.component';
import { AreaComponent } from './components/inputs/area/area.component';
import { DateComponent } from './components/inputs/date/date.component';
import { AvatarComponent } from './components/inputs/avatar/avatar.component';
import { SwitchComponent } from './components/inputs/switch/switch.component';
import { ListTableCrudComponent } from './components/list-table-crud/list-table-crud.component';
import { ExtensibleSelectComponent } from './components/inputs/extensible-select/extensible-select.component';
import { DependencySelectionComponent } from './components/inputs/dependency-selection/dependency-selection.component';
import { ModalGenericComponent } from './modal/modal-generic/modal-generic.component';
import { SelectComponent } from './components/inputs/select/select.component';
import { TapsComponent } from './components/inputs/taps/taps.component';
import { CardsComponent } from './components/inputs/cards/cards.component';
import { ListTableComponent } from './components/inputs/list-table/list-table.component';
import { ColorComponent } from './components/inputs/color/color.component';
import { CardComponent } from './components/card/card.component';
import { NewUserSidebarStepperComponent } from './components/new-user-sidebar-stepper/new-user-sidebar-stepper.component';
import { ModalSucursalesComponent } from './components/modal-sucursales/modal-sucursales.component';
import { NumericComponent } from './components/inputs/numeric/numeric.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MapComponent } from './components/inputs/map/map.component';
import { MainCrudComponent } from './components/main-crud/main-crud.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { MainTableComponent } from './main-table/main-table.component';
import { ListSelectComponent } from './components/inputs/list-select/list-select.component';
import { ExtensibleAvatarComponent } from './components/inputs/extensible-avatar/extensible-avatar.component';
import { ExtAvatarComponent } from './components/inputs/ext-avatar/ext-avatar.component';
import { PortalComponent } from './components/portal/portal.component';
import { ChartComponent } from './components/chart/chart.component';
import { MapaComponent } from './components/inputs/mapa-leaflet/mapa.component';
import { MoneyComponent } from './components/inputs/money/money.component';
import { ModalComponent } from "./components/modal/modal.component";
import { PortalModule } from '@angular/cdk/portal';
import { TabGenericComponent } from './components/tab/tab-generic.component';
import { TabContentComponent } from './components/tab/tabElement/tab-content.component';
import { ModalShowQRComponent } from './components/modalShowQR/modalShowQR.component';
import { ModalSourceMediaComponent } from './components/modal-source-media/modal-source-media.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CdkDropListGroup, CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalNotificationsComponent } from './components/modalNotifications/modalNotifications.component';

const sharedComponents = [
  ModalComponent,
  DataTableComponent,
  JsonFormComponent,
  DynamicControlComponent,
  FilterFormComponent,
  TabsComponent,
  TabComponent,
  ActionButtonsComponent,
  TableComponent,
  SelectListComponent,
  CheckListComponent,
  RadioListComponent,
  FieldDinamicComponent,
  DatepickerComponent,
  ListTable1Component,
  ListTable2Component,
  NewUserSidebarComponent,
  AvatarGenericComponent,
  MasterCrudComponent,
  ListTableCrudComponent,
  StringComponent,
  NameComponent,
  PasswordComponent,
  MovilComponent,
  EmailComponent,
  AreaComponent,
  DateComponent,
  AvatarComponent,
  SwitchComponent,
  ExtensibleSelectComponent,
  DependencySelectionComponent,
  ModalGenericComponent,
  SelectComponent,
  TapsComponent,
  CardsComponent,
  ListTableComponent,
  ColorComponent,
  CardComponent,
  NewUserSidebarStepperComponent,
  ModalSucursalesComponent,
  NumericComponent,
  ModalSucursalesComponent,
  MapComponent,
  MainCrudComponent,
  MainTableComponent,
  TableActionsComponent,
  ListSelectComponent,
  ExtensibleAvatarComponent,
  ExtAvatarComponent,
  PortalComponent,
  ChartComponent,
  MapaComponent,
  TabGenericComponent,
  TabContentComponent,
  ModalShowQRComponent,
  ModalSourceMediaComponent,
  ModalShowQRComponent,
  ModalNotificationsComponent,
  ProductCardComponent
]
  @NgModule({
  declarations: [...sharedComponents, MoneyComponent, ModalSourceMediaComponent],
  imports: [
    PortalModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    LazyLoadImageModule,
    CdkDropListGroup, CdkDrag, CdkDropList,
  ],
  exports: [...sharedComponents],
  providers: [HttpClient],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }

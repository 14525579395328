import { Component } from '@angular/core';

@Component({
  selector: 'app-master-franchise',
  templateUrl: './master-franchise.component.html',
  styleUrls: ['./master-franchise.component.scss']
})
export class MasterFranchiseComponent {

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartJsService {

  constructor() { }

  getDataColors(opacity?: any) {
    const colors = ['#7448c2', '#21c0d7', '#d99e2b', '#cb3234', '#9c99cc', '#e14eca', '#ffffff', '#ff0000', '#d6ff00', '#0038ff'];
    return colors.map(color => opacity ? `${color + opacity}` : color)
  }
}

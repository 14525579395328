import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
  @Input() Field: any;
  @Input() TitleSow: any;
  @Output() ValueSalida = new EventEmitter<string>();

}

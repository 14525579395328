import { Component } from '@angular/core';
import { ITab } from 'src/app/core/interfaces/ITabs.interface';
import { FeaturedPromosComponent } from './featured-promos/featured-promos-component';
import { FeaturedCompaniesComponent } from './featured-companies/featured-companies-component';

@Component({
  selector: 'app-master-featured-promos',
  templateUrl: './master-featured-promos.component.html',
  styleUrls: ['./master-featured-promos.component.scss']
})
export class MasterFeaturedPromosComponent {

  public componentsData: ITab[] = [
    {
      component: FeaturedPromosComponent,
      title: 'Promociones',
    },
    {
      component: FeaturedCompaniesComponent,
      title: 'Aliados'
    }
  ]

  constructor(
  ){};

  ngOnInit(): void {
  }

}

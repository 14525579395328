import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import {FeaturedStructureComponent} from '../featured-structure/featured-structure-component'

@Component({
  selector: 'app-featured-promos',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [dragable]="true" [idColName]="'idFeatured'" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [optionsDropdown]="optionsMenu" [canShowInput]="canShowInputs"   [filterBy]="this.filter"></app-main-crud>`,
  styleUrls: [],
  providers: [
    {provide: 'TABLE_NAME',useValue: 'cb_Featured'},
    {provide: 'TABLE_LIST',useValue: [
      'cb_Featured'
    ]},
    {provide: 'TABLE_RELATIONS', useValue: []},
    {provide: 'ATTRIBUTES', useValue: { title: 'Promociones Destacadas', description: 'Listado control de las promociones destacadas' }},
    // {provide: 'FILTERS', useValue: { name: "featured", value: "1" }}

  ]
})
export class FeaturedPromosComponent extends FeaturedStructureComponent { public filter =[ { name: "type", value: "0" }] }


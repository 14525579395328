<div class="card card-chat-contact">
    <div class="card-header bg-primary tx-white">
      {{TitleShow}}
    </div><!-- card-header -->
    <div class="card-body">
      <div class="media-list contact-list">
        <button [disabled]="disabledInput" *ngFor="let tap of Fields" type="button" [class]="'media buttom-item-list' + (getValue() === tap.value ? ' active' : '')" (click)="onClick(tap.value)">
            
          <i *ngIf="isIcon(tap.icon)" class="{{tap.icon}} icon ion-ios-albums-outline"></i>
            <img *ngIf="isUrl(tap.icon)" src="{{tap.icon}}">
            <div class="media-body mg-l-15">
                <h6 class="tx-inverse tx-13 mg-b-2">{{tap.datoShow}}</h6>
                <p class="tx-12 tx-success mg-b-0">{{tap.status || "Activo"}}</p>
            </div><!-- media-body -->
        </button>
      </div><!-- media-list -->
    </div><!-- card-body -->
  </div><!-- card -->
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';


@Component({
  selector: 'app-master-company',
  templateUrl: './master-company.component.html',
  styleUrls: ['./master-company.component.scss']
})
export class MasterCompanyComponent implements OnInit {
  public TableName: string = 'cb_EmpresaConvenio';
  
  optionsMenu: {id:number, name:string, icon:string}[] = [];
  
  public tableList = [
    'cb_EmpresaConvenio',
    'cb_EmpresaSucursal',
    'cb_SucursalDir'
  ]
  public tableListToEdit = [
    'cb_EmpresaConvenio'
  ]
  
  tableRelations = [
    {
      tablaorigen: 'cb_EmpresaConvenio',
      campoorigen: 'idEmpConv',
      tabladestino: 'cb_EmpresaSucursal',
      campodestino: 'idEmpConv'
    },
    {
      tablaorigen: 'cb_EmpresaSucursal',
      campoorigen:'idSucursal',
      tabladestino: 'cb_SucursalDir',
      campodestino: 'idSucursal'
    }
  ]
  
  public attributes = { title: 'Convenios', description: 'Listado control de los convenios' };

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {    
    this.initEstructure()    
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterCompanyComponent' && e.Tipo === 'Table')
      this.optionsMenu = options ? this.helper.sortOptions(options) : []
      //OPCION PARA MOSTRAR QR; 
      const optionQR = {id:4, name:"QR", icon:'fa fa-qrcode',idAccion: "ACCION"};
      this.optionsMenu.push(optionQR);
       console.log("MENU::::::::::::::::", this.optionsMenu);      
    }
    tablenameQR="cb_QR";

  ClickMenu(ev: any) {
    console.log(ev);
  }
}

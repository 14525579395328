import { Component, OnInit } from '@angular/core';
import { SesionstorageService } from '../../services/sesionstorage.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-config-company-category',
  templateUrl: './config-company-category.component.html',
  styleUrls: ['./config-company-category.component.scss']
})
export class ConfigCompanyCategoryComponent implements OnInit{

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  tableList = [
    'cb_EmpCategoria'
  ]

  tableRelations = [

  ]

  canShowInputs = [
    // {
    //   name: "hide",
    //   check: (vars: any) => {
    //     return !vars.hide;
    //   }
    // },
  ]

  TableName = 'cb_EmpCategoria'

  attributes = { title: 'Categorias', description: 'Listado control de las categorías' }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {
    this.initEstructure()
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const typeAction = "Table";
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'ConfigCompanyCategoryComponent' && e.Tipo === typeAction)
      this.optionsMenu = options ? this.helper.sortOptions(options) : []
  }
}

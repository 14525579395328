import { Component, OnInit } from '@angular/core';
import { IModule, INotification } from 'src/app/core/interfaces/IModule.interface';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { PushNotificationService } from 'src/app/services/pushNotification.service';

@Component({
  selector: 'app-headpanel',
  templateUrl: './headpanel.component.html',
  styleUrls: ['./headpanel.component.scss']
})
export class HeadpanelComponent implements OnInit{
  constructor( 
    private moduleService: DashboardModulesService
  ){}
  public lengthMessagesNotReaded = 0;
  public modalMode = false;
  public btnsLeft!: IModule | any
  // [
  //   {
  //     "id": 1,
  //     "name": "Promociones",
  //     "order": 1,
  //     "route": "/dashboard/tags/promociones",
  //     "styleRules": {
  //       "icon": "fa fa-star"
  //     },
  //     "childrenModules": []
  //   },
  //   {
  //     "id": 2,
  //     "name": "Cupones",
  //     "order": 2,
  //     "route": "/dashboard/tags/cupones",
  //     "styleRules": {
  //       "icon": "fa fa-ticket"
  //     },
  //     "childrenModules": []
  //   },
  // ];
  public btnsRight!: IModule | any
  // = [
  //   {
  //     "id": 1,
  //     "name": "Promociones",
  //     "order": 1,
  //     "route": "/dashboard/tags/promociones",
  //     "styleRules": {
  //       "icon": "fa fa-star"
  //     },
  //     "childrenModules": []
  //   },
  //   {
  //     "id": 2,
  //     "name": "Cupones",
  //     "order": 2,
  //     "route": "/dashboard/tags/cupones",
  //     "styleRules": {
  //       "icon": "fa fa-ticket"
  //     },
  //     "childrenModules": []
  //   },
  // ];
  public profileDdwnBtns: IModule | any = [
    {
      "id": 1,
      "name": "Editar Perfil",
      "order": 1,
      "route": "perfil",
      "styleRules": {
        "icon": "icon ion-ios-person"
      },
      "childrenModules": []
    },
    // {
    //   "id": 2,
    //   "name": "Configuración",
    //   "order": 2,
    //   "route": null,
    //   "method": null,
    //   "styleRules": {
    //     "icon": "icon ion-ios-gear"
    //   },
    //   "childrenModules": []
    // },
    // {
    //   "id": 3,
    //   "name": "Descargas",
    //   "order": 3,
    //   "route": null,
    //   "method": null,
    //   "styleRules": {
    //     "icon": "icon ion-ios-download"
    //   },
    //   "childrenModules": []
    // },
    // {
    //   "id": 4,
    //   "name": "Favoritos",
    //   "order": 4,
    //   "route": null,
    //   "method": null,
    //   "styleRules": {
    //     "icon": "icon ion-ios-star"
    //   },
    //   "childrenModules": []
    // }
  ];
   ngOnInit()  {
  this.initData();
  }
  async initData(){
    // this.lengthMessagesNotReaded = this._notfication.getMessageNotReadLength();
    console.log(this.lengthMessagesNotReaded);
  }
  public areRoutePromotions(){
    const route =   this.moduleService.modules.find(module=>module.name.includes("Promociones"));
   if(route) return true;
   return false;
   }
  // public notifys: INotification[] = [
  //   {
  //     id: 1,
  //     title: "Nuevo producto agregado",
  //     descrip: "Se ha agregado un nuevo producto a la base de datos.",
  //     date: "12 de Julio, 2023 10:30am",
  //     img: "assets/img/producto1.jpg",
  //     order: 1,
  //     styleRules: {
  //       icon: "icon ion-ios-add"
  //     },
  //     childrenModules: []
  //   },
  //   {
  //     id: 2,
  //     title: "Cambio en los precios",
  //     descrip: "Se han actualizado los precios de algunos productos.",
  //     date: "10 de Julio, 2023 3:15pm",
  //     img: "assets/img/producto2.jpg",
  //     order: 2,
  //     styleRules: {
  //       icon: "icon ion-ios-pricetags"
  //     },
  //     childrenModules: []
  //   },
  //   {
  //     id: 3,
  //     title: "Cupón de descuento",
  //     descrip: "Se ha creado un nuevo cupón de descuento para los clientes.",
  //     date: "9 de Julio, 2023 11:45am",
  //     img: "assets/img/coupon.jpg",
  //     order: 3,
  //     styleRules: {
  //       icon: "icon ion-ios-gift"
  //     },
  //     childrenModules: []
  //   }
  // ];
}

import { HostListener, Injectable, isDevMode } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { getMockUserPublicInfo } from 'src/mock-data/user';
import { IModule } from '../core/interfaces/IModule.interface';
import { IUser } from '../core/interfaces/IUser.interface';
import { AuthService } from '../core/services/auth.service';
import { DashboardModulesService } from '../core/services/dashboard-modules.service';
import { ModuleViewComponent } from '../pages/dashboard/components/module-view/module-view.component';
import { TableComponent} from 'src/app/shared/components/table/table.component';
import {NewUserSidebarComponent } from 'src/app/shared/components/new-user-sidebar/new-user-sidebar.component';
import { ModalGenericComponent } from 'src/app/shared/modal/modal-generic/modal-generic.component';
import { moduleViewResolver } from '../resolvers/module-view.resolver';
import { FieldDinamicComponent } from '../shared/components/field-dinamic/field-dinamic.component';
import { MasterCompanyComponent } from '../pages/master-company/master-company.component';
import { MasterProductsComponent } from '../pages/master-products/master-products.component';
import { MasterUserCompanyComponent } from '../pages/master-user-company/master-user-company.component';
import { MasterPromotionsComponent } from '../pages/master-promotions/master-promotions.component';
import { ConfigCompanyCategoryComponent } from '../pages/config-company-category/config-company-category.component';
import { MasterCrudComponent } from '../shared/components/master-crud/master-crud.component';
import { PerfilComponent  } from '../pages/dashboard/layout/perfil/perfil.component';
import { PromotionsTagComponent } from '../pages/promotions-tag/promotions-tag.component';
import { CouponsTagComponent } from '../pages/coupons-tag/coupons-tag.component';
import { ConfigFranquiciasComponent } from '../pages/config-franquicias/config-franquicias.component';
import { ListTableCrudComponent } from 'src/app/shared/components/list-table-crud/list-table-crud.component';
import { MasterPromotionsNewComponent } from '../pages/master-promotions-new/master-promotions-new.component';
import { MasterLogsComponent } from '../pages/master-logs/master-logs.component';
import { ChartsComponent } from '../shared/components/charts/charts.component';
import { HomeComponent } from '../pages/home/home.component';
import { ConfigProductsCategoryComponent } from '../pages/config-products-category/config-products-category.component';
import { MasterFranchiseComponent } from '../pages/master-franchise/master-franchise.component';
import { dbFullService } from './dbFull.service';
import { MasterValidatorqrComponent } from '../pages/master-validatorqr/master-validatorqr.component';
import { MasterAprobacionesComponent } from '../pages/master-aprobaciones/master-aprobaciones.component';
import { MasterViewAprobacionesComponent } from '../pages/master-view-aprobaciones/master-view-aprobaciones.component';
import { SesionstorageService } from '../services/sesionstorage.service';
import { StatisticsComponent } from '../pages/statistics/statistics.component';
import { BannersComponent } from '../pages/banners/banners.component';
import { MasterQRComponent } from '../pages/masterQR/masterQR.component';
import { MasterPromoScoreComponent } from '../pages/master-promo-score/master-promo-score.component';
import { MasterFeaturedPromosComponent } from '../pages/master-featured-promos/master-featured-promos.component';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  //Esto es temporal
  private Menus =['Consulta', 'Nueva']
  permisosRoutes: any

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardModulesService: DashboardModulesService,
    private dbfullS: dbFullService,
    private _SesionstorageService:SesionstorageService
  ) {
    const storedValue =  this._SesionstorageService.GetSesionStorage('permissRou')
    console.table(storedValue);
    //const storedValue = sessionStorage.getItem('permissRou');
    if (storedValue) {
      this.permisosRoutes = storedValue;
    }
    // this.loadConfigurationData(this.permisosRoutes);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    // console.log("permissRou");
    // console.log(this.permisosRoutes)
    this._SesionstorageService.SetSesionStorage('permissRou',JSON.stringify(this.permisosRoutes))
    //sessionStorage.setItem('permissRou', JSON.stringify(this.permisosRoutes));
  }

  /**
   * Sets the values for every service that involves user data.
   * @returns
   */
  public async loadConfigurationData(arrPermissions?: any): Promise<void> {
    // console.log('loadConfigurationData')
    let user: IUser;

    // attempt session retrieval
    try {
      if (isDevMode()) {
        console.log('PASO A DEV >>>>>')
        // in dev mode the user will always exist, not convenient if you want to test user authentication
        let res = await getMockUserPublicInfo();
        console.log("getMockUserPublicInfo", res);
        user = res.data;
      } else {
        user = await this.authService.getUserSession();
      }
    }
    catch(error: any) {
    //   console.log('loadConfigurationData error', error)
      /**
       * status === 0 is basically that the backend doesn't even exist, no response. It's something expected if the server is still not setup anywhere,
       * either locally or on the web
       */
      if (error.request.status === 0 && isDevMode()) return;

      if(error.request.status === 401) {
        // if user session is no longer active, redirect them to login
        this.router.navigate(['/signin']);
      } else if ([0, 403, 500, 503].includes(error.request.status)) {
        // if error is something fatal, show error page
        const status_code = error.request.status === 0 ? 503 : error.request.status; // status_code 0 is a special case (service isn't even running)
        this.router.navigate(['/error'], { queryParams : { status_code }});
      }
      return;
    }

    // user could retrieve session safely
    // console.log('user session resumed');

    /*------------ initialize service's values -------------*/
    // take the user object and split data into their services
    if(user.modules.length > 0) {
      let modulesRen: any
      if (arrPermissions) {
        // console.log("tengo acceso a estas rutas:");
        // console.log(arrPermissions)
        this.permisosRoutes = arrPermissions
        this._SesionstorageService.SetSesionStorage('permissRou',JSON.stringify(arrPermissions))
        //sessionStorage.setItem('permissRou', JSON.stringify(arrPermissions));
        modulesRen = this.filterObjects(user.modules, arrPermissions)
      }
      else{
        modulesRen = user.modules
      }
      this.dashboardModulesService.init(modulesRen);
      try {
        this.loadAditionalRoutes(modulesRen, 'dashboard'); // CAUTION, this function CAN throw an error, so handle appropiately
      } catch (error) {
        console.error(error);
      }
    } else {
      // console.log('user has no modules');
    }
    /*-------------------------------------------------------*/
  }

  getRoutesDataUser( ahsdhj: any ) {
    this.getNewRoutes(ahsdhj)
  }

  /**
   * Recursive function that returns routes generated from an API for a certain user.
   * @param userModules
  //  * @returns the returned routes are defined as { path: module.route, component: ModuleViewComponent, title: `Admin ISPV1 - ${module.name}`, data: { module } }.
   * The "data" property passes on the module object that should be used to perform operations, like fetch the data for that module, etc.
   */
  public getNewRoutes(userModules: IModule[]): Routes {
    // console.log('userModules', userModules)
    let userModulesRoutes: Routes = [];
    // console.log("UserModules");
    // console.log(userModules)
    for (let module of userModules) {
      // virtually this shouldn't occur, but just in case (a module that isn't a dropdown or isn't a standalone module would be nothing more than a decoration)
      if (!module.route && module.childrenModules.length === 0) continue;
        // console.log("Moduleroute");
        // console.log(module)
        switch (module.route) {
          case "home":
            userModulesRoutes.push({
              path: module.route,
              component: HomeComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "perfil":
          userModulesRoutes.push({
            path: module.route,
            component: PerfilComponent,
            // title: `Admin ISPV1 - ${module.name}`,
            data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
          });
            break;
          case "tags/promociones":
            userModulesRoutes.push({
              path: module.route,
              component: PromotionsTagComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "tags/cupones":
            userModulesRoutes.push({
              path: module.route,
              component: CouponsTagComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "tags/perfil":
            userModulesRoutes.push({
              path: module.route,
              component: PerfilComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;


          case "empresa/consulta":
            userModulesRoutes.push({
              path: module.route,
              component: MasterCompanyComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          // case "empresa/nueva":
          //   userModulesRoutes.push({
          //     path: module.route,
          //     component: MasterNewCompanyComponent,
              // title: `Admin ISPV1 - ${module.name}`,
          //     data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
          //   });
          //   break;

          case "empresa/productos":
            userModulesRoutes.push({
              path: module.route,
              component: MasterProductsComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "empresa/usuarios":
            userModulesRoutes.push({
              path: module.route,
              component: MasterUserCompanyComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "promociones/revisiones":
            userModulesRoutes.push({
              path: module.route,
              component: MasterAprobacionesComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "promociones/estado-promociones":
            userModulesRoutes.push({
              path: module.route,
              component: MasterViewAprobacionesComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "promociones/consulta":
            console.log(" CONSULTAA")
            userModulesRoutes.push({
              path: module.route,
              component: MasterPromotionsComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "promociones/featured":
            console.log("FEATURED :::")
            userModulesRoutes.push({
              path: module.route,
              component: MasterFeaturedPromosComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case 'promociones/score':
            userModulesRoutes.push({
              path: module.route,
              component: MasterPromoScoreComponent,
              data: {module}
            })
            break;

          case "configuracion/cat_empresas":
            userModulesRoutes.push({
              path: module.route,
              component: ConfigCompanyCategoryComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break

          // case "configuracion/cate_prod":
          //   userModulesRoutes.push({
          //     path: module.route,
          //     component: ConfigProductsCategoryComponent,
              // title: `Admin ISPV1 - ${module.name}`,
          //     data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
          //   });
          //   break;

          case "configuracion/franquicias":
            userModulesRoutes.push({
              path: module.route,
              component: ConfigFranquiciasComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "configuracion/Usuario":
            userModulesRoutes.push({
              path: module.route,
              component: NewUserSidebarComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;
            case "configuracion/QR":
              console.log("QR");
            userModulesRoutes.push({
              path: module.route,
              component: MasterQRComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;


          case "Franquicias":
            userModulesRoutes.push({
              path: module.route,
              component: MasterFranchiseComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

           case "validation-qr":
            // console.log("validation-qr");
            userModulesRoutes.push({
              path: module.route,
              component: MasterValidatorqrComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "configuracion/reportes":
            console.log("reportes");
            userModulesRoutes.push({
              path: module.route,
              component: StatisticsComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;
          case "configuracion/banners":
             console.log("banners");
            userModulesRoutes.push({
              path: module.route,
              component: BannersComponent,
              // title: `Admin ISPV1 - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;
          // case "logs/consulta":

          //   userModulesRoutes.push({
          //     path: module.route,
          //     component: MasterLogsComponent,
              // title: `Admin ISPV1 - ${module.name}`,
          //     data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
          //   });
          // break;

          default:
            if (module.route) {
              userModulesRoutes.push({
                path: module.route,
                component: ModuleViewComponent,
                // title: `Admin ISPV1 - ${module.name}`,
                data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
                resolve: { // resolver used to fetch data before switching routes
                  contentBlocks: moduleViewResolver
                }
              });
            }
        }





      // if it has children modules, add the children routes
     if (module.childrenModules.length > 0) {
        userModulesRoutes = userModulesRoutes.concat(this.getNewRoutes(module.childrenModules));
      }
    }

    return userModulesRoutes
  }

  filterObjects(routeServ: any, permisosArr: any) {
    return routeServ.filter((elem: any) => {
      // Buscar el permiso en permisosArr que tenga la misma ruta que elem
      const foundPermiso = permisosArr.find((permiso: any) => {
        // console.log(`${permiso.Url}` +`==`+ `${elem.route}`)
        return permiso.Url === elem.route || elem.route == 'home';
      });

      if (foundPermiso) {
        // Si se encuentra un permiso asociado con la ruta actual, agregarla al resultado
        return true;
      } else if (elem?.childrenModules?.length > 0) {
        // Si tiene childrenModules, buscar la ruta en el siguiente nivel y solo devolver los hijos que tienen permisos asociados
        const filteredChildren = this.filterObjects(elem?.childrenModules, permisosArr);
        if (filteredChildren.length > 0) {
          elem.childrenModules = filteredChildren;
          return true;
        }
      }
      return false;
    });
  }

  /**
   * Create and load routes defined from modules that come from the backend
   * @param userModules
   * @param basePath the base path where the children routes should be defined
   */
  private loadAditionalRoutes(userModules: IModule[], basePath?: string): void | never {
    // console.log("loadAditionalRoutes");
    // console.log(userModules);
    const dashboardIndex = this.router.config.findIndex((route: any) => route.path === basePath);
    if (dashboardIndex === -1) throw new Error(`Could not find a base path with the value of '${basePath}'`);
    const userModulesRoutes = this.getNewRoutes(userModules);
    this.router.config[dashboardIndex].children?.unshift(...userModulesRoutes);
  }
}

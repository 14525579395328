<!-- SMALL MODAL -->
<div id="login" class="modal fade">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content bd-0 tx-14">
      <div class="modal-header pd-x-20">
        <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{Modal.title}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pd-20">
        <p class="mg-b-5">{{Modal.texto}}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-success pd-x-20">{{Modal.botonsave}}</button>
        <button type="button" class="btn btn-secondary pd-x-20" data-dismiss="modal">{{Modal.botonclose}}</button>
      </div>
    </div>
  </div><!-- modal-dialog -->
</div><!-- modal -->
<button type="button" class="btn btn-success pd-x-20" data-toggle="modal" data-target="#login">Login</button>

import { Component } from '@angular/core';

@Component({
  selector: 'app-master-new-company',
  templateUrl: './master-new-company.component.html',
  styleUrls: ['./master-new-company.component.scss']
})
export class MasterNewCompanyComponent {

  tableArray = [
    'cb_EmpresaConvenio',
    'cb_EmpresaSucursal',
    'cb_SucursalDir'
  ]

  tableRelations = [
    {
      tablaorigen: 'cb_EmpresaConvenio',
      campoorigen: 'idEmpConv',
      tabladestino: 'cb_EmpresaSucursal',
      campodestino: 'idEmpConv'
    },
    {
      tablaorigen: 'cb_EmpresaSucursal',
      campoorigen:'idSucursal',
      tabladestino: 'cb_SucursalDir',
      campodestino: 'idSucursal'
    }
  ]

  TableName = 'cb_EmpresaConvenio'

  Atributos = { 'title': 'Empresas', 'Description': 'Listado control de las empresas' }
}

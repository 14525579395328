export const environment = {
  production: false,

  // ! URL API's
  UrlFull:'https://backend.thomas-talk.me/',
  urlApiMapbox: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  urlDBFULL: 'https://dbfull.thomas-talk.me/api/',
  apiUrl: 'http://localhost:3000',

  // ! BBDD
  bd: 'thomas_sandbox_clubfibex',//

  // ! BBDD
  // bd:'sandbox_clubfibex',

  // ! Tablas
  tableEmpConv:'cb_EmpresaConvenio',
  tableEmpFran:'cb_EmpFranquicia',
  tableEmpCat:'cb_EmpCategoria',
  tableEmpPromo:'cb_Promo',
  ApiQR:'https://puntoclub.fibextelecom.net/',//'https://puntoclub.fibextelecom.net/',

  // ! Token & Auth
  tokenMapbox: 'pk.eyJ1IjoibHVpc3BlcmRvbW80MDgiLCJhIjoiY2xqaXpnY2o3MDQ3eTNncGI1YWN5eW1qZiJ9.IjpfPbexwEjcLNQ6JTt_1w',
  tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
  authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),

  // ! Keys
  apiKey: 'dev',
  encryptionKey: 'asf1d2dwqd21',
  SecuryEncrypt: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  ApiKey: "167871278349249",
  ApiSecret: "7I5iUVfNCJlWI8zS6E1vUSXM4oI",

  // ! Otros
  CloudName: "thomasv9",
  upload_preset: "itenw8im",
  URLApiLocal: 'https://apitest1.thomas-talk.me',//'https://apitest1.thomas-talk.me',//'http://localhost:9012','https://apiclub.fibextelecom.info',https://apiclub.thomas-talk.me/ 'https://apitest1.thomas-talk.me'
  wsClubFibex: 'wss://apitest1.thomas-talk.me',//'https://apitest1.thomas-talk.me' 'https://apitest1.thomas-talk.me'
   //CLOUDINARY
   upload_image_url:'https://images-services.thomas-talk.me',
   upload_image_token:'bT5n-xuJ4JXcxIGvVsF2ZmffHg3I_h2OLcon6UvZ9kBxNAzlDIoQFmFpOWkU5yU4Z0R0GbTjK-5bXpv7PAtw6h2BlYJ2DddJKi9A5GouXgCpoxfNTbOMVwmiNAvCvDFJsULA3q-rqgESUgR9Rk4g61ttRV_1HCv-vl5-tj3lODHTtLK2_hGh',
   upload_image_user:'Randal',
   upload_image_password:'$%rgY6rh==vbhp93*',
   upload_image_endpoint:'upload-images',
   folder:'ClubFibex',


  //!env for logs
  logs:{
    table: 'cb_LogUser',
    id:'idLog',
  },
  audit:{
    tableLog: "cb_LogSystem",
    pkLog: "idLogSystem"
  },
  //DIAS POR USAR CUPOn
  daysUseCupon: 5,
  //* To get oficina movil banners
  Lic: '584120202020@c.us',
  banners: 'Banners_App',
  TokenUpdate: '4p1_[51t3m4s]_f186@th0m4s',
  table_notification: 'tm_notifications_clients_sandbox',
  tm_cobertura: "thomas_cobertura",

  URLApi:'',//'https://apitls.fibextelecom.net/', //http://localhost:9001,

}

import { Component, ElementRef, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { Map, Marker, Popup} from 'mapbox-gl';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { MapServiceService } from 'src/app/services/map-service.service';
import { Feature } from 'src/app/shared/interfaces/map';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  @ViewChild('mapdiv') mapdiv!: ElementRef;
  @ViewChild('txtQuery') txtQuery!: ElementRef;
  @Input() defaultValor: any = [undefined, undefined];
  @Output() coords: EventEmitter<[number, number]> = new EventEmitter();
  private defaultCoords: [number, number] = [-67.999511, 10.2337867]; // Ubicación fibex valencia
  private debounceTimer!: NodeJS.Timeout;
  public selectedId: string = '';
  private marker: any = { };

  get placesData(): Feature[] {
    return this.locationServ.data
  }
  constructor( 
        private locationServ: GeolocationService, 
        private mapServ: MapServiceService
      ) {}

  ngAfterViewInit(): void {
    try {
      const map = new Map({
        container: this.mapdiv.nativeElement,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [this.defaultValor[0] ?? this.defaultCoords[0], this.defaultValor[1] ?? this.defaultCoords[1]],
        zoom: 14,
      });
  
      const popup = new Popup()
        .setHTML(`
          <h6>Aquí estoy</h6>
          <span>Estoy en este lugar del mundo</span>
        `)
  
        const marker = this.marker = new Marker({ color: 'red', draggable: true,  })
        .setLngLat( [this.defaultValor[0] ?? this.defaultCoords[0], this.defaultValor[1] ?? this.defaultCoords[1]] )
        // .setPopup( popup )
        .addTo( map );
  
      this.mapServ.setMap( map );
  
      // map.on( 'click', (e) => {
      //   const { lat, lng } = e.lngLat;
      //   this.coords.emit([ lng, lat ]);
      //   marker.remove(); // remove old
      //   marker = new Marker({ color: 'red' })
      //   .setLngLat([ lng, lat ])
      //   .addTo( map );
  
      // } )

      // Manejar el evento de fin de arrastre
      marker.on("dragend", () => {
        const lngLat = marker.getLngLat();
        this.coords.emit([lngLat.lng, lngLat.lat])
        console.log([lngLat.lng, lngLat.lat])
      });
      
      if(this.defaultValor[0] === undefined || this.defaultValor[1] === undefined) {
        // Tratar de obtener la ubicación del usuario que caso que no exista ningun valor por defecto
        this.locationServ.getUserLocation().then(res => {
          try {
            map.setCenter(res);
            marker.setLngLat(res);
          } catch (error) {
            console.error(error)
          }
        })
      }
    }
    catch(err) {
      console.error(err);
    }
  }


  goToOriginalLocation() {
    if( !this.locationServ.isUserLocationAvailable ) throw new Error('No hay ubicación');
    if( !this.mapServ.isMapReady ) throw new Error('No hay mapa disponible');

    this.mapServ.flyTo( this.locationServ.userLocation! )
  }

  onQueryChanged( query: string ) {
    if( this.debounceTimer ) clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(() => {
      this.locationServ.getSpecificPlace(query)
    }, 500)
  }

  getSelectedPlace(place: Feature) {

    this.selectedId = place.id;
    const [ lng, lat ] = place.center;
    this.coords.emit([lng, lat]);
    this.mapServ.flyTo([lng, lat]);
    this.marker.setLngLat([lng, lat]);

    console.log([lng, lat])

    this.locationServ.deleteData();
    this.txtQuery.nativeElement.value = '';
  }
}

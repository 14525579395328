<div *ngIf="!module.noDisplay">
  <ng-container *ngIf="layer < 2">
    <!-- if module is standalone (single module with no children and a route that leads to somewhere) -->
    <li class="customs" *ngIf="module.route">
      <a [class]="{'nav-link': true, 'active': dashboardModulesService.currentModule.id === module.id && 'active'}"
        [routerLink]="module.route" style="color: #525353">
        <!-- <i  [class]="module.styleRules?.icon"></i> -->
        <img *ngIf="dashboardModulesService.currentModule.id === module.id && 'active'" [src]="module.imgIcon" alt=""
        width="16%"
        >
        <img *ngIf="dashboardModulesService.currentModule.id !== module.id && 'active'" [src]="module.titleIcon" alt=""
        width="16%"
        >
        <span style="margin-left: 6px;">{{ module.name }}</span>
      </a>
    </li>

    <!-- if module is a dropdown menu (module with no route but with children that can have routes and content) -->
    <li class="customs" *ngIf="!module.route && module.childrenModules.length > 0">
      <a href="" [class]="{'nav-link': true, 'with-sub': true, 'active': dashboardModulesService.currentModule.id === module.id && 'active'}" style="color: #525353">
        <!-- <i  [class]="module.styleRules?.icon"></i> -->
        <img *ngIf="dashboardModulesService.currentModule.id === module.id && 'active'" [src]="module.imgIcon" alt=""
        width="16%"
        >
        <img *ngIf="dashboardModulesService.currentModule.id !== module.id && 'active'" [src]="module.titleIcon" alt=""
        width="16%"
        >
        <span style="margin-left: 6px;">{{ module.name }}</span>
      </a>
      <ul class="nav-sub" style="display: none; background-color: #fff;">
        <app-module-option *ngFor="let childModule of module.childrenModules" [module]="childModule" [layer]="layer + 1"></app-module-option>
      </ul>
    </li>
  </ng-container>


  <!-- if menu has reached level 2 -->
  <ng-container *ngIf="layer > 1">
    <!-- if route exists, lead directly to content -->
    <li class="customs2" *ngIf="module.route">
      <a [class]="{'nav-link': true, 'active': dashboardModulesService.currentModule.id === module.id && 'active'}"
        [routerLink]="module.route" style="color: #525353">
        <!-- <i 
          [class]="module.styleRules?.icon"
          [style]="{'margin-left': module.styleRules?.icon ? '18px' : '0px' }"></i> -->
          <img *ngIf="dashboardModulesService.currentModule.id === module.id && 'active'" [src]="module.imgIcon" alt=""
          width="16%"
          >
          <img *ngIf="dashboardModulesService.currentModule.id !== module.id && 'active'" [src]="module.titleIcon" alt=""
          width="16%"
          >
        <span style="margin-left: 6px;">{{ module.name }}</span>
      </a>
    </li>

    <!-- if route doesn't exist and has children, lead to component with list of children modules -->
    <li class="customs2" *ngIf="!module.route && module.childrenModules.length > 0">
      <a [class]="{'nav-link': true, 'active': dashboardModulesService.currentModule.id === module.id && 'active'}"
        href="javascript:void(0)"
        (click)="goToModulesCardMenu()" style="color: #525353">
        <!-- <i [class]="module.styleRules?.icon"></i> -->
        <img *ngIf="dashboardModulesService.currentModule.id === module.id && 'active'" [src]="module.imgIcon" alt=""
        width="16%"
        >
        <img *ngIf="dashboardModulesService.currentModule.id !== module.id && 'active'" [src]="module.titleIcon" alt=""
        width="16%"
        >
        <span style="margin-left: 6px;">{{ module.name }}</span>
      </a>
    </li>
  </ng-container>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() cardData: {
    icon: string,
    title: string,
    cant: number | string
  }[] = [];


  ngOnInit(): void {
    
  }
}

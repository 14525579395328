import { Injectable, OnInit } from "@angular/core";
import axios, { AxiosResponse } from "axios";
import { environment } from "src/environments/environment";
import { SesionstorageService } from "src/app/services/sesionstorage.service";
import { EncryptionService } from "../core/services/encryption.service";
import { SeguridadDatos } from "./bscript.service";
import { Icb_QR } from "../models/tables";

@Injectable({
  providedIn: 'root'
})

export class dbFullService implements OnInit {

  private ShowInfoTable: any = ""
  private Excluido: any = ["createdAt", "updatedAt"];
  public permissionsData = [];
  public localStructure = {
    getImagePromo: [
      {
        Campo:"desktop",
        HeadListName:"Imagen Banner",
        MaxLen: 180,
        OrdenInsert: 1,
        OrdenList: 1,
        PK: 0,
        PlaceHolder:"Imagen Banner escriotrio",
        Reading: 0,
        Requerido: 1,
        ShowInsert: 1,
        ShowList: 1,
        TableName:"cb_LandingBanner",
        TipoDato:"Avatar",
      },
      {
        Campo:"phone",
        HeadListName:"Imagen Banner",
        MaxLen: 180,
        OrdenInsert: 1,
        OrdenList: 2,
        PK: 0,
        PlaceHolder:"Imagen Banner movil",
        Reading: 0,
        Requerido: 1,
        ShowInsert: 1,
        ShowList: 0,
        TableName:"cb_LandingBanner",
        TipoDato:"Avatar",
      },
      {
        Campo:"Tipo",
        HeadListName:"TIpo Banner",
        MaxLen:180,
        OrdenInsert:4,
        OrdenList:3,
        PK:0,
        PlaceHolder:"Tipo Banner",
        Reading:0,
        Requerido:1,
        ShowInsert:1,
        ShowList:1,
        TableName:"cb_LandingBanner",
        TipoDato:"String",
        idEstructura:"184",
      }
    ]
  }


  constructor(
    private _SesionstorageService?: SesionstorageService,
    private _encryptService?: EncryptionService,
    private enc?: SeguridadDatos
  ) {

  }


  get PermissionsUserRoutes() {
    return { ...this.permissionsData }
  }

  ngOnInit(): void {
    this.ShowInfo();
  }

  GetTokenJWT(Data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${environment.URLApiLocal}/LoginAuth`, Data)
        .then((response: any) => {
          // console.log("Response");
          // console.log(response);
          console.log("response: " + response);
          if (response && response.data.hasOwnProperty('tknlg')) {
            //   console.log("Este es el JWT");
            //   console.log(response.data.tknlg);
            console.log(response.data.tknlg);
            this._SesionstorageService?.SetSesionStorage('token', JSON.stringify(response.data.tknlg))
            resolve(response.data.tknlg)
          }
          resolve(null)
        })

        .catch((error: any) => reject(error));
    })

  }

  async GetAllData(table: string) {
    return new Promise(async (resolve, reject) => {
      axios.get(`${environment.UrlFull}api/v1/find-all-info/${environment.bd}/${table}`)
        .then((resp: any) => resolve(resp.data))
        .catch((error: any) => reject(error));
    })
  }

  async GetAllT() {
    return new Promise(async (resolve, reject) => {
      axios.get(`${environment.UrlFull}api/v1/show-all-info/${environment.bd}`)
        .then((resp: any) => resolve(resp.data))
        .catch((error: any) => reject(error));
    })
  }

  async GetAllDataCampo(table: string, campo: string, valor: any): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const structure = (this.localStructure as any)[valor]
      if (structure) resolve(structure)//* If a local structure was found, resolves it
      else {
        const iURL = `${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`
        // console.log(iURL)
        axios.get(iURL)
          .then((resp: any) => resolve(resp.data))
          .catch((error: any) => reject(error));
      }
    })
  }

  getLoginDataQuery(table: string, email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'x-data-query': `SELECT * FROM ${environment.bd}.${table} WHERE Login="${email}" and Clave="${password}" and Status=1`
      };

      axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          // console.log(resp)
          resolve(resp.data)
        })
        .catch((error: any) => reject(error));
    })
  }

  async getDataByEmpresa(table: string, idEmpConv: string) {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'x-data-query': `SELECT * FROM ${table} WHERE  Status="1"`
      };
      // console.log(headers)
      axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          //   console.log(resp)

          //   console.log(resp.data)
          resolve(resp.data)
        })
        .catch((error: any) => reject(error));
    })
  }

  getCountDataQuery(table: string) {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'x-data-query': `SELECT MONTH(createdAt) as mes, COUNT(*) as cantidad FROM ${table} GROUP BY mes`
      };

      axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => resolve(resp.data))
        .catch((error: any) => reject(error));
    })
  }

  getPermissionsQuery(id: string) {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'x-data-query': `SELECT * FROM cb_RolPermisos crp JOIN cb_Permisos cp ON crp.idRol ="${id}"  and cp.idPermiso = crp.idPermiso`
      };

      axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          this.permissionsData = resp.data
          resolve(resp.data)
        })
        .catch((error: any) => reject(error));
    })
  }

  getStatistics() {
    return new Promise(async (resolve, reject) => {

      const token = this._SesionstorageService?.GetSesionStorage('token');
      const {idEmpConv} = this._SesionstorageService?.GetSesionStorage('login').Data;
      console.log(idEmpConv);
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
        //  'x-data-query': `SELECT * FROM Reporte_Club_FIbex`
      };
      axios.get(`${environment.URLApiLocal}/ReportCoupons${idEmpConv ? '?idEmpConv='+idEmpConv : ''}`, { headers })
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          const response = resp.data;
          console.log(response);
          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }


  /** Get any query */
  getAnyQuery(db: string, query: string) {
    return new Promise<any[]>(async (resolve, reject) => {
      const headers = {
        'x-data-query': query
      };

      axios.get(`${environment.UrlFull}api/v1/any-queries/` + db, { headers })
        .then((resp: any) => {
          this.permissionsData = resp.data
          resolve(resp.data)
        })
        .catch((error: any) => reject(error));
    })
  }

  async GetAllEstrucura(table: string, campo: string, valor: any) {
    //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Estructura/TableName/cb_EmpresaConvenio
    return new Promise(async (resolve, reject) => {
      axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
        .then((resp: any) => resolve(resp.data))
        .catch((error: any) => {
          console.log(error);
          reject(error)
        });
    })
  }

  CreateData(table: string, idkey: string, body: any) {
    return new Promise((resolve, reject) => {
      // console.log("body");
      // console.log(body);
      // console.log("key");
      // console.log(idkey)
      // console.log("table");
      // console.log(table);

      let config = {
        method: 'post',
        url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${table}`,
        headers: {
          'x-keys-to-add-id': idkey !== '' ? `["${idkey}"]` : '[]',
          'x-keys-of-arrays': '[]',
          'x-relations': 'false',
          'Content-Type': 'application/json'
        },
        data: body
      };
      // console.log(config)
      axios.request(config)
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.log((error as any).response.data);
          reject(error)
        });
    })
  }

  async UpdateData(table: string, campoSearch: string, body: any) {
    return new Promise(async (resolve, reject) => {
      let config = {
        method: 'put',
        url: `${environment.UrlFull}api/v1/update-info/${environment.bd}/${table}/${campoSearch}`,
        headers: {
          'x-multiple-update': 'false',
          'x-elements-obj': '[]',
          'x-attr-duplicate': '[]',
          'campo': '',
          'Content-Type': 'application/json'
        },
        data: body
      };
      axios.request(config)
        .then((response) => resolve(response.data))
        .catch((error) => {
          // console.log(body);
          console.error(error && error.response.data);
          reject(error);
        });
    })
  }

  async ShowInfo() {
    return new Promise(async (resolve, reject) => {
      if (this.ShowInfoTable && this.ShowInfoTable != "") {
        resolve(this.ShowInfoTable)
      } else {
        let config = {
          method: 'get',
          url: `${environment.UrlFull}api/v1/show-all-info/${environment.bd}`,
        };
        axios.request(config)
          .then((response) => { resolve(response.data); this.ShowInfoTable = response.data })
          .catch((error) => reject(error));
      }
    })
  }

  Filtrado(table: any) {
    let info = this.ShowInfoTable.model.filter((datadb: any) => (datadb.tableName === table))
    if (info && info.length > 0) {
      info = info[0].attr.filter((camposexluido: any) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
      return (info);
    }
    return [];
  }

  async CamposTableReturn(table: string) {
    return new Promise(async (resolve, reject) => {
      if (table && table != "") {
        if (this.ShowInfoTable && this.ShowInfoTable != "") {
          // let info =this.ShowInfoTable.model.filter((datadb:any)=>(datadb.tableName === table))
          // if(info && info.length>0){
          //   info =info[0].attr.filter((camposexluido:any)=>( camposexluido!="createdAt" && camposexluido!="updatedAt"));
          //   resolve(info);
          // }else
          // resolve(info);
          let info = this.Filtrado(table);
          return info;
        } else {
          this.ShowInfo()
            .then((resp: any) => {
              this.ShowInfoTable = resp;
              // let info =this.ShowInfoTable.model.filter((datadb:any)=>(datadb.tableName === table))
              // if(info && info.length>0){
              //   info =info[0].attr.filter((camposexluido:any)=>( camposexluido!="createdAt" && camposexluido!="updatedAt"))
              //   resolve(info)
              // }else
              // resolve(info);
              let info = this.Filtrado(table);
              return info;
            })
            .catch((error: any) => { reject({ error: error }) })
        }
      } else {
        reject({ error: "Error debes poner un nombre de tabla" })
      }
    })
  }
  getDataTableCount(table: string, filter?: { name: string, value: string | number }[]) {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'x-data-query': `SELECT COUNT(*) as cantidad FROM ${table} WHERE status=1`
      };
      let querTable = ' AND ('
      if (filter) {

        querTable += filter.map(filtro => `${filtro.name} = '${filtro.value}' `).join(' AND ');
        querTable += ')'
      }
      headers["x-data-query"] += querTable;
      axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => resolve(resp.data[0].cantidad))
        .catch((error: any) => reject(error));
    })
  }
  Test() {
    /*Test1 por campos
    this._dbFullService.GetAllDataCampo('cb_EmpCategoria','nombre','Comida')
    .then((resp)=>{
      console.log("respondio");
      console.log(resp);
    })
    .catch((error:any)=>{
      console.log(error);
    })*/

    /*
    Test2
    let Data ={
      idEmpConv:"100",
      idFranquicia:"GG5HP",
      Nombre: "Damasco",
      Status:true,
      ImgURL: "https://usatoday.com"
    }

    this._dbFullService.CreateData('cb_EmpresaSucursal','idSucursal',Data)
    .then((resp)=>{
      console.log("respondio");
      console.log(resp);
    })
    .catch((error:any)=>{
      console.log(error);
    })*/

    /*
    Test3
    let Data ={
      idEmpCat:"123458",
      nombre: "Comida",
      icon:"fa fa-burguer",
      img: "http://scribd.com2"
    }

    this._dbFullService.UpdateData('cb_EmpCategoria','idEmpCat',Data)
    .then((resp)=>{
      console.log("respondio");
      console.log(resp);
    })
    .catch((error:any)=>{
      console.log(error);
    })
    */
  }

  public getImagePromo = async (): Promise<any | null> => {
    try {
      const headers = {
        directory: this.enc?.encrypt("Consultas"),
        method: this.enc?.encrypt(environment.banners),
        token: this.enc?.encrypt(environment.TokenUpdate),
        platform: this.enc?.encrypt("App"),
        lic: environment.Lic
      }

      const encryptRes: AxiosResponse = await axios.get(`${environment.URLApi}`, { headers })
      const result = this.enc?.DescrypValidateType(encryptRes.data)
      result.map((image: any) => { image.imgDesktop = image.desktop; image.imgPhone = image.phone })
      console.log('result', result)
      return result
    } catch (error) {
      console.error(error)
      return null
    }
  }
  public async getImageQRRedirection(dataQR: Icb_QR){
    return new Promise((resolve,reject)=>{
      const token = this._SesionstorageService?.GetSesionStorage('token');
      const headers = {
        'tknlg': `Bearer ${token}`,
        'Content-Type': 'application/json'
        //  'x-data-query': `SELECT * FROM Reporte_Club_FIbex`
      };
      axios.post(`${environment.URLApiLocal}/GetQRredirection/`, [dataQR.idEmpConv] )
        // axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.bd}`, { headers })
        .then((resp: any) => {
          resolve(resp.data)
        })
        .catch((error: any) => {
          reject(error)
          console.error(error)
        });
    })
  }
async getNotify(id_cliente: string, readed: boolean, page?: number ) {
    return new Promise((resolve, reject) => {
        try {
            console.log("CONSULTANDO NOTIFICACIONES AQUI >>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            const headers = {
              db: this.enc?.encrypt(environment.tm_cobertura),
              type: this.enc?.encrypt('any-queries'),
              'x-data-query': this.enc?.encrypt(`SELECT * from ${environment.table_notification} where id_cliente = '${id_cliente}' and ${readed ? 'read_at is not NULL' : 'read_at is NULL'} ORDER BY createdAt DESC limit 10 offset ${(page || 0) * 10}`),
              "TokenAuthPlataform": environment.tokendbFull,
              "Authorization": environment.authdbFUll,
          };  
         
            // this.http.get(`${environment.ApiBackendThomas}`, { headers: headersData }).subscribe(data => {
            //     console.log(data);
            //     resolve(data)
            // });
            axios.get(`${environment.urlDBFULL}`,{headers: headers}).then(resp=>resolve(resp.data)
            )
            // this.getAnyQuery("thomas_cobertura", `
            //   SELECT * from ${environment.table_notification} 
            //   where id_cliente = '${id_cliente}' and 
            //   ${readed ? 'read_at is not NULL' : 'read_at is NULL'}
            //   limit 10 offset ${(page || 0) * 10}`)
            //     .then(res => {
            //         console.log('HOLA MUNDO', res)
            //         resolve(res)
            //     })
        } catch (error) {
            // this._logs.insertarError(error.message);
            reject(error)
        }
    })
}
async setNotify(data: any, date: any): Promise<any> {
  return new Promise((resolve, reject) => {
      try {
          let headers = {
              'db': this.enc!.encrypt(environment.tm_cobertura),
              'table': this.enc!.encrypt(environment.table_notification),
              'campo': this.enc!.encrypt('id'),
              'x-multiple-update': 'false',
              'x-elements-obj': '[]',
              'x-attr-duplicate': '[]',
              'Authorization': environment.authdbFUll,
              'Content-Type': 'application/json',
              'TokenAuthPlataform': environment.tokendbFull
          },
              body = {
                  'id': data.id,
                  'read_at': date
              }
          axios.put(`${environment.urlDBFULL}update-info`, body, { headers: headers }).then((response: any) => {
            console.log(response);
              resolve(response);
          })

      }
      catch (error) {
          reject(error);
      }
  });
}
async getAllNotifyCount(id_cliente: string, readed: boolean){
  return new Promise((resolve, reject) => {
    try {
        console.log("CONSULTANDO NOTIFICACIONES AQUI >>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        const headers = {
          db: this.enc?.encrypt(environment.tm_cobertura),
          type: this.enc?.encrypt('any-queries'),
          'x-data-query': this.enc?.encrypt(`SELECT COUNT(*) AS 'count' from ${environment.table_notification} where id_cliente = '${id_cliente}' and ${readed ? 'read_at is not NULL' : 'read_at is NULL'}`),
          "TokenAuthPlataform": environment.tokendbFull,
          "Authorization": environment.authdbFUll,
      };  
     
        // this.http.get(`${environment.ApiBackendThomas}`, { headers: headersData }).subscribe(data => {
        //     console.log(data);
        //     resolve(data)
        // });
        axios.get(`${environment.urlDBFULL}`,{headers: headers}).then(resp=>resolve(resp.data[0]['count'])
        )
        // this.getAnyQuery("thomas_cobertura", `
        //   SELECT * from ${environment.table_notification} 
        //   where id_cliente = '${id_cliente}' and 
        //   ${readed ? 'read_at is not NULL' : 'read_at is NULL'}
        //   limit 10 offset ${(page || 0) * 10}`)
        //     .then(res => {
        //         console.log('HOLA MUNDO', res)
        //         resolve(res)
        //     })
    } catch (error) {
        // this._logs.insertarError(error.message);
        reject(error)
    }
})
}
async deleteNotify(data: any, date: any) {
  return new Promise((resolve, reject) => {
      try {
          let headers = {
              'db': this.enc!.encrypt(environment.tm_cobertura),
              'table': this.enc!.encrypt(environment.table_notification),
              'campo': this.enc!.encrypt('id'),
              'x-multiple-update': 'false',
              'x-elements-obj': '[]',
              'x-attr-duplicate': '[]',
              'Authorization': environment.authdbFUll,
              'Content-Type': 'application/json',
              'TokenAuthPlataform': environment.tokendbFull
          },
              body = {
                  'id': data.id,
                  'deleteAt': date
              }
          axios.put(`${environment.urlDBFULL}update-info`, body, { headers: headers }).then((response: any) => {
              if (response.status == 200) {
                  // this.EmiterNotify(true);
              }
              resolve(response);
          })

      }
      catch (error) {
          // this._logs.insertarError(error.message);
          reject(error);
          
      }
  });
}
}

import { Component, Input } from '@angular/core';
import { IFormData } from 'src/app/models/tipo.input';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperAPIPetService } from 'src/app/services/helper-apipet.service';

@Component({
  selector: 'app-dependency-selection',
  templateUrl: './dependency-selection.component.html',
  styleUrls: ['./dependency-selection.component.scss']
})
export class DependencySelectionComponent {
  @Input() formData: IFormData = new IFormData();
  @Input() value: any;
  @Input() multiple: boolean = false;

  // private FullDBObj = new dbFullService();

  @Input() Fields: {
    nameTable: string,
    keyUniqueShow: string,
    keyUniqueValue: string
    keyUniqueRelation?: string
  }[] = [
      {
        nameTable: "cb_EmpresaConvenio",
        keyUniqueShow: "nombre",
        keyUniqueValue: "idEmpConv"
      },
      {
        nameTable: "cb_EmpresaUser",
        keyUniqueShow: "Nombre",
        keyUniqueValue: "idProducto",
        keyUniqueRelation: "idEmpConv"
      },
    ];

  // Fields Example
  // [
  //   {
  //     nameTable: "cb_EmpresaConvenio",
  //     keyUniqueShow: "nombre",
  //     keyUniqueValue: "idEmpConv"
  //   },
  //   {
  //     nameTable: "cb_EmpresaUser",
  //     keyUniqueShow: "Nombre",
  //     keyUniqueValue: "idProducto",
  //     keyUniqueRelation: "idEmpConv"
  //   },
  // ]

  public __options: { datoShow: string, value: string}[][] = [];

  constructor(private _helperApi: HelperAPIPetService){}

  GetIndex(i: number) {
    return this.__options[i] ?? [];
  }

  ngOnInit() {
    this.PrepareRelations();
  }

  async PrepareRelations(indexStart: number = 0) {
    for(let index = indexStart; index < this.Fields.length; index++) {
      const field = this.Fields[index];
      const dataCampo: {[key: string]: any}[] = await this._helperApi.GetAllDataCampo(field.nameTable, "status", 1);

      function funMap(item: {[key: string]: any}) {
          return {
          datoShow: item[field.keyUniqueShow],
          value: item[field.keyUniqueValue]
        };
      }

      if(index > 0) {
        const lastField = this.Fields[index-1];

        if(this.formData.vars[lastField.nameTable] !== undefined) {
          this.__options[index] = dataCampo.filter(item => {
            if(this.multiple) {
              const valueArray = this.formData.vars[lastField.nameTable];

              return Boolean(valueArray instanceof Array && valueArray.some((v: any) => v === item[field.keyUniqueRelation ?? ""]))
            }
            // console.log()
            return this.formData.vars[lastField.nameTable] == item[field.keyUniqueRelation ?? ""];
          }).map(funMap);
        }
        else break; // salir del ciclo
      }
      else {
        this.__options[index] = dataCampo.map(funMap);
      }
    }
  }

  UpdateValue(name: string, value: any) {
    this.formData.vars[name] = value;
    const index = this.Fields.findIndex(f => f.nameTable === name);

    if(index !== -1) this.PrepareRelations(index+1);
  }
}

import { Component, Input } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();

  ngOnInit() {
    this.formData.vars[this.data.Campo] = this.formData.vars[this.data.Campo] || "#000000";
  }

  HandlerChange(event: any) {
    console.log("NEW COLOR: ", event.target.value);
    this.formData.vars[this.data.Campo] = event.target.value;
  }
}

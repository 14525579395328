import { Component } from '@angular/core';

@Component({
  selector: 'app-coupons-tag',
  templateUrl: './coupons-tag.component.html',
  styleUrls: ['./coupons-tag.component.scss']
})
export class CouponsTagComponent {

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, type OnInit } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiClubService } from 'src/app/services/api-club.service';
import { IQrCompany } from 'src/app/models/attributes';
import Swal from 'sweetalert2';
import { Icb_QR } from 'src/app/models/tables';

@Component({
  selector: 'app-modal-show-qr',
  templateUrl: './modalShowQR.component.html',
  styleUrls: ['./modalShowQR.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalShowQRComponent implements OnInit {

  
  @Input() dataQR!:IQrCompany;
  @Input() tableName: string = "";
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  public image: string = "";

  public allyhasQR: boolean = true;
  public isLoadedImage: boolean = false;

  constructor(
    private apiclub: ApiClubService,
    private cdr: ChangeDetectorRef,
    private dbFull: dbFullService){}

async ngOnInit() { 
    try {
      const response = await this.getQRByIdEmp();
      this.image = response["image"];
      this.isLoadedImage= true;
      this.cdr.markForCheck();
      console.log(this.isLoadedImage);
     
    } catch (error) {
      this.allyhasQR = false;
      this.isLoadedImage= true;
      this.cdr.markForCheck();
      console.error(error);      
    }
   
    
  }
  public async getQRByIdEmp():Promise<any>{
    try {
      const query: string =  this.tableName== "cb_QR" 
      ?  `SELECT imgQR FROM cb_QR qe WHERE qe.Status=1 AND qe.idEmpConv="${this.dataQR.idEmpConv}"`
      : `SELECT QRurl FROM cb_QREmpresa qe WHERE qe.Status=1 AND idEmpConv="${this.dataQR.idEmpConv}"`;
      const response: any[] = await this.dbFull.getAnyQuery(environment.bd,query);
      if( Array.isArray(response) && response.length>0 ){
        console.log(response);
        const value: any[] = Object.values(response[0]); 
       return Promise.resolve({"image": value[0]});
      }
    else{
      return Promise.reject({"error":"El aliado no tiene un QR asignado"});
    }
    } catch (error: any) {
      if(error.hasOwnProperty("name")){
       return Promise.reject({"error":error.name});
      }
    console.error(error);
    }
  }
  public closeModal(){
    this.SalidaClickMenu.emit();
  }
  downloadImage() {
    // Supongamos que 'imageURL' es la URL de la imagen que quieres descargar
    
    var imageURL = this.image;

    this.download(imageURL,  `QR_${this.dataQR.name ? this.dataQR.name : this.dataQR.idEmpConv}`);
}
download(imageUrl: string, imageName: string) {
  fetch(imageUrl).then(res => res.blob()).then(blob => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  });
}
async generateQR(){
  try {
    this.closeModal();
    if(this.tableName === "cb_QR"){
      this.apiclub.generateQR_Redirection({idQR: this.dataQR.codigoEmpresa!, idEmpConv: this.dataQR.idEmpConv})
    }else{
    this.apiclub.generateQrCompany(this.dataQR).then(response=>{
      console.log(response)
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Correcto",
          text: "Se ha generado su QR correctamente",
          footer: "Presione el boton para continuar",
          confirmButtonText: "Aceptar"
        })
      }, 500)
    })
    }
   
  } catch (error) {
    console.error(error);
  }
 
}
}

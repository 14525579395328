import { Injectable, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ObserverTableEvents } from 'src/app/shared/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class ObservableComponentsService {

  nextObj = new EventEmitter<ObserverTableEvents>();
  prevObj = new EventEmitter<ObserverTableEvents>();
  modifyTotalReg = new EventEmitter<ObserverTableEvents>();


  // ! Obervable para componente *boton*
  paramData$: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageTitleOptions$: EventEmitter<string> = new EventEmitter<string>();
  // Observable para monitorear el Id de la accion del componente
  dataIdAction$: EventEmitter<string> = new EventEmitter<string>();
  //observable para monitorear el boton de redireccion de las notificaciones
  notificationButton$:EventEmitter<any> = new EventEmitter<any>();
}

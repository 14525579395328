import { Component } from '@angular/core';

@Component({
  selector: 'app-promotions-tag',
  templateUrl: './promotions-tag.component.html',
  styleUrls: ['./promotions-tag.component.scss']
})
export class PromotionsTagComponent {

}

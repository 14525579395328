<div class="card bd-primary tableContainer">
    <div class="card-header bg-primary tx-white">Estadísticas</div>
    <div class="card-body pd-sm-30" style="max-height: 600px; height: 100%; overflow: auto;">
        
      
      <div class="d-inline-block">
        <div
        
        class="btn_filter" *ngIf="!loading" (click)="showModalFilter()">
            FILTRO <i class="icon ion-stats-bars"></i> 
            <!-- ion-navicon-round -->
        </div> 
        <button *ngIf="isfilteredTable" class="btn btn-danger btn_no_filter"
        
        (click)="stopFilter()"
        >No filtrar</button>

      </div>
  
      <p class="mg-b-20 mg-sm-b-30"> Registro de Aliados </p>

      <button class="btn btn-success mx-1" (click)="export()">Descargar reporte</button>
      <div *ngIf="showFilter" class="modal-background-black" >
        <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Filtrar</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="cancelFilter()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
                <form [formGroup]="formG" class="row">
                    <div class="col-12">
                        <label  class="col-12" for="" class="form-label">Selecciona una ciudad</label>
                        <select class="col-12" class="form-select form-control" formControlName="selectEnt"  >
                            <option value="">---Selecciona una ciudad---</option>
                            <option *ngFor="let item of selectEmterprise" [value]="item">{{item | titlecase}}</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label class="col-12" for="" class="form-label">Selecciona una categoría</label>
                        <select  class="form-select col-12 form-control" formControlName="selectProm" >
                            <option value="">---Selecciona una categoría---</option>
                            <option *ngFor="let item of selectProm" [value]="item">{{item | titlecase}}</option>
                        </select>
                    </div>   
                </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="cancelFilter()">Cancelar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="confirmFilter()">Aceptar</button>
        </div>
        </div>
        </div>
    </div>
     
    
      <div class="table-wrapper mt-3">
          <div class="scrollable-element subWrapper">
              <table  #datatable3 class="table display responsive nowrap tableWidth"  id="datatable3">
                <thead class="tableWidth">
                    <tr>
                     <th *ngFor="let header of rowHead; index as i">{{header}}</th>
                    </tr>
                </thead>
                <tbody>  
                            <tr  *ngFor="let rowData of dataOrganizada">
                            
                                <td>{{rowData.Empresa | uppercase}}</td>
                                <td>{{rowData.fechaIngreso}}</td>
                                <td>{{rowData.rif }}</td>
                                <td>{{rowData.direccionFiscal | titlecase}}</td>
                                <td>{{rowData.razonSocial}}</td>
                                <td>{{rowData.ciudad | titlecase}}  </td>
                                <td>{{rowData.Categoria | titlecase}}</td>
                                <td>{{rowData.aliadoStatus | titlecase}}</td>
                                
                                
                            </tr> 
                            <tr *ngIf="loading;" class="tableWidth loader ">
                                <td [colSpan]="rowHead.length">
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </td>
                            </tr>

                   
                    
                </tbody>
                  
                  <!-- <thead>
                      <tr>
                          <th *ngFor="let head of FieldAndHead?.head" [width]="WithCalculeSize">{{ head }}</th>   
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of ArrayDataAll; let i = index" >  
                          <td class="table-item-td" *ngFor="let key of validFields;" [ngClass]="{'tbl-img': isFieldImage(key), '': !isFieldImage(key)}">
                              <ng-container *ngIf="!isFieldImage(key) && !isFieldDate(key) && !isFieldStatus(key)">{{ item[key] ?? 'S/I' }}</ng-container>
                              <ng-container *ngIf="isFieldDate(key)">{{formatDate(item[key])}}</ng-container>
                              <ng-container *ngIf="isFieldStatus(key)">{{item[key] ? 'ACTIVO' : 'INACTIVO'}}</ng-container>
                              <img *ngIf="isFieldImage(key)" src="{{ item[key] ? item[key] : 'https://res.cloudinary.com/thomasv9/image/upload/v1681065837/ClubFibex/users_sjlanv.png' }}" alt="{{item[key]}}">
                          </td>
                      </tr>
                      <tr *ngIf="loading" class="loader">
                          <td [colSpan]="(FieldAndHead?.head?.length || 0) + 1">
                              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                          </td>
                      </tr>
                  </tbody>   -->
              </table>
              
          </div>
      </div>
      
     </div>
  </div>

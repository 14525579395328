import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-config-franquicias',
  templateUrl: './config-franquicias.component.html',
  styleUrls: ['./config-franquicias.component.scss']
})
export class ConfigFranquiciasComponent {

  optionsMenu: {id:number, name:string, icon:string,}[] = [];

  tableList = [
    'cb_Franquicia'
  ]

  tableRelations = [

  ]

  TableName = 'cb_Franquicia'

  attributes = { title: 'Franquicias', description: 'Listado control de las franquicias' }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {
    this.initEstructure()
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'ConfigFranquiciasComponent' && e.Tipo === 'Table')
      console.log("LISTA DE ACCIONES DE ","FRANQUICIAS",": ",options);
      this.optionsMenu = options ? this.helper.sortOptions(options) : []
  }
}

<div class="row">
  <div class="col-6 col-sm-4 col-md" *ngFor="let module of getOnlyModulesWithRoutes()">
    <a class="shortcut-icon"
      [routerLink]="'/dashboard/' + module.route"
      (click)="dashboardModulesService.setCurrentModule(module)">
      <div>
        <i class="icon ion-ios-albums-outline"></i>
        <span>{{ module.name }} asdasd</span>
      </div>
    </a>
  </div><!-- col -->
</div><!-- row -->
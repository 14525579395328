import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit{

  tableList = [
    'cb_EmpresaUser'
  ]
  
  public optionsMenu =[
    {id:1, name: 'Eliminar', icon:'icon ion-trash-a'},
    {id:2, name:'Editar', icon:'icon ion-edit'}
  ]
  // public optionsMenu =[
  //   {id:1, name: 'Eliminar', icon:'icon ion-trash-a'},
  //   {id:2, name:'Editar', icon:'icon ion-edit'},
  //   {id:3, name:'Sucursales', icon:'fa fa-building'}
  // ]
  
  tableRelations = [
    
  ]
  
  TableName = 'cb_EmpresaUser'
  
  attributes = { title: 'Usuarios', description: 'Listado control de los usuarios' }

  admin: boolean = false;

  get sessionStorageData() {
    const asd =this._SesionstorageService.GetSesionStorage("login")
    //const asd = sessionStorage.getItem('login');
    return (asd !== null) ? JSON.parse(asd) : null;
  }

  userElement: any;

  constructor(
    public dashboardModulesService: DashboardModulesService,
    private DbFull: dbFullService,
    private helper: HelperService,
    private router: Router,
    private _SesionstorageService: SesionstorageService
  ) { }

  ngOnInit(): void {
    this.admin =  this._SesionstorageService.GetSesionStorage("login").admin || false;
    //this.admin = JSON.parse(sessionStorage.getItem("login") || "{}").admin || false;
    const user = this._SesionstorageService.GetSesionStorage("login")
    //const user = JSON.parse(sessionStorage.getItem("login") || "{}")
    // if( this.sessionStorageData.idUserEmp ) {
    //   this.cdata.Value.push(`idUserEmp_${this.sessionStorageData.idUserEmp}`)
    // }

    this.userElement = {
      user:{
        type: "EDITAR",
        element: user,
        option: {
          id: 2,
          name: "Editar",
          icon: "icon ion-edit"
        }
      },
      stat:true
    }
  }
}




import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { SesionstorageService } from "src/app/services/sesionstorage.service";
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-master-validatorqr',
  template: `
  <iframe #myIframe style="width: 100%; height: 80vh; border: none;" sandbox="allow-same-origin allow-scripts allow-forms"></iframe>
  `,
})
export class MasterValidatorqrComponent implements OnInit{
  @ViewChild('myIframe') myIframe: ElementRef | undefined;
  public Token = '';
  public Enlace= 'https://puntoclub.fibextelecom.net/';
  
  constructor(private _SesionstorageService:SesionstorageService,private _sanitizer: DomSanitizer){
  }
  ngOnInit() {
    this.Token = this._SesionstorageService.GetSesionStorage('token')
  }

  ngAfterViewInit() {
    const url = new URL(this.Enlace);
    url.searchParams.set("token", this.Token);
    const newEnlace= url.href;
    const iframe = this.myIframe?.nativeElement;
    iframe.src = newEnlace;
  }




}

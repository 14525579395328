<div class="dropdown custom-dropdown">
  <input 
  [disabled]="imgDisabled === 1 ? true : false" [name]="name" hidden type="file" #uploader a (change)="uploadImagePayment2($event)" accept=".jpg, .jpeg, .png, .webp"/>
  <a href="#" data-toggle="dropdown" class="dropdown-link rounded-circle bd" aria-haspopup="true" aria-expanded="false">
    <img   (mouseenter)="showEditimg=true;" [src]="UrlIMg != '' ? UrlIMg : ImgDefecto"
      
      class="wd-100 ht-100 rounded-circle pd-2 img-avatar-class" alt="">
  </a>
  <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton" *ngIf="imgDisabled==0" >
    <a role="button" class="dropdown-item" *ngIf="showEditimg" (click)="uploader.click()">{{UrlIMg != ImgDefecto && UrlIMg ? "Editar imagen" : "Agregar imagen"}}</a>
    <a role="button" class="dropdown-item" *ngIf="showEditimg && UrlIMg != ImgDefecto && UrlIMg"  (click)="RemoveAvatar()">Eliminar Imagen</a>
  </div>
  <div *ngIf="downloadImg===1 &&  UrlIMg">
    <button class="btn btn-primary" type="button" (click)="download()">Descargar</button> 
  </div>  
</div>

<div style="color: red" *ngIf="!ValidExtension">Formato de imagen no válido</div>
<div style="color: red" *ngIf="!ValidSize">Imagen mayor a {{limitMB}}MB</div>
<div style="color: red" *ngIf="currentResolution && !validResolutionSize">Las dimensiones de la imagen deben estar en un tamaño de {{currentResolution.widht}}x{{currentResolution.height}} px</div>

<!-- <div class="media align-items-center" (mouseenter)="showEditimg=true;">
  <div>
    <button class="ButtonCustomise" *ngIf="showEditimg" (click)="uploader.click()" (mouseleave)="showEditimg=false;">
      <i class="fa fa-pencil" style='font-size:24px; color: rgba(255, 255, 255, 0.87); margin-left:45px;'></i>
    </button>
    <button class="ButtonCustomise" *ngIf="showEditimg" (click)="RemoveAvatar()" (mouseleave)="showEditimg=false;">
      <i class="fa fa-trash" style='font-size:24px; color: rgba(255, 255, 255, 0.87); margin-left:15px;'
        aria-hidden="true"></i>
    </button>
  </div>
  <img [src]="UrlIMg !=''? UrlIMg : ImgDefecto" class="wd-100 ht-100 rounded-circle bd pd-5" alt="">
</div> -->
<!-- <div [class.ImageBlack] = "showEditimg" [style.backgroundImage]="'url('+UrlIMg+')'"> -->
<!-- <div class="overlay"></div> -->
<!-- </div> -->

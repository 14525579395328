import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { ApiClubService } from 'src/app/services/api-club.service';
import { DataTableService } from 'src/app/services/datatable.service';
import Swal from 'sweetalert2';

interface IReport {
	status: boolean;
	data: IDataReport[]
}

interface IDataReport{
	Empresa:string;
	Ciudad:string;
	idAbonado:string;
	nombrPromocion:string;
	Canjeado:string;
	FechaGenerado:string;
	HoraGenerado:string;
	FechaCanjeado: string,
	HoraCanjeado: string,
	nombreAbonado:string;
}

@Component({
  selector: 'app-report-giftcard',
  templateUrl: './report-giftcard.component.html',
  styleUrls: ['./report-giftcard.component.scss'],
  encapsulation: ViewEncapsulation.None // Desactiva la encapsulación
})
export class ReportGiftcardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("datatable3", { static: true}) datatable3!: ElementRef;
	showMenu: boolean = false;
	showOptions: boolean = false;
	loading: boolean = true;
    currentTable: any[] = []
	private inicializedTable: boolean = false;
	public showFilter: boolean = false;
	public isfilteredTable: boolean = false;
	public errorFechaMax: boolean = false;
	public dataLengthGiftcard: number = 100000;
	constructor(private dbFull: dbFullService, private fb: FormBuilder, private helper: HelperService, private datatableService: DataTableService, ){

	}
	public data: any[] = [];
	public selectEmterprise: any[] = [] 
	selectProm: any[] = []; 
	public dataAgrouped: any[]=[];
    public areExportingData: boolean = false;
	public rowHead: any[] = ["Abonado","codigo","Estado de Uso", "Fecha Canjeo"];

	public formG: FormGroup = this.fb.group({
		minD: [,],
		maxD: [,],
		selectEnt: [,],
		selectProm: [,]
	});

	public columns = [
		{
		  data: 'id_abonado',
		  title: 'Abonado',
		  name: 'Abonado',
		  render: function (data: any, type: any, row: any) {
			return data && data.trim().length > 0 ? data : 'S/I';
		  }
		},
		{
		  data: 'name_client',
		  title: 'Nombre Abonado',
		  name: 'Nombre Abonado',
		  render: function (data: any, type: any, row: any) {
			return data && data.trim().length > 0 ? data : 'S/I';
		  }
		},
		{
		  data: 'codigo',
		  title: 'Codigo',
		  name: 'Codigo',
		  render: function (data: any, type: any, row: any) {
			return data && data.slice(-2).padStart(data.length, '*');
		  }
		},
		{
		  data: 'status_uso',
		  title: 'Estatus de uso',
		  name: 'Estatus de uso',
		  render: function (data: any, type: any, row: any) {
			return data ? 'ACTIVO' : 'INACTIVO';
		  }
		},
		{
		  data: 'updatedAt',
		  title: 'Fecha canjeo',
		  name: 'Fecha canjeo',
		  render: function (data: any, type: any, row: any) {
			if (!data) return "";
			const date = new Date(data);
			return date.toLocaleDateString('es-VE', { day: '2-digit', month: '2-digit', year: 'numeric' });
		  }
		}
	  ];
	  

	public obsEnt!: Subscription;
	public table!: any;
	
	public timerFilter!: NodeJS.Timeout; 
	
	ngOnInit(): void {
	
	}
	ngAfterViewInit(): void {
		this.initData();
    	const control =this.formG.get("selectEnt");
		if(control){
		this.obsEnt = control.valueChanges.subscribe(resp=>{
			this.selectProm = Object.keys(this.groupBy(this.dataAgrouped[resp],'nombrPromocion'));
			
      	})
    }
	
	}
    
	initTable() {
		($(this.datatable3.nativeElement) as any).DataTable({
			language: {
				searchPlaceholder: 'Buscar...',
				sSearch: '',
        processing: true, 
        serverSide: true,
        'ajax': (dataTablesParameters: any, callback: any) => { 
          this.datatableService.getUsabilityGiftCards(dataTablesParameters).then((resp: any) => { 
            callback(
              { recordsTotal: resp.totalRecords, 
                recordsFiltered: resp.filteredRecords, 
                data: resp.data 
              }); 
            }); 
          },
				info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
				lengthMenu: 'Mostrar _MENU_',
				paginate:{
					previous:'Anterior',
					next:'Siguiente'
				},
			},
			dom: 'Bfrtip',
    buttons: [
        'excelHtml5'
    ]
        });
		
	}
    toOpenMenu() {
        setTimeout(() => {
                document.body.addEventListener("click", this.toCloseMenu);
        }, 200)
        this.showMenu = true;
    }

    toCloseMenu = () => {
        document.body.removeEventListener("click", this.toCloseMenu)
        if(!this.showOptions){
            this.showMenu = false;
        }
        this.showOptions = false;
    }

    getStatistics(){
        // return new Promise(async(resolve,reject)=>{

        //     const data: IReport = <IReport> await this.apiClub.getUsabilityGiftCards();
        //     console.log(data);
		// 	  if(data && data.data.length>0){
        //         this.data=data.data;
        //         resolve({status: true});
        //     } 
        //     else{reject({status: false})}
        // })
        
    }

    export() {  
		
        if (this.currentTable) {
        	const headers:string[] = []
        	const refHeaders:string[] = []
        	const form = this.formG;

		
			const formPromo =  form.get("selectProm")?.value;
			let sheets: any[][] = []
		

            //To set row headers
        	for (const header of this.rowHead) {
        		headers.push(header)
        	}
            for (const refHeader in this.data[0]) {
                refHeaders.push(refHeader)
            }
			// console.log(refHeaders.length);
			// if(refHeaders.length>=9){
			
				// const lastL =refHeaders.pop();
			
				// refHeaders.splice(3,0,lastL!);
			// }
			
        	sheets.push(headers)
            
        	for (let i = 0; i < this.currentTable.length; i++) {
        		let column: any[] = []
                let isvalidDateFilter = false;
				let isvalidEntFilter = false;
				
				// console.log(refHeaders);
        		for (const header of refHeaders) {  
			
					
					
        			
        		}
				if(isvalidEntFilter  && isvalidDateFilter){
					sheets.push(column)
				}
        		
        	}
			// console.log(this.currentTable);
        	const workbook = XLSX.utils.book_new();
			const tabl_sort = this.sortMatriz(sheets);
			console.log(tabl_sort);
        	const worksheet = XLSX.utils.aoa_to_sheet(tabl_sort);
			const max_width = tabl_sort[0].reduce((w, r) => Math.max(w, r[0].length), 20);
			worksheet["!cols"] = [ { wch: max_width },
				{wch:10},  // Ancho de la columna 1
				{wch:30},  // Ancho de la columna 2
				{wch:45}   // Ancho de la columna 3
			];


        	XLSX.utils.book_append_sheet(workbook, worksheet);
        	var wopts = { bookType:"xlsx", bookSST:false, type:"array" } as const;
        	var wbout = XLSX.write(workbook, wopts);

        	const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"});
        	var reader = new FileReader();

        	reader.onload = (ev) => {
        		const base64URL = reader.result as string;

        		const linkElement = document.createElement("a");
        		linkElement.style.display = "none";
        		linkElement.href = base64URL;
        		linkElement.download = "reporte_cupones.xlsx";
        		linkElement.click();
        	}
        	reader.readAsDataURL(blob);
        }
    }

	async initData(){
		const responseLen: number = await this.datatableService.getLengthDataGiftcard() as number;
		this.dataLengthGiftcard = responseLen;
		console.log(responseLen);
		
        // const response: {status: boolean} = await this.getStatistics()
        this.loading = false;
        // response.status 
        // ? this.currentTable = this.data
        // : this.currentTable = []        
        //this.data va a ser tu data original no la modifiques
			setTimeout(() => {
			if(!this. inicializedTable) {
				this. inicializedTable = true;				
		this.table = ($(this.datatable3.nativeElement) as any).DataTable({
					language: {
						searchPlaceholder: 'Buscar...',
						sSearch: '',
						info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
						lengthMenu: 'Mostrar _MENU_',
						paginate:{
							previous:'Anterior',
							next:'Siguiente'
						}
					},
					processing: true, 
					serverSide: true,
					ajax:  (dataTablesParameters: any, callback: any) => { 
					  this.datatableService.getUsabilityGiftCards(dataTablesParameters).then((resp: any) => { 
						console.log(resp);
						callback(
						  { recordsTotal: this.dataLengthGiftcard, 
							recordsFiltered:dataTablesParameters.search.value.length>0 ? resp.filteredRecords :  this.dataLengthGiftcard, 
							data: resp.data
						  }); 
						}); 
					  },
		
					  columns: this.columns
					});
        
        }				 
				 
			},500)	
	}
	getLengthTable(){

	}
	genLengTableFiltered(){

	}
	cambiarData(){
		
	
		const formG = this.formG;
		let min_forms = (formG.get("minD")?.value === null) ? "1969-12-31" : formG.get("minD")?.value;
		let max_forms =  (formG.get("maxD")?.value === null) ? "2999-12-31" : formG.get("maxD")?.value;
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
		
			let min: any =new Date(min_forms);
			min.setMinutes(min.getMinutes() + min.getTimezoneOffset());
			let max =new Date(max_forms);
			
			max.setUTCHours(23 );
			max.setUTCMinutes(59+ max.getTimezoneOffset());
			max.setUTCSeconds(59);
		
			
			console.log(data[6]);
			
			let   date = new Date(data[6]);
			date.setMinutes(date.getMinutes()+date.getTimezoneOffset());
		
			if (
				(min === null && max === null) || 
				(min === null && date <= max) ||
				(min <= date && max === null) ||
				(min <= date && date <= max)
			) {
				return true;
			}
			return false;
		});
		// console.log("drawing");
		
		//CONSECUENTEMENTE
		 this.changeEnterprise();
		
	
	}
	changeEnterprise(){
		// console.log("data");
		// this.table.draw();
		const formG = this.formG;
	
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
			
			let nombreEmp = formG.get("selectEnt")?.value;
			let nombrePromo = formG.get("selectProm")?.value;
			

			const dataEmpr = data[0];
			// console.log(dataEmpr);
			const dataPromo = data[4];
			 console.log(dataEmpr, dataPromo);
		 		if (
				 ((nombreEmp === "" || nombreEmp === null) && (nombrePromo === "" || nombrePromo === null)) ||

				 (nombreEmp === dataEmpr && (nombrePromo === null || nombrePromo==""))||
				 (nombreEmp === dataEmpr &&  nombrePromo === dataPromo ) 
				){return true;
				}
				else return false;
		});
		// console.log("drawing");
		
		this.table.draw();
		
	}
	showModalFilter(){
		
		this.showFilter=true;
		this.obtenerGrupo();
	}
	hideModalFilter(){
		this.showFilter=false; 
		
		if(this.isfilteredTable){
			
		if(this.timerFilter){
			clearTimeout(this.timerFilter);
		}	
		this.timerFilter = setTimeout(()=>{
			 
				this.cambiarData();
				
			},1000)	
		}
		
	}
	obtenerGrupo(){
       
		this.dataAgrouped = this.groupBy(this.currentTable,'Empresa');
       
		const groupList: any[] = Object.keys(this.dataAgrouped);
		this.selectEmterprise = groupList;
	
		}
	groupBy(array: any[], key: any) {
			return array.reduce((result, currentValue) => {
			if(typeof(currentValue)=="string"){
				(result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
			}
			else (result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
		  return result;
		}, {});
	}	
	confirmFilter(){
	const form = this.formG;

	if(form.valid && form.touched){
		if(new Date(form.get("maxD")?.value)>=new Date(form.get("minD")?.value)){
			this.errorFechaMax=false;
			this.isfilteredTable=true;

			this.hideModalFilter();
		}
		else if(form.get("minD")?.value && form.get("maxD")?.value==null){
			this.isfilteredTable=true;
			this.hideModalFilter();

		} 
		else{
           this.errorFechaMax=true;
		}
	}else if(form.untouched){ 
		
		this.hideModalFilter();}
     
	}
	stopFilter(){
		
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.isfilteredTable = false;
		this.formG.reset();
		this.cambiarData();
	
	}
	sortMatriz(matriz: any[][])
	{
	const matrizOrdenada = matriz.slice(1); 
	matrizOrdenada.sort((a:any, b:any) => {
			if (a[0] === b[0] ) {
				if (a[3] === b[3] ) {
					return new Date(a[5]).getTime() - new Date(b[5]).getTime();
				}
				return a[3].localeCompare(b[3]);
			}
			return a[0].localeCompare(b[0]);
		});
	
		return [matriz[0], ...matrizOrdenada]
	}
	ngOnDestroy(): void {
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.obsEnt.unsubscribe();
	}
	onClickButtonExport(): void {
		Swal.fire({
			title: "¿Deseas descargar el documento?",
			showDenyButton: true,
			confirmButtonText: "Aceptar",
			denyButtonText: `Cancelar`
		  }).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) 
			 this.downloadExcel();
		  });
	}

	async  downloadExcel() {
		try {
		  this.areExportingData = true;
		  const allData = await this.datatableService.getAllData();
		  console.log(allData);
	  
		  // Obtener los títulos de las columnas
		  const columnTitles: any = this.columns.reduce((acc: any, column: any) => {
			acc[column.data] = column.title;
			return acc;
		  }, {});
	  
		  // Reemplazar claves con títulos
		  const excelData = allData.map(item => {
			const newItem: any = {};
			for (const key in item) {
			  if (item.hasOwnProperty(key)) {
				newItem[columnTitles[key]] = item[key];
			  }
			}
			return newItem;
		  });
	  
		  const worksheet = XLSX.utils.json_to_sheet(excelData);
		  const workbook = XLSX.utils.book_new();
		  this.areExportingData = false;
		  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
		  XLSX.writeFile(workbook, 'reporte_tarjetas.xlsx');
		} catch (error) {
			
		 this.areExportingData = false;
		  Swal.fire({
			icon: "error",
			title: "Error",
			text: "¡Ha ocurrido un error en la generación de su reporte por favor intente nuevamente!",
		  });
		  console.error('Error al descargar los datos:', error);
		}
	  }
	  
 }

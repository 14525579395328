import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SesionstorageService } from "./sesionstorage.service";
import { dbFullService } from "./dbFull.service";


@Injectable({
    providedIn: 'root'
  })
  export class PushNotificationService{

    constructor(  private _localStorageService: SesionstorageService, private consultService: dbFullService){
        this.initSocket()
    }
    private user: any;
    private connection: WebSocket | undefined;
    public urlSocket = environment.wsClubFibex;
    public lengthMessagesNotRead: number = 0;
    canReadMoreMessageReaded = false;
    pageLimitMessagesReaded = 0;
    loadingReaded = false;
    canReadMoreMessageUnreaded = false;
    pageLimitMessagesUnreaded = 0;
    loadingUnreaded = false;
  initializedNotificationsWhenSocketInit = false;
  notificationsRead: any[] = [];
  notificationNotRead: any[] = [];
  fechaHoraActual = new Date();
  fechaHoraFormateada = this.fechaHoraActual.toISOString();
    public initSocket(
      
        
    ){
        try {
            if(!this.connection) {
              console.log("USER INIT CONNECT");
              this.user = this._localStorageService.GetSesionStorage("login").Data;
              console.log(this.user);
              console.log("USER INIT CONNECT GETTED USER");          
              if(this.user) {
                const connection = this.connection = new WebSocket(this.urlSocket+"/notifications-promotions/"+ encodeURIComponent(this.user.idUser));
                connection.onmessage = (event) => {
                  console.log("DATOS RECIBIDOS");
                  let notificationsJSON: {data: any,event:any} = JSON.parse(event.data);
                  let data = JSON.parse(notificationsJSON.data)
                  console.log(notificationsJSON);
                  if (data.notification === true) {
                    console.log(data.notification);
                    console.log('actualizando nostificaciones')
                     this.loadMessagesUnreaded(this.user.idUser, 0);
                  }
                }
            
                connection.onerror = (event) => {
                  // aun por definir
                }
            
                connection.onclose = (event) => {
                  // se ejecuta cuando se cierre la conexion
                  console.log('Recuperando conexion')
        
                  if(this.connection) {
                    setTimeout(() => {
                      this.connection = undefined;
                      this.initSocket();
                    }, 1000)
                  }
                }
            
                connection.onopen = (event) => {
                  console.log("Conexión con las notificaciones establecida");
                  // se ejecuta cuando se conecta
                  console.log(this.initializedNotificationsWhenSocketInit);
                  
                  if(!this.initializedNotificationsWhenSocketInit) {
                    this.initializedNotificationsWhenSocketInit = true;
                    this.loadMessagesUnreaded(this.user.idUser, 0);
                    this.loadMessagesReaded(this.user.idUser, 0);
                  }
                }
              }
              // else {
              //   setTimeout(() => {
                //     this.initConnectSocket();
              //   }, 1000);
              // }
            }
          }
          catch(err) {
            setTimeout(() => {
              this.initSocket();
            }, 1000);
          }
    }
    stopConnection() {
      if(this.connection) {
        const connection = this.connection;
        this.connection = undefined;
        this.initializedNotificationsWhenSocketInit = false;
        this.lengthMessagesNotRead = 0;
        try {
          connection.close();
          this.notificationsRead = [];
          this.notificationNotRead = [];
          console.log("Conexión con las notificaciones finalizada");
        }
        catch(err) {
          console.error(err);
        }
      }
    }
    public loadMessagesReaded(nro_contrato: string, page: number) {
      this.loadingReaded = true;
      this.consultService.getNotify(nro_contrato, true, page).then((messages) => {
        let notificationsRead: any[] = [];
  
        notificationsRead = messages as any[];
        notificationsRead = notificationsRead.filter(message => message.read_at !== null);
        notificationsRead.splice(0, notificationsRead.length, ...notificationsRead.filter(message => message.deleteAt === null));
        notificationsRead = notificationsRead.sort((c, d) => {
          const dateC = new Date(c.createdAt);
          const dateD = new Date(d.createdAt);
          return dateC.getTime() - dateD.getTime();
        }).reverse();
  
        this.pageLimitMessagesReaded = page;
        this.canReadMoreMessageReaded = notificationsRead.length >= 10;
        if(page > 0) {
          this.notificationsRead = [...this.notificationsRead, ...notificationsRead];
        }
        else {
          this.notificationsRead = [...notificationsRead];
        }
        console.log(this.notificationsRead);
        
      }).finally(() => {
        this.loadingReaded = false;
      })
    }
    public loadMessagesUnreaded(nro_contrato: string, page: number) {
      this.loadingUnreaded = true;
      this.getAllLengthMessagesNotRead();
      this.consultService.getNotify(nro_contrato, false, page).then((messages) => {
        let notificationsNotRead: any[] = [];
  
        notificationsNotRead = messages as any[];
        notificationsNotRead = notificationsNotRead.filter((m) => m.read_at === null);
        notificationsNotRead = notificationsNotRead.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA.getTime() - dateB.getTime();
        }).reverse();
  
        this.pageLimitMessagesUnreaded = page;
        this.canReadMoreMessageUnreaded = notificationsNotRead.length >= 10;
        if(page > 0) {
          this.notificationNotRead = [...this.notificationNotRead, ...notificationsNotRead];
        }
        else {
          this.notificationNotRead = [...notificationsNotRead];
        }
        console.log(this.notificationNotRead);
      }).finally(() => {
        this.loadingUnreaded = false;
      })
    }
  
    public loadMoreMessagesReaded(nro_contrato: string) {
      return this.loadMessagesReaded(nro_contrato, this.pageLimitMessagesReaded+1);
    }
    public loadMoreMessagesUnreaded(nro_contrato: string) {
      return this.loadMessagesUnreaded(nro_contrato, this.pageLimitMessagesUnreaded+1);
    }
  
    // public loadMessages(nro_contrato: string, page: number = 0) {
    //   this.consultService.getNotify(nro_contrato).then((messages) => {
    //     let notificationsNotRead: any[] = [];
    //     let notificationsRead: any[] = [];
  
    //     notificationsNotRead = messages as any[];
    //     notificationsNotRead = notificationsNotRead.filter((m) => m.read_at === null);
    //     notificationsNotRead = notificationsNotRead.sort((a, b) => {
    //       const dateA = new Date(a.createdAt);
    //       const dateB = new Date(b.createdAt);
    //       return dateA.getTime() - dateB.getTime();
    //     }).reverse();
  
    //     notificationsRead = messages as any[];
    //     notificationsRead = notificationsRead.filter(message => message.read_at !== null);
    //     notificationsRead.splice(0, notificationsRead.length, ...notificationsRead.filter(message => message.deleteAt === null));
    //     notificationsRead = notificationsRead.sort((c, d) => {
    //       const dateC = new Date(c.createdAt);
    //       const dateD = new Date(d.createdAt);
    //       return dateC.getTime() - dateD.getTime();
    //     }).reverse();
  
    //     if(page > 0) {
    //       this.notificationNotRead = [...this.notificationNotRead, ...notificationsNotRead];
    //       this.notificationsRead = [...this.notificationsRead, ...notificationsRead];
    //     }
    //     else {
    //       this.notificationNotRead = [...notificationsNotRead];
    //       this.notificationsRead = [...notificationsRead];
    //     }
  
    //     //   console.log('Notificaciones sin leer', this.notificationNotRead);
    //     //   console.log('Notificaciones leidas', this.notificationsRead);
  
    //   }).catch((err) => {
    //     console.error(err);
    //   });
    // }
  
    public getMessageNotReadLength(): number {
      return this.lengthMessagesNotRead;
    }
  
    public getMessageIsReadLength(): number {
      // return 0;
     return this.notificationsRead.length;
    }
  
    public markMessageRead(message: any) {     
      this.consultService.setNotify(message, this.fechaHoraFormateada).then((res: any) => {
        if (res.status == 200) {
          message.read_at = this.fechaHoraActual;
          // this.loadMessagesUnreaded(this.user.idUser, 0);
          this.notificationsRead.unshift(message);
          this.getAllLengthMessagesNotRead();
        } 
      }).catch((error) => {
        console.log(error)
      });
    }
  
    public markAllAsRead(message: any, index: number) {
      this.consultService.setNotify(message, this.fechaHoraFormateada).then((res: any) => {
        if (res.status == 200) {
          message.read_at = true;
          this.notificationsRead.unshift(message);
          this.loadMessagesUnreaded(this.user.idUser, 0);
          if (this.notificationNotRead.length - 1 === index) this.notificationNotRead = [];
        }
      }).catch((error) => {
        console.log(error)
      });
    }
    deleteMessages(notification: any) {
      this.consultService.deleteNotify(notification, this.fechaHoraFormateada).then((res: any) => {
        if (res.status == 200) {
          notification.deleteAt = this.fechaHoraActual;
          this.notificationsRead = this.notificationsRead.filter(not=>not.id !== notification.id);
        }
      }).catch((error) => {
        console.log(error)
      });
    }
    async getAllLengthMessagesNotRead(){
      try {
        const response = await this.consultService.getAllNotifyCount(this.user.idUser,false) as number;
        this.lengthMessagesNotRead = response;
      } catch (error) {
        console.log(error);
        this.lengthMessagesNotRead = 0;
      }
   
      
    }
    public reloadMessagesNotReaded(){
      this.loadMessagesUnreaded(this.user.idUser,0);
    }
  }
import { ComponentType } from '@angular/cdk/portal';
import { ViewContainerRef, OnDestroy, ComponentRef, Type } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, AfterViewInit, inject } from '@angular/core';
import { Class } from 'leaflet';
import { ITab } from 'src/app/core/interfaces/ITabs.interface';

@Component({
  selector: 'app-tab-generic',
  standalone: false,
  templateUrl: './tab-generic.component.html',
  styleUrls: ['./tab-generic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGenericComponent implements AfterViewInit, OnDestroy {
  public vcr = inject(ViewContainerRef);
  @Input() componentsData!: ITab[];
  public currentComponent!: Type<Component> & ComponentRef<Component>;
  public tabidActual: number = 0

  constructor(
    // private componentFactoryResolver: ComponentFactoryResolver,
    // private cd: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit() {
    try {
      if (this.componentsData) this.loadComponent(this.componentsData[0].component)
    } catch (error) {
      console.error(error)
    }
  }

  ngOnDestroy(): void {
    try {
      this.vcr.clear();
    } catch (error) {
      console.error(error)
    }
  }

  public changeActive(id: number) {
    try {
      if (id === this.tabidActual) return;
      this.tabidActual = id;
      this.vcr.clear();
      this.loadComponent(this.componentsData[id].component);
    } catch (error) {
      console.error(error)
    }
  }

  public loadComponent(component: ComponentType<Class>) {
    try {
      console.log('this.tabidActual', this.tabidActual)
      // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      this.currentComponent = this.vcr.createComponent(component) as Type<Component> & ComponentRef<Component>;
      this.currentComponent.changeDetectorRef.detectChanges();
    } catch (error) {
      console.error(error)
    }
  }

}

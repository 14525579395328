<div class="sh-pagetitle">
  <div class="input-group" >

    <!-- Cuandro de busqueda que estaba anteriormente -->
    <!-- <input type="search" class="form-control" placeholder="Search">
    <span class="input-group-btn">
      <button class="btn"><i class="fa fa-search"></i></button>
    </span> -->
    <button class="btn btn-primary btn-block mg-b-10"><i class="fa fa-plus mg-r-10"></i>Crear Elemento</button>
  </div><!-- input-group -->
  <div class="sh-pagetitle-left">
    <div class="sh-pagetitle-icon">
      <ng-content select="[icon]"></ng-content>
    </div>
    <div class="sh-pagetitle-title">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[subtitle]"></ng-content>
    </div><!-- sh-pagetitle-left-title -->
  </div><!-- sh-pagetitle-left -->
</div><!-- sh-pagetitle -->
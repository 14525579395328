import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuleCardMenuComponent } from './pages/dashboard/components/module-card-menu/module-card-menu.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpErrorComponent } from './pages/http-error/http-error.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { PerfilComponent } from './pages/dashboard/layout/perfil/perfil.component';
import { CouponsTagComponent } from './pages/coupons-tag/coupons-tag.component';
import { PromotionsTagComponent } from './pages/promotions-tag/promotions-tag.component';
import { HomeGuard } from './guards/home.guard';
import { AuthCanActiavteGuard } from './guards/auth-can-actiavte.guard';
import { MasterPromoScoreComponent } from './pages/master-promo-score/master-promo-score.component';

const routes: Routes = [
  {
    path: 'dashboard',
    // canActivateChild: [AuthGuard], // auth guard will be deactivated when dev mode is on
    component: DashboardComponent,
    children: [  // ConfigurationService is in charge of loading the routes here
      { path: 'modules', component: ModuleCardMenuComponent },
      { path: "promotions", component: PromotionsTagComponent },
      { path: "coupons", component: CouponsTagComponent },
      { path: 'score', component: MasterPromoScoreComponent}
    ],
    canActivate: [HomeGuard]

  },
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent, canActivate: [AuthCanActiavteGuard] },
  { path: 'perfil', component: PerfilComponent},
  { path: 'error', component: HttpErrorComponent },
  { path: '', redirectTo: 'dashboard/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

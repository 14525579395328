import { Component } from '@angular/core';

@Component({
  selector: 'app-config-products-category',
  templateUrl: './config-products-category.component.html',
  styleUrls: ['./config-products-category.component.scss']
})
export class ConfigProductsCategoryComponent {

}

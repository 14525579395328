import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { ITab } from 'src/app/core/interfaces/ITabs.interface';
import { ReportCouponsComponent } from './reportCoupons/reportCoupons.component';
import { ReportAlliesComponent } from './reportAllies/reportAllies.component';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
	components: ITab[] = [
		{component: ReportCouponsComponent,title: "Reporte Cupones"},
		{component: ReportAlliesComponent,title: "Reporte Aliados"},
	]
	
}

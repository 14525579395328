import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoInput } from 'src/app/models/tipo.input';
import { IFormData } from 'src/app/models/tipo.input';


@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit{
  @Input() Fields: {datoShow: string, value: string, icon?: string}[] = [
    {
      datoShow: "Promociones",
      value: "PromoID",
    },
    {
      datoShow: "Cupones",
      value: "CuponID"
    }
  ]
  @Input() disabledInput: boolean | number = 0;
  @Input() value: string = "";
  @Input() TitleShow: string = "";
  @Output() ValueSalida = new EventEmitter<string | string[]>()
  privateValue: string = "";
  
  ngOnInit(): void {
    
  }
  getValue() {
    return this.value || this.privateValue;
  }

  onClick(value: string) {
    this.ValueSalida.emit(this.privateValue = value);
  }

  isIcon(icon: string | undefined) {
    return icon && !this.isUrl(icon);
  }

  isUrl(url: string | undefined) {
    return url && (url.includes("/") || url.includes("\\"));
  }
}

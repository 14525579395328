<div class="slideout-content modal-content pt-0">
    <!--Title--
    <div class="modal-header mb-1">
        <!-- APP TITLE -->
        <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel" style="position: sticky; top: 0;">QR DEL ALIADO</h5>
        <!-- END APP TITLE -->
        <button data-dismiss="modal" aria-label="Close" class="close" (click)="closeModal()">
        <i class="icon ion-close"></i>
        <!-- × -->
      
        </button>
        </div>
    <!--Content-->
    <div class="modal-body flex-grow-1 content-modal">
    <div *ngIf="!isLoadedImage ;else imageContainer">
        <div class="loader-container" *ngIf="!isLoadedImage else imageContainer" >
            <div class="loader"></div>   
        </div>
    </div>    
    <ng-template #imageContainer >
        <!--SI NO EXISTE IMAGEN-->
        <div *ngIf="allyhasQR; else buttonCreate" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <img [src]="image" class="image-qr">
            <!--BOTON DE DESCARGAR-->
            <button (click)="downloadImage()" class="btn btn-primary">Descargar</button>
        </div>
       <ng-template #buttonCreate>
        <button (click)="generateQR()" [disabled]="tableName === 'cb_QR'" class="btn btn-primary">Generar QR</button>
       </ng-template>
       
    </ng-template>

    

</div>    
   
    
</div>
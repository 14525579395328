import { Injectable } from "@angular/core";
import { SesionstorageService } from "./sesionstorage.service";
import { dbFullDataBase, dbFullTable } from "src/assets/lib/dbfullv2";
import { Icb_ComponentesAcciones, Icb_LogSystem } from '../models/tables';
import { environment } from "src/environments/environment";
import axios from "axios";
import { HttpHeaders } from '@angular/common/http';


interface IUserMaster {
    Clave: string,
    Email: string,
    Login: string,
    Status: string,
    admin: boolean,
    idRol: string,
    idUser: string,
    createdAt: string,
    updatedAt: string
}

interface IUserEmpresa {
    Avatar: string,
    Clave: string,
    Email: string,
    Login: string,
    Movil: string,
    Nombre: string,
    Status: number,
    admin: boolean,
    idRol: string,
    idUserEmp: string,
    updatedAt: string,
    createdAt: string,
    idEmpConv: string,
}

@Injectable({
    providedIn: "root"
})
export class AuthService {

    public cb_ComponentesAcciones: dbFullTable<Icb_ComponentesAcciones>;
    
    constructor(
        public _sessionStorage: SesionstorageService
    ) {
        const db = new dbFullDataBase(environment.bd);
        this.cb_ComponentesAcciones = db.CreateChild("cb_ComponentesAcciones");
     }
    
    /**
     * Permite obtener información acerca del usuario logueado actualmente, ó null en coso que no se encuentre logueado
     * @returns Datos asociado al usuario logueado
     */
    public GetUser(): (IUserMaster | IUserEmpresa) | null {
        try {
            const user: any = this._sessionStorage.GetSesionStorage("login").Data;

            if(user) {
                return user;
            }
        }
        catch(err) {
            console.error(err);
        }
        return null;
    }

    /**
     * Validar si el usuario logueado es administrador
     * @returns valor booleano
     */
    public isMaster(): boolean {
        return Boolean(this.GetUserMaster());
    }

    /**
     * validar sie l usuario logueado es usuario empresa
     * @returns valor booleano
     */
    public isUserEmpresa(): boolean {
        return Boolean(this.GetUserEmpresa());
    }

    /**
     * Permite obtener los datos del usuario sólo cuando cumple con la estructura master
     * @returns Datos asociado del master usuario o nul en caso que no exista
     */
    public GetUserMaster(): IUserMaster | null {
        const user = this.GetUser();
        return (user && this.checkUserMaster(user)) ? user : null;
    }

    /**
     * Permite obtener los datos del usuario sólo cuando cumple con la estructura user empresa
     * @returns Datos asociado del usuario de la empresa ó null en caso que no exista
     */
    public GetUserEmpresa(): IUserEmpresa | null {
        const user = this.GetUser();
        return (user && this.checkUserEmpresa(user)) ? user : null;
    }

    /**
     * Permite validar si el dato del usuario cumple con la estructura master
     * @param user datos asociado del usuario
     * @returns valor booleano
     */
    public checkUserMaster(user: IUserMaster | IUserEmpresa): user is IUserMaster {
        return user.admin;
    }

    /**
     * Permite validar si el dato del usuario cumple con la estructura userEmpresa
     * @param user datos asociado del usuario
     * @returns valor booleano
     */
    public checkUserEmpresa(user: IUserMaster | IUserEmpresa): user is IUserEmpresa {
        return !user.admin;
    }

    retrieveActions(roleID: string): Promise<any>{

        return new Promise<void>((resolve, reject) => {
            
            this.cb_ComponentesAcciones.GET_ANY_QUERY<any>({ query: `SELECT * FROM ComponentesAcciones WHERE idRol = '${roleID}'` })
            .then((data: any) => resolve(data))
            .catch((err: any) => { reject(err); console.log(err); })
        })
        
    }
    
    public async getIP(): Promise<any>{
    try {
        let token = this._sessionStorage.GetSesionStorage("token");
        if(!token) return ;
        console.log(token);
        const headers = new HttpHeaders().set('tknlg', `Bearer ${token}`);
        let config ={
            headers: {
                'tknlg': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
           
            }                                               
    const ip = (await axios.get(`${environment.URLApiLocal}/whatismyip`,config)).data.ip;
    return ip;    
    } catch (error) {
        console.error(error);
    }   
      
    
    }

}
import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-master-view-aprobaciones',
  templateUrl: './master-view-aprobaciones.component.html',
  styleUrls: ['./master-view-aprobaciones.component.scss']
})
export class MasterViewAprobacionesComponent {

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  public TableName: string = 'ViewAprobacion';
  
  public tableList = [
    'ViewAprobacion',
    // 'cb_EmpresaSucursal',
    // 'cb_SucursalDir'
  ]
  // public tableListToEdit = [
  //   'cb_Aprobaciones'
  // ]
  
  tableRelations = [
    // {
    //   tablaorigen: 'cb_EmpresaConvenio',
    //   campoorigen: 'idEmpConv',
    //   tabladestino: 'cb_EmpresaSucursal',
    //   campodestino: 'idEmpConv'
    // },
    // {
    //   tablaorigen: 'cb_EmpresaSucursal',
    //   campoorigen:'idSucursal',
    //   tabladestino: 'cb_SucursalDir',
    //   campodestino: 'idSucursal'
    // }
  ]
  
  public attributes = { title: 'Convenios', description: 'Listado control de los convenios' };

  // ngOnInit(): void {
  //   const isAdmin = JSON.parse(sessionStorage.getItem("login") || "{ admin: false }").admin;

  //   if(!isAdmin) this.optionsMenu = [{id:3, name:'Sucursales', icon:'fa fa-building'}]
  // }

  ClickMenu(ev: any) {
    console.log(ev);
  }

  constructor(
    private _SesionstorageService:SesionstorageService,
    private auth: AuthService,
    private helper: HelperService
    ){}

  ngOnInit(): void {    
    this.initEstructure()    
  }

  async initEstructure() {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'MasterViewAprobacionesComponent' && e.Tipo === 'Table')
      console.log(options)
      this.optionsMenu = options ? this.helper.sortOptions(options) : []      
  }
}

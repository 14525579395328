<!-- <app-master-crud [TableName]="'cb_Promo'" [Atributos]="Atributos"></app-master-crud> -->
<app-main-crud 
    [TableName]="TableName" 
    [attributes]="attributes" 
    [tableList]="tableList" 
    [tableRelations]="tableRelations" 
    [optionsDropdown]="optionsMenu"
    [canShowInput]="canShowInputs"
></app-main-crud>


import { Component, Input, OnInit } from '@angular/core';
import { IModule } from 'src/app/core/interfaces/IModule.interface';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { ObservableComponentsService } from 'src/app/services/observable-components.service'
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-module-option',
  templateUrl: './module-option.component.html',
  styleUrls: ['./module-option.component.scss']
})
export class ModuleOptionComponent implements OnInit {
  @Input() module!: IModule;
  /**
   * represents the level of this component in the menu context (top level is 1, submenu is 2, and so on)
   */
  @Input() layer!: number;

  constructor(
    private router: Router,
    private encryptionService: EncryptionService,
    public dashboardModulesService: DashboardModulesService,
    private obsServer: ObservableComponentsService
  ) {
    this.router.events.pipe(
      // Filtrar eventos de tipo NavigationEnd
      filter((event: any) => event instanceof NavigationEnd),
      // Obtener la parte de la URL que está después de "dashboard/"
      map((event: NavigationEnd) => event.urlAfterRedirects.split('dashboard/')[1]),
      // Asegurarse de que la suscripción solo se active cuando la ruta cambie
      distinctUntilChanged()
    ).subscribe((route) => {
      // Emitir la ruta actual
      this.obsServer.pageTitleOptions$.emit(route);
    });

    // Emitir la ruta actual al inicializar el servicio
    const route = window.location.pathname.split('dashboard/')[1];
    this.obsServer.pageTitleOptions$.emit(route);
  }

  ngOnInit(): void {
    if (this.layer === undefined) {
      this.layer = 1;
    }
  }

  public goToModulesCardMenu(): void {
    let modules = this.encryptionService.encryptRouteParameter(this.module.childrenModules);
    this.router.navigate(['/dashboard/modules'], { queryParams: { modules } });
  }

  setpageTitleOptions(route: string){
    this.obsServer.pageTitleOptions$.emit(route)
  }
}

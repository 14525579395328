import { Injectable } from '@angular/core';
import { Feature, MapboxResponse } from '../shared/interfaces/map';
import { HttpClient } from '@angular/common/http';
import { MapServiceService } from './map-service.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  public userLocation!: [number, number];
  public data: Feature[] = [];

  get isUserLocationAvailable(): boolean {
    return !!this.userLocation;
  }

  constructor(  
          private http: HttpClient, 
          private mapServ: MapServiceService 
        ) { }

  public async getUserLocation(): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.userLocation = [coords.longitude, coords.latitude];
        resolve(this.userLocation)
      }, 
      (err) => {
        reject(err);
      })
    })
  }

  getSpecificPlace( query: string ) {
    if( query.length === 0 ) {
      this.data = [];
      return
    }

    return this.http.get<MapboxResponse>(`${environment.urlApiMapbox}/${ query }.json?access_token=${environment.tokenMapbox}`)
    .subscribe(( res: MapboxResponse ) => {
      console.log(res)
      this.data = res.features;
      this.mapServ.createMark(this.data);
    })
  }

  deleteData() {
    this.data = [];
  }
}

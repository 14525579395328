import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import $ from 'jquery';
import 'src/assets/lib/datatables/jquery.dataTables.js'; // using the one from npm presents issues
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { ICoreEstructure } from 'src/app/models/Estructure';
import { HelperAPIPetService } from 'src/app/services/helper-apipet.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

const steps = [
  'cb_EmpresaSucursal',
  'cb_SucursalDir'
];

@Component({
  selector: 'app-list-table2',
  templateUrl: './list-table2.component.html',
  styleUrls: ['./list-table2.component.scss']
})

export class ListTable2Component implements AfterViewInit, OnChanges {
  @Input() TableName: any;
  @Input() options:any;
  @Input() style:any;
  @Input() Atributos: any;
  @Input() Estructura: any;
  @Input() isNecesaryUpdate: any;
  @Input() renderFrom: string = '';
  @Input() dataForSucursales: any;
  @Output() ValueSalida = new EventEmitter<string>();

  public tableId: string = '';
  public tableClasses: string[] = [];
  public ArrayDataAll: any[]=[]
  public thead: any[]=[]
  public FieldAndHead: any
  public showMenu: boolean = false;
  public loading: boolean = true;
  public openCreateElement: boolean = false;
  public openCreateELementInit: boolean = true;
  public WithCalcule: any[]=[]   // Es el que se debe usar con los datos de la Base de datos
  public WithCalculeSize: number = 0// Tempotal
  public element:any;
  public ElementSelect:any;
  public ElementName: string = "";
  public Object = Object;
  public validFields: any = [];
  public param: any;
  public renderForm: boolean = true;
  public showSucursales: boolean = false;
  public closeModalSucursales: boolean = true;
  public showEdit: boolean = false;
  public loadingMessage: string = 'Cargando...';
  public renderOptSucursales: boolean = false;
  public showDelete: boolean = false;

  public DataFormOpenArray: ICoreEstructure[] = [];

  public imagesKeys = [
    'Avatar',
    'ImgURL',
    'img'
  ]

  relations = []

    constructor(
        private _helperApi: HelperAPIPetService,
        private router: Router,
        private helperServ: HelperService,
        private helper: HelperService,
        private logs: LogsService,
        private _SesionstorageService: SesionstorageService
        ) {
    }


    ngOnChanges(changes: SimpleChanges) {
      if( this.isNecesaryUpdate instanceof Object && this.isNecesaryUpdate[0].Status ) {
        this.ArrayDataAll.push( this.isNecesaryUpdate[0] );
      }

      // if( this.dataForSucursales ) {
      //   console.log(this.dataForSucursales)
      //   this.loadDataField( this.TableName, this.dataForSucursales.Campo, this.dataForSucursales.id )
      // }
    }

    ngOnInit(): void {
      if(this.router.url.includes('empresa/consulta') && this.renderFrom !== 'sucursales') {
        this.renderOptSucursales = true
        this.options = this.options.filter((option: any) => !option.icon.includes('edit') );
      }
    }


    toCloseMenu = () => {
      document.body.removeEventListener("click", this.toCloseMenu)
      this.showMenu = false;
    }

    toOpenMenu() {
      setTimeout(() => {
        document.body.addEventListener("click", this.toCloseMenu);
      }, 200)
      this.showMenu = true;
      // console.log("56")
    }

    Cargar2() {

      return new Promise(async(resolve,reject)=>{
        const FullDBObj = new dbFullService();
        const DatosSend = { title: "Control " + this.TableName,  data: [] };
        this.FieldAndHead = DatosSend;
        let ArgValues: any[] = [];
        // Cargo los campos que toca mostrar
        // console.log(this.TableName)
        FullDBObj.GetAllDataCampo("cb_Estructura", "TableName", this.TableName).then(async (Estructura: any) => {

              const ArgTemp: any[] = []
              const ArgHead: any[] = []
              Estructura.forEach(async (element:any , index:number) => {
                if(element.ShowList) {
                  ArgTemp.push(element.Campo)
                  ArgHead.push(element.HeadListName)
                }
              });
              this.FieldAndHead.head = ArgHead   // Entrega la cabecera de la lista
              this.FieldAndHead.campo = ArgTemp

              this.WithCalculeSize =  100 / this.FieldAndHead.length
              // console.log(ArgTemp)

                // Voy ahora a buscar los datos
                  this.ArrayDataAll =  await FullDBObj.GetAllDataCampo(this.TableName, "Status", "1")
                  this.ArrayDataAll.forEach((elementTable: any, index: number) => {

                    // Contiene todos los elementos de la tabla
                    let ValurReg:any[]=[]

                    ArgTemp.forEach(AsigElement => {
                      ValurReg.push(elementTable[AsigElement])   // Contiene solo los elementos seleccionados de la tabla
                    });

                    ArgValues.push(ValurReg)
                    if (this.ArrayDataAll.length - 1 == index ) {
                      this.thead = ArgTemp
                      resolve(ArgValues)
                    }
                    // console.log("82")
                  });
          })
      })

      }

    Cargar3 () {
      return new Promise(async(resolve,reject)=>{
        // const FullDBObj = new dbFullService();
        const DatosSend = { title: "Control " + this.TableName,  data: [] };
        this.FieldAndHead = DatosSend;
        let ArgValues: any[] = [];

        this._helperApi.GetAllDataCampo("cb_Estructura", "TableName", this.TableName).then(async (Estructura: any) => {
          const ArgHead: any[] = []
          Estructura.forEach(async (element:any , index:number) => {
            if(element.ShowList) {
              this.validFields.push(element.Campo)
              ArgHead.push(element.HeadListName)
            }
          });

            this.FieldAndHead.head = ArgHead;
            this.WithCalculeSize =  100 / this.FieldAndHead.length;

            this.ArrayDataAll =  await this._helperApi.GetAllDataCampo(this.TableName, "Status", "1");
            this.ArrayDataAll.forEach((elementTable: any, index: number) => {

              /*
                @Param: el segundo parametro en formatDate es para determinar que dormato usar
                  1- [day, month, year]
                  2- [year, month, day]
              */

              // if(elementTable.hasOwnProperty('Fecha')) elementTable.Fecha = this.helperServ.formatDate(elementTable.Fecha, 1);
              // if(elementTable.hasOwnProperty('FechaIni')) elementTable.FechaIni = this.helperServ.formatDate(elementTable.FechaIni, 1);
              // if(elementTable.hasOwnProperty('createdAt')) elementTable.createdAt = this.helperServ.formatDate(elementTable.createdAt, 1);

              // Contiene todos los elementos de la tabla
              let ValurReg:any[]=[]
              ArgValues.push(ValurReg)
              if (this.ArrayDataAll.length - 1 == index ) resolve(ArgValues);
            });
          })
        })
    }

    loadDataField( table: string, field: string, value: string ) {
      // const FullDBObj = new dbFullService();
      const DatosSend = { title: "Control " + this.TableName,  data: [] };
      this.FieldAndHead = DatosSend;

      this._helperApi.GetAllDataCampo("cb_Estructura", "TableName", table).then(async (Estructura: any) => {
        const ArgHead: any[] = []
        Estructura.forEach(async (element:any) => {
          if(element.ShowList) {
            this.validFields.push(element.Campo)
            console.log(this.validFields)
            ArgHead.push(element.HeadListName)
          }
        });

        this.FieldAndHead.head = ArgHead;
        this.WithCalculeSize =  100 / this.FieldAndHead.length

        this.ArrayDataAll = await this._helperApi.GetAllDataCampo(table, field, value);
        this.ArrayDataAll && this.ArrayDataAll.length > 0 ? this.loading = false : this.loadingMessage = 'Sin registros.';
      })
    }

    async createFormSteps(){
      // const FullDBObj = new dbFullService();

      for (const table of steps) {
        const estructure = await this._helperApi.GetAllDataCampo("cb_Estructura", "TableName", table)

        const core = {
          TitleHead: this.Atributos?.title,
          Table: table,
          Estructura: estructure,
          Inputs: [],
          Value:[]//"idUserEmp_3jKgVz"
        }

        this.DataFormOpenArray.push(core)
      }
    }


      BuscarElementoClick(ElementClick:any) {
        let IndexEncontrado = -1
        let DataSalida: any = undefined;
        let CamposIguales:number = 0
        this.ElementName = ElementClick[0]
        for (let index = 0; index < this.ArrayDataAll.length; index++) {
            const DataItem = this.ArrayDataAll[index];
            CamposIguales = 0;
            console.log(this.FieldAndHead)
            this.FieldAndHead.campo.forEach((head: any, index: number) => {
              if (DataItem[head] === ElementClick[index]) {
                CamposIguales = CamposIguales+1
              }
            });
            if (CamposIguales == this.FieldAndHead.campo.length) {   // Encontre todos los campos iguales
              IndexEncontrado = index
              DataSalida =  DataItem
              break
            }
        }

        // console.log(IndexEncontrado)
        if (IndexEncontrado != -1) { return IndexEncontrado }
        else return undefined
      }

      // Metodo se llama al darle click a  por el menú
      toDelete(element:any, deleteType: string) {
        console.log('TIPO DE BORRADO')
        console.log(deleteType)
        this.showDelete = true;
        if( element && element !== undefined ) this.ElementSelect = element;
        return;
      }

      // Metodo se llama al confirmar eliminar en el Modal
      AceptarDelete(){
        if (this.ElementSelect  != undefined) {
          let Pkey = ""
          const pk = this.Estructura.Estructura.filter((element: any) => element.PK === 1);
          const { Campo } = pk[0];
          Pkey = `${Campo}_${this.ElementSelect[Campo]}`;

          if (Pkey != "") {
            const FullDBObj = new dbFullService();
            // Pkey es el nombre de la llave de ka tabla que es dinamica
            // iTemDelete[Pkey] es el valor que le voy hacer update

            const Data = {
              [Campo]: this.ElementSelect[Campo].replace(/&quot;/ig,'\"'),
              Status: 0
            }

            FullDBObj.UpdateData(this.TableName,Campo,Data).then((resp: any)=>{
              if( resp.rowsUpdate === 1 ) {
                this.ArrayDataAll = this.ArrayDataAll.filter( emp => emp[Campo] !== this.ElementSelect[Campo] );
                // this.createLog(this.ElementSelect.idEmpConv)
              }
            })
            .catch((error:any)=>{
              console.log(error);
            })
          }
        }
      }

    toOpenCreateElement( element:any, name: string ) {

      const pk = this.Estructura.Estructura.filter((element: any) => element.PK === 1);
      const { TableName, Campo, HeadListName } = pk[0];
      this.renderForm = true;

      this.param = {
        TitleHead: HeadListName,
        Table: TableName,
        Inputs: [],
        Value: [ `${Campo}_${element[Campo]}` ]
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      this.element = element;

      if( name.toUpperCase() === 'SUCURSALES' ) {
        this.showSucursales = true;
      } else {
        this.showEdit = true;
        this.openCreateELementInit = false;

        if( element.idSucursal ) {
          this.DataFormOpenArray = this.DataFormOpenArray.map( param => {
            const pk = param.Estructura.filter( key => key.PK === 1 );
            const { Campo } = pk[0];
            param.Value = [`${Campo}_${this.element[Campo]}`];

            return param;
          });
        }
      }
    }

    toCloseCreateElement( element?: any ) {
      const { Campo } = this.getPK();

      if( element !== undefined && element.data instanceof Object ) {
        if(element.evento !== 'cerrar') this.reloadCurrentRoute();
      }

      this.showEdit
      ? this.showEdit = false
      : this.showSucursales = false;

      this.openCreateELementInit = false;
        setTimeout(() => {
          this.openCreateELementInit = true;
        }, 1000);

    }

    ngAfterViewInit(): void {
      this.getData();
      this.createFormSteps();
    }

    getData() {
      if( this.dataForSucursales ) {
        this.loadDataField( this.TableName, this.dataForSucursales.Campo, this.dataForSucursales.id );
      } else {
        this.ArrayDataAll = [];

        this.Cargar3().then((Data: any) => {
          this.FieldAndHead.data = Data;
            this.loading = false;

          setTimeout(() => {
            //@ts-ignore

            $('#datatable2').DataTable({
              language: {
                searchPlaceholder: 'Buscar...',
                sSearch: '',
                lengthMenu: 'MOSTRAR _MENU_ REGISTROS',
              }
            });
            // console.log("167")

          },500)
        })
        .catch((error) => {
          console.error(error);
          // console.log("No se logró cargar los datos")
        })
      }
    }

    getPK() {
      const pk = this.Estructura.Estructura.filter((element: any) => element.PK === 1);
      return pk[0];
    }

    async createLog(id: string){
      const auth: any =this._SesionstorageService.GetSesionStorage('login')
      //const auth: any = sessionStorage.getItem('login')
      const { idUser, idUserEmp } = JSON.parse(auth)

      if(idUser){
        const action = await this.helper.retrieveAction(this.router.url)

      this.logs.createLog(idUser, action, `Se ha eliminado el registro ${id}`).then(resp => {

      }).catch(error => console.log(error))

      }else if(idUserEmp){
        const action = await this.helper.retrieveAction(this.router.url)

      this.logs.createLog(idUserEmp, action, `Se ha eliminado el registro ${id}`).then(resp => {

      }).catch(error => console.log(error))

      }
    }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}

export interface TipoInput {
    hidden: boolean;
    idEstructura: string,
    PK: number | boolean,
    TableName: string,
    Campo: string,
    TipoDato: string,
    HeadListName: string,
    PlaceHolder: string,
    ShowList: number, // boolean
    ShowInsert: number, // boolean
    MaxLen: number | null, //
    Requerido: number, // boolean
    ExpresionReg: string |null,
    OrdenList: number,
    OrdenInsert: number,
    RelacionOtrasTablas: null | string
    ValorDiccionario: string | null;
    Reading: number | boolean;
    DownloadImg?: number | boolean;
  }

export class IFormData {
  public vars: {[nameVar: string]: any};
  public defaultValues: {[nameVar: string]: any};
  public error:  {[campo: string]: string};
  // public toSubmitInEnd: {
  //   table: string,
  //   vars: IFormData["vars"]
  // }[];

  constructor() {
    this.vars = { };
    this.error = { };
    this.defaultValues = { };
    // this.toSubmitInEnd = [];
  }

  // /**
  //  * Permite agregar una tarea pasa subir el registro al final del registro del padre de new-user-sidebar (Esto sólo debe usarlo los componente que usen internamente otro new-user-sidebar)
  //  * @param table Nombre de la tabla
  //  * @param vars Variables a guardar
  //  */
  // RegisterToSubmiInEnd(table: string, vars: IFormData["vars"]) {
  //   this.toSubmitInEnd.push({
  //     table,
  //     vars
  //   })
  // }
}

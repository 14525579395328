import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputTableService {
  private datas: {
    table: string,
    campoOrigen: string,
    vars: { [key: string]: any }
  }[] = [];

  constructor() { }

  RegisterInput(table: string, campoOrigen: string, vars: { [key: string]: any}) {
    console.log("REGISTERED:", table, campoOrigen, vars);
    this.datas.push({
      table,
      campoOrigen,
      vars
    })
  }

  GetInput() {
    return this.datas.pop(); // Obtener el primero
  }
}

<input
    type="date"
    class="form-control"
    [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
    [name]="data.Campo"
    [required]="!!data.Requerido"
    (change)="HanderOnChange($event)"
    (keypressed)="HandlerOnKey()"
    (blur)="HanderOnChange($event)"
    [maxLength]="data.MaxLen"
    [hidden]="!data.ShowInsert"
    [value]="formatValue"
    [disabled]="data.Reading === 1 ? true : false"
/>

<!-- <textarea
    type="text"
    class="form-control"
    [name]="param.Campos[i]"
    [(ngModel)]="param.Value[i]"
    [class.error]="newUserForm.submitted && param.Campos[i].invalid"
    #[param.Campos[i]]="ngModel"
    [required]="param.Required[i]"
    rows="3"
>
</textarea> -->
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEstructura2 } from 'src/app/models/Estructure';


/**
  @Input() element:any;
  @Input() param!: IEstructura;
  @Input() excelColumnsNames: string[] = [];
  @Input() onSubmit: ((vars: {[key: string]: unknown}) => void) | undefined; // Este método permite evitar enviar cualquier dato a base de datos, en cambio de disparar esta funcion (sólo si se recibe)
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  @Input() noClose: boolean = false;
 */

@Component({
  selector: 'app-new-user-sidebar',
  templateUrl: './new-user-sidebar.component.html',
  styleUrls: ['./new-user-sidebar.component.scss']
})
export class NewUserSidebarComponent {
  @Input() element:any;
  @Input() param!: IEstructura2;
  @Input() excelColumnsNames: string[] = [];
  @Input() optionToExecute: string = '';
  @Input() dataSelected: any;
  @Input() idEmpConvCreate: string = '';
  @Input() relations: any[] = [];
  @Input() noClose: boolean = false;
  @Input() onSubmit: ((vars: {[key: string]: unknown}) => void) | undefined; // Este método permite evitar enviar cualquier dato a base de datos, en cambio de disparar esta funcion (sólo si se recibe)
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  /** Permite verificar si se puede mostrar algun campo */
  @Input() canShowInput: {name: string, check(vars: {[key: string]: any}): boolean}[] | null = null; // name = Nombre del campo || check = método que se llamará para verificar si se puede mostrar el campo asociado
  @Input() beforeSubmit?: (vars: {[key: string]: unknown}, tableName: string) => Promise<{[key: string]: unknown}>;

  public local_params: IEstructura2[] = [];
  
  ngOnInit() {
    this.PrepareSimpleSidebar();
  }

  ngOnChanges() {
    this.PrepareSimpleSidebar();
  }

  PrepareSimpleSidebar() {
    if(this.param !== this.local_params[0]) {
      this.local_params = [this.param];
    }
  }
}

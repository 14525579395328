import { Injectable } from '@angular/core';
import { LngLatLike, Map, Marker, Popup } from 'mapbox-gl';
import { Feature } from '../shared/interfaces/map';

@Injectable({
  providedIn: 'root'
})
export class MapServiceService {
  private map!: Map;
  private markers: Marker[] = []

  get isMapReady() {
    return !!this.map
  }

  setMap(map: Map) {
    this.map = map
  }

  flyTo( coords: LngLatLike ) {
    if( !this.isMapReady ) throw new Error( 'Map is not ready');

    this.map?.flyTo({
      zoom: 14,
      center: coords
    });
  }

  createMark( places: Feature[] ) {
    if( !this.map ) throw new Error('No se ha inicializado ningun mapa');
    this.markers.forEach( marker => marker.remove() );

    const newMarkers = [];

    for ( const place of places ) {
      const [ lng, lat ] = place.center;
      const popup = new Popup()
        .setHTML(`
          <h6>${place.text}</h6>
          <span>${place.place_name}</span>
        `);

        const marker = new Marker({ color: '#00184E' })
        .setLngLat([ lng, lat ])
        .setPopup( popup )
        .addTo( this.map );

      newMarkers.push( marker );
    }

    this.markers = newMarkers;
  }
}

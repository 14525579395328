import {Component, Input, OnInit, SecurityContext } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-extensible-avatar',
  templateUrl: './extensible-avatar.component.html',
  styleUrls: ['./extensible-avatar.component.scss']
})
export class ExtensibleAvatarComponent implements OnInit{
  @Input() data: TipoInput = {} as any;
  @Input() value: string | undefined = "";
  @Input() formData: IFormData = new IFormData();

  valuesAvatars: string[] = [];

  ngOnInit() {
    try {
      console.log("HERE WORK MONRIGN WASAKAKA")
      const valueDefault = JSON.parse(this.value || "");
      if(valueDefault instanceof Array) {
        // Preparar el valor por defecto
        this.valuesAvatars = valueDefault;
      }
    }
    catch(err) {
      console.log("No se logró hacer el JSON.parce()... ignorando...");
    }

    setInterval(() => console.log(this.valuesAvatars), 1000)
  }

  HandlerOnChange(url: string, index: number) {
    this.valuesAvatars[index] = url; // Esto modificará el valor
    console.log(">>>>>>>>>>>>>>>>>>>", this.valuesAvatars);
  }

  HandlerOnChangeAdd(url: string) {
    this.valuesAvatars.push(url); // esto agregará una nueva url
    console.log(">>>>>>>>>>>>>>>>>>>", this.valuesAvatars);
  }
  
  // avatarContainer:Array<{url:string, index:number}> = [];

  // constructor() {

  // }
  // ngOnInit(): void {
  //   console.log('data de extensible avatar', this.data)
  //   console.log(this.value)
  //   console.log(this.formData)
  // }

  // HandlerOnChange(value: string, avatarIndex: number) {

  //   const index = this.avatarContainer.findIndex(avatar => avatar.index === avatarIndex)

  //   if(index === -1) {
  //     this.avatarContainer.push({url: value, index:avatarIndex})
  //   } else {
  //     this.avatarContainer[index].url = value
  //   }

  //   // //! ordenamiento
  //   this.avatarContainer = this.avatarContainer.sort((a, b) => a.index - b.index)

  //   //!Extracción de urls
  //   const avatars = this.avatarContainer.map(avatar => avatar.url)

  //   delete this.formData.error[this.data.Campo];
  //   this.formData.vars[this.data.Campo] = JSON.stringify(avatars);
  //   }
}

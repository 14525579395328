import { Component, OnInit } from '@angular/core';
import { IEmpProduct } from 'src/app/models/tables';
import { dbFullService } from 'src/app/services/dbFull.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-master-promo-score',
  templateUrl: './master-promo-score.component.html',
  styleUrls: ['./master-promo-score.component.scss']
})
export class MasterPromoScoreComponent implements OnInit {

  public allPromotions: IEmpProduct[] = []

  constructor(
    private _helperApi: dbFullService,
  ) {
    // console.log('MASTER SCORE WORKS 1', 'hola prueba')
  }

  ngOnInit(): void {
    try {
      console.log('MASTER SCORE WORKS 2')
      this.getAllPromotions()
    } catch (error) {
      console.error(error);
    }
  }

  public getAllPromotions = async () => {
    try {
      this.allPromotions =  (await this._helperApi.getAnyQuery(environment.bd, ` SELECT * FROM cb_EmpresaProductos WHERE Status = 1 `))?.slice(0,60);
      console.log('PROMOTIONS', this.allPromotions);
    } catch (error) {
      console.error(error)
    }
  }

}

import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { getMockModuleContent } from 'src/mock-data/module-content';
import { IContentBlock } from 'src/shared-interfaces/IContentBlock.interface';
import { IModule } from '../core/interfaces/IModule.interface';
import { ApiClientService } from '../core/services/api-client.service';

export const moduleViewResolver: ResolveFn<IContentBlock[] | void> = async (route: ActivatedRouteSnapshot) => {
  // get services
  const apiClientService = inject(ApiClientService);
  
  // get route data in order to get the neccesary info to fetch content
  const module = route.data['module'] as IModule;
  const contentRoute = module.contentRoute!;
  const NameComponent = module.name!;


  if (isDevMode()) {
    let res = await getMockModuleContent(contentRoute,NameComponent);
    if(contentRoute){
      return res.data;
    }else{
      console.log("Entre por name")
      return;
    }
    
  } else {
    let res = await apiClientService.http.get(contentRoute);
    return res.data as IContentBlock[];
  }
};

<input
    type="email"
    class="form-control"
    [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
    [name]="data.Campo"
    [required]="data.Requerido"
    (keypressed)="HandlerOnKey()"
    (change)="HanderOnChange($event)"
    (blur)="HanderOnChange($event)"
    [maxLength]="data.MaxLen"
    [hidden]="!data.ShowInsert"
    [value]="value"
    [disabled]="data.Reading === 1 ? true : false"
/>


<!-- <input
    type="email"
    class="form-control"
    [placeholder]="param.Campos[i]"
    [name]="param.Campos[i]"
    [(ngModel)]="param.Value[i]"
    [class.error]="newUserForm.submitted && param.Campos[i].invalid"
    (keypress)="validateEmail($event)"
    #[param.Campos[i]]="ngModel"
    [required]="param.Required[i]"
/> -->
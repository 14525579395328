<ul class="nav nav-pills nav-fill tab_container">
  <li class="tab_item" *ngFor="let tab of componentsData;let i = index" 
  [ngClass]="tabidActual === i ? 'nav-link active' : 'nav-link' "
  (click)="changeActive(i)"
  > 
  {{tab.title}}
  </li>
</ul>
<div *ngIf="currentComponent">
  <app-tab-element></app-tab-element>
</div>

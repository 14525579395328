import {Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { environment } from 'src/environments/environment';


interface AlliesSelect {
  Nombre: string;
  idEmpConv: string;
}

@Component({
  selector: 'app-master-qr',
  templateUrl: './masterQR.component.html',
  styleUrls: ['./masterQR.component.scss']
})



export class MasterQRComponent  implements OnInit{



  constructor(private dbFull: dbFullService, private fb: FormBuilder,private _SesionstorageService: SesionstorageService,private auth: AuthService, private helper: HelperService){}

  public allies:AlliesSelect[]= [];

  optionsMenu: {id:number, name:string, icon:string}[] = [];

  public imageQR: string = "";
  ngOnInit(): void {
    this.initEstructure();
  }
public formG = this.fb.group({
  'aliado': ['']
})
async initEstructure() {
  const { Data } = this._SesionstorageService.GetSesionStorage('login')
  const actions = await this.auth.retrieveActions(Data.idRol)
  const options = actions.filter( (e: any) => e.Componente === 'MasterQRComponent' && e.Tipo === 'Table')
  this.optionsMenu = options ? this.helper.sortOptions(options) : []
  //OPCION PARA MOSTRAR QR;
}


  public isEmptyValue(): boolean{
    return this.formG.get('aliado')?.value?.length! < 1;
  }
  TableName = 'cb_QR'

  attributes = { title: 'QR', description: 'Listado control de los QR' }

  tableList = [
    'cb_QR'
  ]

}

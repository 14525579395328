import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import axios from 'axios';

import { Icon, Map, tileLayer, Marker } from 'leaflet';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { PlacesService } from 'src/app/services/places.service';
import Swal from 'sweetalert2';

interface INominatimMapSearch {
  place_id: number,
  licence: string,
  osm_type: string,
  osm_id: number,
  lat: string,
  lon: string,
  class: string,
  type: string,
  place_rank: number,
  importance: number,
  addresstype: string,
  name: string,
  display_name: string,
  boundingbox: string[]
}

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent {
  @ViewChild("map_container", {static: true}) map_container!: ElementRef<HTMLDListElement>;
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();
  @Output() emitter: EventEmitter<any> = new EventEmitter();


  /**
   * geolocalization de acuerdo al API del navegador
   */
  public geo_valencia:[number, number] = [ 10.2337867, -67.999511 ]; // Ubicación fibex valencia
  /**
   * Ubicacion actual
   */
  public geo: [number, number] = [...this.geo_valencia];
  /**
   * instancia de leaflet
   * */
  public map: Map | null = null;
  /**
   * marcador del mapa
   */
  public marker!: Marker;
  /**
   * ubicacion seleccionada
   */
  public ubicacion:[number, number] = [0, 0];
  /**
   * notifica si se encuentra opteniendo la ubicacion en este momento
   */
  private processingGetLocation: boolean = false;
  /**
   * Botton deshabilidado
   */
  public disabledButton: boolean = false;
  /**
   * Valor anterior
   */
  private lastValue: string | null = null;
  /**
   * Constructor del componente
   * @param placeSvc Servicio de geoubicación
   */
  constructor (private placeSvc: PlacesService) { }


  /**
   * Inicializar el componente
   */
  ngOnInit() {
    this.AnalizeChanges();
  }

  /**
   * Cuando el componente notifica cambios
   */
  ngOnChanges() {
    this.AnalizeChanges();
  }

  /**
   * Despues de haber renderizado el componente
   */
  ngAfterViewInit() {
    this.InicializeMap();
  }


  /**
   * Posicionar mi ubicación actual
   */
  public MyLocation() {
    if(this.map) {
      if(!this.processingGetLocation) {
        Swal.fire({
          icon: "info",
          title: "Verificando",
          text: "Esperando para conocer su ubicación",
          footer: "Continuaremos una vez que usted haya aceptado los permisos",
          confirmButtonText: "Aceptar"
        });
        Swal.showLoading()

        this.placeSvc.getUserLocation()
          .then((position) => {
            Swal.close();

            this.processingGetLocation = false;
            this.geo = [position.coords.latitude, position.coords.longitude];
            this.marker.setLatLng(this.geo);
            this.disabledButton = true;
            this.UpdateValue(this.geo[0], this.geo[1]);

            if(this.map) this.map.flyTo(this.geo, 18);
          })
          .catch((error) => {
            this.processingGetLocation = false;
            console.log(error);

            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Hubo un error al intentar obtener su ubicación",
              footer: "Asegurese que su navegador tenga activo el permiso para conocer su ubicación",
              confirmButtonText: "Aceptar"
            })
          })
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al intentar obtener su ubicación",
          footer: "Asegurese que su navegador tenga activo el permiso para conocer su ubicación",
          confirmButtonText: "Aceptar"
        })
      }
    }
  }

  public InicializeMap() {
    if(!this.map) {
      const myIcon = new Icon({
        iconUrl: './assets/icons/marker-icon2.png',
        iconSize: [25, 41]
      });

      // Crear mapa
      this.map = new Map(this.map_container.nativeElement);
      this.map.setView(this.geo, 18);

      // Crear marcador del mapa
      this.marker = new Marker(this.geo, { icon: myIcon, draggable: true });
      this.marker.addTo(this.map);
      this.marker.bindPopup(`Ubicación seleccionada`);

      // Evento para obtener luego de haber sido movida la ubicación
      this.marker.on('moveend', () => {
        const location = this.marker.getLatLng();
        this.geo = [location.lat, location.lng];
        this.disabledButton = false;

        // update vars
        this.UpdateValue(location.lat, location.lng);
      });
      this.map.flyTo(this.geo);

      // nose que es pero está aqui :]... me parece como que es su configuración inicial
      tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 100,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
    }
    else console.log("El mapa ya se encontraba creado");
  }

  public AnalizeChanges() {
    console.log("MY VALUES:::", this.value);
    if(this.value && this.lastValue !== this.value) {
      try {
        const value = JSON.parse(this.value);

        if(
          value instanceof Object &&
          typeof value.latitude === "number" &&
          typeof value.longitude === "number" &&
          value.latitude !== this.geo[0] &&
          value.longitude !== this.geo[1]
        ) {
          this.geo = [value.latitude, value.longitude];
          if(this.map) {
            this.map.setView(this.geo, 15);
            this.marker.setLatLng(this.geo);
          }
        }
      }
      catch(err) {
        console.log(err);
      }
    }
  }

  /**
   * Actualizar variables al externo
   * @param lat latitud
   * @param lng longitud
   */
  private UpdateValue(lat: number, lng: number) {
    // Update vars in form
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = JSON.stringify({
      latitude: lat,
      longitude: lng
    });
  }

  public onChangeSearch(ev: any) {
    const input = ev.target.value;
    this.SearchLocation(input);
  }

  private timerToSearch: NodeJS.Timeout | null = null;
  public activeInputToSearch: boolean = false;
  public searched: boolean = false;
  public searching: boolean = false;
  public locationSearched: INominatimMapSearch[] = [];

  public SearchLocation(input: string) {
    this.searching = true;

    // check if exists other timer to search
    if(this.timerToSearch) {
      clearTimeout(this.timerToSearch);
    }

    // set timer to search
    this.timerToSearch = setTimeout(() => {
      const url = new URL("https://nominatim.openstreetmap.org/search?format=json&countrycodes=VE");

      // append query
      url.searchParams.set("q", input);

      // request HTTP
      axios.get<INominatimMapSearch[]>(url.href)
        .then((response) => {
          this.locationSearched = response.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.searching = false;
          this.searched = true;
        });

    }, 5000);
  }

  public SelectLocation(location: INominatimMapSearch) {
    this.activeInputToSearch = false;
    const lat = Number(location.lat);
    const lon = Number(location.lon);

    this.processingGetLocation = false;
    this.geo = [lat, lon];
    this.marker.setLatLng(this.geo);
    this.disabledButton = true;
    this.UpdateValue(this.geo[0], this.geo[1]);

    if(this.map) this.map.flyTo(this.geo, location.place_rank);
  }

  private timerInputFocusing: NodeJS.Timeout | null = null;

  public FocusInputSearch() {
    if(this.timerInputFocusing) clearTimeout(this.timerInputFocusing);

    this.timerInputFocusing = setTimeout(() => {
      this.timerInputFocusing = null;
      this.activeInputToSearch = true;
    }, 100);
  }

  public BlurInputSearch() {
    if(this.timerInputFocusing) clearTimeout(this.timerInputFocusing);

    this.timerInputFocusing = setTimeout(() => {
      this.timerInputFocusing = null;
      this.activeInputToSearch = false;
    }, 100);
  }
}

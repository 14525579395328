import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-taps',
  templateUrl: './taps.component.html',
  styleUrls: ['./taps.component.scss']
})
export class TapsComponent {
  @Input() Fields: {datoShow: string, value: string}[] = [
    {
      datoShow: "Promociones",
      value: "PromoID"
    },
    {
      datoShow: "Cupones",
      value: "CuponID"
    }
  ]
  @Input() disabledInput: boolean | number = 0; 
  @Input() value: string = "";
  @Input() TitleShow: string = "";
  @Output() ValueSalida = new EventEmitter<string | string[]>()
  privateValue: string = "";

  getValue() {
    return this.value || this.privateValue;
  }

  onClick(value: string) {
    this.ValueSalida.emit(this.privateValue = value);
  }
}

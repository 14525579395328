
<ng-container *ngIf="!profile.stat">
  <div>

        <app-modal *ngIf="showSucursales" >
            <div class="modal-background" >
          <!-- <div style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: fixed; width: 100%; height: 80lh; z-index: 80;"> -->
              <!-- <div ngClass="list-table2-showsize" style="z-index: 10000000;"> -->
                <app-modal-sucursales (SalidaClickMenu)="closeModal($event)" [params]="estructures" [selectedElement]="selectedElement" [tableRelations]="tableRelations" [attributes]="attributes" (deleteSucursales)="getDataToSideBar( $event )" (dataFromSucursales)="getDataToSideBar($event)"></app-modal-sucursales>
                  <!-- </div> -->
            <!-- </div> -->
            </div>
        </app-modal>

      <!-- <div *ngIf="showSucursales" style="background-color: rgba(0,0,0,0.4); left: 0; top: 100px; position: fixed; width: 100% !important; height: 100lh; z-index: 80;">
          <div class="list-table2 list-table2-showsize">
              <app-modal-sucursales (SalidaClickMenu)="closeModal($event)" [params]="estructures" [selectedElement]="selectedElement" [tableRelations]="tableRelations" [attributes]="attributes" (deleteSucursales)="getDataToSideBar( $event )" (dataFromSucursales)="getDataToSideBar($event)"></app-modal-sucursales>
          </div>
      </div> -->

      <app-modal *ngIf="modal">
        <div class="modal-background">
          <!-- <div style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: fixed; width: 100%; height: 80lh; z-index: 80;"> -->
              <!-- <div ngClass="list-table2-showsize" style="z-index: 10000000;"> -->
                  <app-new-user-sidebar-stepper [idAction]="idAction" *ngIf="estructures" [beforeSubmit]="beforeSubmit" [params]="estructures" [TableEdit]="TableEdit" [relations]="tableRelations" [dataSelected]="selectedElement" (SalidaClickMenu)="closeModal($event);" [dataDefault]="inputsValueDefault" ></app-new-user-sidebar-stepper>
                  <!-- </div> -->
            <!-- </div> -->
        </div>
      </app-modal>
      <app-modal *ngIf="showQR">
        <div class="modal-background">
        <app-modal-show-qr [dataQR]="CurrentidEmpConv" [tableName]="tableNameQR" (SalidaClickMenu)="closeModal($event);"></app-modal-show-qr>
        </div>
      </app-modal>

      <app-main-table *ngIf="TableName" [TableName]="TableName" [canShowInput]="canShowInput" [filterBy]="filterBy" [attributes]="attributes" [idColName]="idColName" [optionsDropdown]="optionsDropdown" [fnDataName]="fnDataName" [dragable]="dragable" (dataRender)="SalidaClickMenu.emit($event); getDataToSideBar($event);"></app-main-table>
  </div>

  <div *ngIf="showDelete" class="modal-background-black" (click)="showDelete = false">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              ¿Está seguro de querer eliminar?
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="confirmDelete()">Aceptar</button>
          </div>
          </div>
      </div>
  </div>
</ng-container>

<ng-container *ngIf="profile.stat">
  <div>
    <app-modal *ngIf="modal">
        <div class="modal-background">
    <!-- <div *ngIf="modal" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: fixed; width: 100%; height: 100lh; z-index: 80;">
        <div ngClass="list-table2 list-table2-showsize" style="position: absolute; top: 10px; left: 10px;"> -->
            <app-new-user-sidebar-stepper [dataDefault]="inputsValueDefault" [idAction]="idAction" *ngIf="estructures" [beforeSubmit]="beforeSubmit" [canShowInput]="canShowInput" [TableEdit]="TableEdit" [params]="estructures" [relations]="tableRelations" [dataSelected]="selectedElement" (SalidaClickMenu)="trigger($event)"></app-new-user-sidebar-stepper>
        </div>
    <!-- </div> -->
    </app-modal>
</div>
</ng-container>


<ng-container>

<app-modal *ngIf="detalle">
    <div class="modal-background">
        <app-new-user-sidebar-stepper [dataSelected]="selectedElement" [isSidebarOpen]="true" [TableEdit]="TableEdit" (SalidaClickMenu)="trigger($event)" [dataDefault]="inputsValueDefault"></app-new-user-sidebar-stepper>
    </div>
</app-modal>
</ng-container>

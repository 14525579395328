<input
  type="number"
  class="form-control"
  [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
  [name]="data.Campo"
  [required]="!!data.Requerido"
  (change)="HanderOnChange($event)"
  (keyup)="HandlerOnKey($event)"
  (blur)="HanderOnChange($event)"
  [maxLength]="data.MaxLen"
  [hidden]="!data.ShowInsert"
  [value]="value"
  [disabled]="data.Reading === 1 ? true : false"
  min="0"
  oninput="validity.valid||(value='');"
  />

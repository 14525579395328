import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/core/interfaces/IModule.interface';

@Component({
  selector: 'app-nav-btns-left',
  templateUrl: './nav-btns-left.component.html',
  styleUrls: ['./nav-btns-left.component.scss']
})
export class NavBtnsLeftComponent {

  @Input() navBtnsLeft!: IModule | any;

}

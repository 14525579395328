import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

interface IReport {
	status: boolean;
	data: IDataReport[]
}

interface IDataReport{
	Empresa:string;
	Ciudad:string;
	idAbonado:string;
	nombrPromocion:string;
	Canjeado:string;
	FechaGenerado:string;
	HoraGenerado:string;
	FechaCanjeado: string,
	HoraCanjeado: string,
	nombreAbonado:string;
}

@Component({
  selector: 'app-report-coupons',
  templateUrl: './reportCoupons.component.html',
  styleUrls: ['./reportCoupons.component.scss']
})
export class ReportCouponsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("datatable3", { static: true}) datatable3!: ElementRef;
	showMenu: boolean = false;
	showOptions: boolean = false;
	loading: boolean = true;
    currentTable: any[] = []
	private inicializedTable: boolean = false;
	public showFilter: boolean = false;
	public isfilteredTable: boolean = false;
	public errorFechaMax: boolean = false;
	constructor(private dbFull: dbFullService, private fb: FormBuilder, private helper: HelperService){

	}
	public data: any[] = [];
	public selectEmterprise: any[] = [] 
	selectProm: any[] = [];
	public dataAgrouped: any[]=[];
    
	public rowHead: any[] = ["EMPRESA","CIUDAD","AB", "NOMBRE AB", "PROMOCIÓN","CANJEADO","DÍA GENERADO","HORA GENERADO","FECHA CANJEADO","HORA CANJEADO"];

	public formG: FormGroup = this.fb.group({
		minD: [,],
		maxD: [,],
		selectEnt: [,],
		selectProm: [,]
	});



	public obsEnt!: Subscription;
	public table!: any;
	
	public timerFilter!: NodeJS.Timeout; 
	
	ngOnInit(): void {

	}
	ngAfterViewInit(): void {
		this.initData();
    	const control =this.formG.get("selectEnt");
		if(control){
		this.obsEnt = control.valueChanges.subscribe(resp=>{
			this.selectProm = Object.keys(this.groupBy(this.dataAgrouped[resp],'nombrPromocion'));
			
      	})
    }
	
	}
    
	initTable() {
		($(this.datatable3.nativeElement) as any).DataTable({
			language: {
				searchPlaceholder: 'Buscar...',
				sSearch: '',
				
				info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
				lengthMenu: 'Mostrar _MENU_',
				paginate:{
					previous:'Anterior',
					next:'Siguiente'
				},
			},
			order: [[5, 'asc']],
			dom: 'Bfrtip',
    buttons: [
        'excelHtml5'
    ]
        });
		
	}
    toOpenMenu() {
        setTimeout(() => {
                document.body.addEventListener("click", this.toCloseMenu);
        }, 200)
        this.showMenu = true;
    }

    toCloseMenu = () => {
        document.body.removeEventListener("click", this.toCloseMenu)
        if(!this.showOptions){
            this.showMenu = false;
        }
        this.showOptions = false;
    }

    getStatistics(): Promise<{status: boolean}>{
        return new Promise(async(resolve,reject)=>{

            const data: IReport = <IReport> await this.dbFull.getStatistics();
			if(data && data.data.length>0){
                this.data=data.data;
                resolve({status: true});
            } 
            else{reject({status: false})}
        })
        
    }

    export() {  
        if (this.currentTable) {
        	const headers:string[] = []
        	const refHeaders:string[] = []
        	const form = this.formG;
			const minVlue  = (form.get("minD")?.value === null) ? "1969-12-31" : form.get("minD")?.value;
			const maxValue =  (form.get("maxD")?.value === null) ? "2999-12-31" : form.get("maxD")?.value;
			
		    const formMin  = new Date(minVlue);
			const formMax = new Date(maxValue);
			
			formMin.setMinutes(formMin.getMinutes() +formMin.getTimezoneOffset());
			
			// console.log(formMin);
			formMax.setUTCHours(23 );
			formMax.setUTCMinutes(59+ formMax.getTimezoneOffset());
			formMax.setUTCSeconds(59);
			const formEmpresa =  form.get("selectEnt")?.value;
			const formPromo =  form.get("selectProm")?.value;
			let sheets: any[][] = []
		

            //To set row headers
        	for (const header of this.rowHead) {
        		headers.push(header)
        	}
            for (const refHeader in this.data[0]) {
                refHeaders.push(refHeader)
            }
			// console.log(refHeaders.length);
			// if(refHeaders.length>=9){
			
				// const lastL =refHeaders.pop();
			
				// refHeaders.splice(3,0,lastL!);
			// }
			
        	sheets.push(headers)
            
        	for (let i = 0; i < this.currentTable.length; i++) {
        		let column: any[] = []
                let isvalidDateFilter = false;
				let isvalidEntFilter = false;
				
				// console.log(refHeaders);
        		for (const header of refHeaders) {   
                    // console.log("CANJEADO "+typeof(this.currentTable[i]['FechaCanjeado']))
					const empresas = this.currentTable[i]['Empresa'];
					const promociones = this.currentTable[i]['nombrPromocion']; 
					const date = new Date(this.currentTable[i]['FechaGenerado']);

					if (
						((formEmpresa === "" || formEmpresa === null) && (formPromo === "" || formPromo === null)) ||
						(formEmpresa ===  empresas  && (formPromo === null || formPromo==""))||
						(formEmpresa === empresas &&  formPromo === promociones ) 
					   ){ 
                         //console.log("la empresa "+form+"Es "+formEmpresa); 
						 isvalidEntFilter =true;
                    }
					
					if(
						(formMin === null && formMax === null) || 
						(formMin === null && date <= formMax) ||
						(formMin <= date && formMax === null) ||
						(formMin <= date && date <= formMax)
					)
					{
						// console.log("La fecha "+date+" Es valida")
						isvalidDateFilter= true;	
					}
        			if(header === 'FechaGenerado'){  
						column.push(this.helper.getDate(String( this.currentTable[i][header])).toUpperCase())
						
        			}
					else if (header === 'HoraGenerado') {  
						// console.log(this.currentTable[i][header])
						// console.log(this.helper.getTime(String(this.currentTable[i][header])).toUpperCase());
        				column.push(this.helper.getTime(String(this.currentTable[i][header])).toUpperCase())
						
        			}
					else if(header === 'FechaCanjeado' && this.currentTable[i]['FechaCanjeado'] ){
					
						column.push(String(this.helper.formatDatehyphen(this.currentTable[i][header]) ).toUpperCase())					
					}	
					else if(header === 'HoraCanjeado' && this.currentTable[i]['HoraCanjeado']){
						column.push(String(this.helper.formateTimeAMPM(this.currentTable[i][header]) ).toUpperCase())	
					}
					
					else{	
						if(this.currentTable[i][header] === null) column.push("")
						else column.push(String( this.currentTable[i][header]).toUpperCase())
        			}
        		}
				if(isvalidEntFilter  && isvalidDateFilter){
					sheets.push(column)
				}
        		
        	}
			// console.log(this.currentTable);
        	const workbook = XLSX.utils.book_new();
			const tabl_sort = this.sortMatriz(sheets);
			console.log(tabl_sort);
        	const worksheet = XLSX.utils.aoa_to_sheet(tabl_sort);
			const max_width = tabl_sort[0].reduce((w, r) => Math.max(w, r[0].length), 20);
			worksheet["!cols"] = [ { wch: max_width },
				{wch:10},  // Ancho de la columna 1
				{wch:30},  // Ancho de la columna 2
				{wch:45}   // Ancho de la columna 3
			];


        	XLSX.utils.book_append_sheet(workbook, worksheet);
        	var wopts = { bookType:"xlsx", bookSST:false, type:"array" } as const;
        	var wbout = XLSX.write(workbook, wopts);

        	const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"});
        	var reader = new FileReader();

        	reader.onload = (ev) => {
        		const base64URL = reader.result as string;

        		const linkElement = document.createElement("a");
        		linkElement.style.display = "none";
        		linkElement.href = base64URL;
        		linkElement.download = "reporte_cupones.xlsx";
        		linkElement.click();
        	}
        	reader.readAsDataURL(blob);
        }
    }

	async initData(){
        const response: {status: boolean} = await this.getStatistics()
        this.loading = false;
        response.status 
        ? this.currentTable = this.data
        : this.currentTable = []        
        //this.data va a ser tu data original no la modifiques
			setTimeout(() => {
			if(!this. inicializedTable) {
				this. inicializedTable = true;				
		this.table = ($(this.datatable3.nativeElement) as any).DataTable({
					language: {
						searchPlaceholder: 'Buscar...',
						sSearch: '',
						info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
						lengthMenu: 'Mostrar _MENU_',
						paginate:{
							previous:'Anterior',
							next:'Siguiente'
						}
					},
					columnDefs: [
						{
							targets: 6,
							render:  (data:any, type:any, row:any) =>{
								const date = new Date(data);
								date.setMinutes(date.getMinutes()-date.getTimezoneOffset());
								
								return date.toISOString().substring(0,10);
							}
						},
						{
							targets: 7,
								render: (data: any,type:any,row:any)=>{
								
									
									const date = new Date(data);
								
									date.setMinutes(date.getMinutes()-date.getTimezoneOffset());
									
									let hours = date.getUTCHours();
									let minutes = date.getUTCMinutes();
									let seconds = date.getUTCSeconds();
									let horary = "pm";
									if(hours>=0 && hours<12){
										horary="am";
									}else{
										hours=hours-12;
									}
									return `${hours}:${minutes}:${seconds} ${horary}`;
							}
						},
						// {	
						// 	targets: 6,
						// 	render:  (data:any, type:any, row:any) =>{
						// 		if(data){
						// 		const date = new Date(data);
						// 		date.setMinutes(date.getMinutes()-date.getTimezoneOffset());
								
						// 		return date.toISOString().substring(0,10);
						// 		}
						// 		return 
						// 		}
							
						// },
						{
							targets:9, 
								render: (data: string,type:any,row:any)=>{
								if(data.substring(0,17) !== `No se ha canjeado`){
									const [hora, minuto, segundo] =  data.substring(0,8).split(':');
								
									// Convertir la hora a un número
									const horaNum = parseInt(hora, 10);
								
									// Determinar si es AM o PM
									const periodo = horaNum >= 12 ? 'pm' : 'am';
								
									// Ajustar la hora si es PM
									const hora12 = horaNum > 12 ? horaNum - 12 : horaNum;
									const horaFormateada = hora12.toString().padStart(2, '0');
								
									// Crear la cadena formateada en formato "hh:mm:ss AM/PM"
									const horaAMPM = `${horaFormateada}:${minuto}:${segundo} ${periodo}`;
								
									return horaAMPM;
								}
									return "No canjeado";
							}
						}
						]  
								 	   

					});
        
        }				 
				 
			},500)	
	}
	cambiarData(){
		
	
		const formG = this.formG;
		let min_forms = (formG.get("minD")?.value === null) ? "1969-12-31" : formG.get("minD")?.value;
		let max_forms =  (formG.get("maxD")?.value === null) ? "2999-12-31" : formG.get("maxD")?.value;
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
		
			let min: any =new Date(min_forms);
			min.setMinutes(min.getMinutes() + min.getTimezoneOffset());
			let max =new Date(max_forms);
			
			max.setUTCHours(23 );
			max.setUTCMinutes(59+ max.getTimezoneOffset());
			max.setUTCSeconds(59);
		
			
			console.log(data[6]);
			
			let   date = new Date(data[6]);
			date.setMinutes(date.getMinutes()+date.getTimezoneOffset());
		
			if (
				(min === null && max === null) || 
				(min === null && date <= max) ||
				(min <= date && max === null) ||
				(min <= date && date <= max)
			) {
				return true;
			}
			return false;
		});
		// console.log("drawing");
		
		//CONSECUENTEMENTE
		 this.changeEnterprise();
		
	
	}
	changeEnterprise(){
		// console.log("data");
		// this.table.draw();
		const formG = this.formG;
	
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
			
			let nombreEmp = formG.get("selectEnt")?.value;
			let nombrePromo = formG.get("selectProm")?.value;
			

			const dataEmpr = data[0];
			// console.log(dataEmpr);
			const dataPromo = data[4];
			 console.log(dataEmpr, dataPromo);
		 		if (
				 ((nombreEmp === "" || nombreEmp === null) && (nombrePromo === "" || nombrePromo === null)) ||

				 (nombreEmp === dataEmpr && (nombrePromo === null || nombrePromo==""))||
				 (nombreEmp === dataEmpr &&  nombrePromo === dataPromo ) 
				){return true;
				}
				else return false;
		});
		// console.log("drawing");
		
		this.table.draw();
		
	}
	showModalFilter(){
		
		this.showFilter=true;
		this.obtenerGrupo();
	}
	hideModalFilter(){
		this.showFilter=false; 
		
		if(this.isfilteredTable){
			
		if(this.timerFilter){
			clearTimeout(this.timerFilter);
		}	
		this.timerFilter = setTimeout(()=>{
			 
				this.cambiarData();
				
			},1000)	
		}
		
	}
	obtenerGrupo(){
       
		this.dataAgrouped = this.groupBy(this.currentTable,'Empresa');
       
		const groupList: any[] = Object.keys(this.dataAgrouped);
		this.selectEmterprise = groupList;
	
		}
	groupBy(array: any[], key: any) {
			return array.reduce((result, currentValue) => {
			if(typeof(currentValue)=="string"){
				(result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
			}
			else (result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
		  return result;
		}, {});
	}	
	confirmFilter(){
	const form = this.formG;

	if(form.valid && form.touched){
		if(new Date(form.get("maxD")?.value)>=new Date(form.get("minD")?.value)){
			this.errorFechaMax=false;
			this.isfilteredTable=true;

			this.hideModalFilter();
		}
		else if(form.get("minD")?.value && form.get("maxD")?.value==null){
			this.isfilteredTable=true;
			this.hideModalFilter();

		} 
		else{
           this.errorFechaMax=true;
		}
	}else if(form.untouched){ 
		
		this.hideModalFilter();}
     
	}
	stopFilter(){
		
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.isfilteredTable = false;
		this.formG.reset();
		this.cambiarData();
	
	}
	sortMatriz(matriz: any[][])
	{
	const matrizOrdenada = matriz.slice(1); 
	matrizOrdenada.sort((a:any, b:any) => {
			if (a[0] === b[0] ) {
				if (a[3] === b[3] ) {
					return new Date(a[5]).getTime() - new Date(b[5]).getTime();
				}
				return a[3].localeCompare(b[3]);
			}
			return a[0].localeCompare(b[0]);
		});
	
		return [matriz[0], ...matrizOrdenada]
	}
	ngOnDestroy(): void {
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.obsEnt.unsubscribe();
	}
 }

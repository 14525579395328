
<div class="extensible-avatar-container">

  <!-- <app-avatar-generic 
    *ngFor="let avatar of valuesAvatars; let i = index" 
    (ValueSalida)="HandlerOnChange($event, i)" 
    [name]="data.Campo" 
    [UrlIMg]="avatar"
  ></app-avatar-generic> -->

  <app-avatar-generic>
    <!-- *ngIf="valuesAvatars.length < 5" -->
    (ValueSalida)="this.HandlerOnChangeAdd($event)" 
    [name]="data.Campo" 
  </app-avatar-generic>

</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SeguridadDatos } from './bscript.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HelperAPIPetService {

  private tokendbfulll: string = environment.tokendbFull;
  private authDBFULL: string = environment.authdbFUll;
  private URLDBFULL: string = environment.urlDBFULL;

  constructor( private security: SeguridadDatos, private http: HttpClient) { }

  MasterGETDBFULL(headersData: any, url: string) {
    return new Promise(async (resolve: any, reject: any) => {
      this.security.EncrypDataHash(headersData).then((headers: any) => {
        headers.TokenAuthPlataform = this.tokendbfulll
        headers.Authorization = this.authDBFULL
        // console.log("ENC:", headers);
        this.http.get(url, { headers }).subscribe((res: any) => {
          // console.log('res', res)
          let jsonres;
          resolve(res);
        })
      }).catch((error: any) => {
        reject(error)
      })

    })
  }

  async GetAllDataCampo(table: string, campo: string, valor: any): Promise<any[]> {
    return new Promise(async (resolve: any, reject: any) => {
      // const helperApi = new HelperAPIPetService();
      const headersData = {
        db: environment.bd,
        table: table,
        type: 'find-any-info',
        campo: campo,
        valor: valor,
      };

      // console.log("HEADER:", headersData);
      // console.log("URL:", this.URLDBFULL);
      // axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
      this.MasterGETDBFULL(headersData, this.URLDBFULL).then((data) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  GetAllData(table: string) {
    return new Promise(async (resolve: any, reject: any) => {
      const headersData = {
        db: environment.bd,
        table: table,
        type: 'find-all-info',
      };
      // axios.get(`${environment.UrlFull}api/v1/find-all-info/${environment.bd}/${table}`)
      this.MasterGETDBFULL(headersData, this.URLDBFULL).then((data) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  GetAllT() {
    return new Promise(async (resolve: any, reject: any) => {
      const headersData = {
        db: environment.bd,
        type: 'show-all-info',
        // table: table,
      };
      // axios.get(`${environment.UrlFull}api/v1/show-all-info/thomas_clubfibex`)
      this.MasterGETDBFULL(headersData, this.URLDBFULL).then((data) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  GetAllEstrucura(table: string, campo: string, valor: any) {
    return new Promise(async (resolve: any, reject: any) => {
      const headersData = {
        db: environment.bd,
        table: table,
        type: 'find-any-info',
        campo: campo,
        valor: valor,
      };
      // axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
      this.MasterGETDBFULL(headersData, this.URLDBFULL).then((data) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-source-media',
  templateUrl: './modal-source-media.component.html',
  styleUrls: ['./modal-source-media.component.scss']
})
export class ModalSourceMediaComponent {

}

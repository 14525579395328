
<div class='{{"extensible-select-input"}}'>
    <input 
        readonly 
        type="hidden" 
        class="form-control" 
        [value]="getValue()"
        [disabled]="disabledInput"
    />
    <div class="main-menu">
        <div *ngIf="!Field.length" class="menu-item-extensible-text">
            Sin contenido
        </div>
        <label *ngFor="let opt of Field" class="row-of-item" style="margin-bottom: 1px;">
          <button type="button" class="{{'menu-item-extensible' + (isSelected(opt.value) && (!disabledInput || disabledInput===0) ? ' selected' : '')}}" (click)="SelectItem(opt.value)">
            <input  [disabled]="disabledInput" type="checkbox" [checked]="isSelected(opt.value)" style="width: auto;"> <div style="width: 100%;">{{opt.datoShow}}</div>
          </button>
        </label>
    </div>
</div>


<app-main-crud 
    [TableName]="TableName" 
    [attributes]="attributes" 
    [optionsDropdown]="optionsMenu"
    [canShowInput]="[]"
    [tableList]="tableList"
></app-main-crud>
<!--
<div class="flex-col">
   
    <form class="row justify-content-center" [formGroup]="formG" (submit)="onSelectAlly()">
        <select class=" col-12 form-select form-select-sm m-2" formControlName="aliado">
            <option value="">Selecciona un aliado..</option>
            <option *ngFor="let ally of allies" 
            value="{{ally.idEmpConv}}">{{ally.Nombre}}</option>
        </select>
   
    
 
    
        <button type="submit" class="col-3 btn btn-primary " [disabled]="isEmptyValue()">Generar QR</button> 
    </form>
   
    <div class="row justify-content-center">
        <img class="col-6" [src]="imageQR" alt="">
    </div>
  
</div>
-->
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent {
  public showSucursales: boolean = false;
  public showDelete: boolean = false;
  public renderForm: boolean = false;
  public showEdit: boolean = false;
  public renderOptSucursales: boolean = false;

  @Input() ElementSelect: any;
  @Input() options: any;
  @Output() emitParam: EventEmitter<any> = new EventEmitter();

  constructor() {  }

  toDelete(deleteType: string) {
    console.log(deleteType)
    this.showDelete = true;
    return;
  }

  toOpenCreateElement( name: string ) {

    this.renderForm = true;

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    if( name.toUpperCase() === 'SUCURSALES' ) {
      this.showSucursales = true;
    } else {
      this.showEdit = true;
    }
  }

  setOptionSelected( option: {id: number, name: string, icon: string, tableList: string[],idAccion?: string} ) {
    // option.tableList=this.ElementSelect.Tablaselect; //Tabla que se selecciono al momento de dispara el actions
    const dataEmit = {
      type: option.name.toUpperCase(),
      element: this.ElementSelect,
      option: option
    }
    console.log("ID DE LA ACCION CLICKEADA: "+option.idAccion);
    console.log("HERE CLICK");
    console.log("VALOR EMMITIDIO", dataEmit.type);
    this.emitParam.emit( dataEmit );
    console.log(this.options)
  }



}

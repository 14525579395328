
  <div class="notification dropdown-link dropdown-link-notification"  [ngClass]="{ 'bell': this._notfication.lengthMessagesNotRead > 0 }" style="cursor: pointer;">
    
    <i  id="icon-contendt-notification" (click)="toggleDropdown()"   class="icon ion-ios-bell-outline tx-24" style=" font-size: 30px; color: white;"></i>
    <!-- <div  (click)="toggleDropdown()"  [ngClass]=" this._notfication.getMessageNotReadLength() > 0 ? 'notification bell' : 'notification'" > -->
      <!-- <div  (click)="toggleDropdown()"  [ngClass]="{ 'bell': this._notfication.getMessageNotReadLength() > 0 }" ></div>      <img src="../../../../../assets/icons/notification.png" alt="" class="bell"> -->
      <div  class="custom_badge" *ngIf="this._notfication.getMessageNotReadLength() > 0">
  
  {{this._notfication.getMessageNotReadLength() }}
      </div>
    </div>
  <!-- </div> -->
  <div *ngIf="dropdownOpen" class="main_container" id="dropdown-modal-nodtification">
    <div class="dropdown-menu-header header">
      <h2 id="title">
       <strong>Notificaciones</strong> </h2>
      <button  class="btn_transparent btn btn-primary"  (click)="markAllAsRead()">Marcar todas como leídas</button>
    </div>
    <div class="media-list">
      <button (click)="handleActiveMessage(notification)" *ngFor="let notification of this._notfication.notificationNotRead; index as i"  class="list_item item_closed" [id]="'item_id_'+notification?.id">
        <div  class="dot" [ngClass]="{'hide_transparent': notification?.read_at}"></div>
        <!-- <div  class="col_info"> -->
          <div class="col_info">
            <span class="created_at">
              {{notification?.createdAt | date }}
            </span>
            <p class="title overflow_ellipsis">
              <strong>
                {{notification?.title}}
              </strong>
            </p>
            <p class="description overflow_ellipsis">
              {{notification?.message}}
            </p>
            <div *ngIf="notification.image" class="noti-img-container description overflow_ellipsis">
              <img [src]="notification.image" alt="IMG">
            </div>
            <button (click)="redirectNotification($event,notification.routerLink)" *ngIf="notification.button" style="width: 70%; margin: auto;" class="btn_gradient_app see_all_btn  btn btn-primary">
              {{ notification.buttonText }}
            </button>
          </div>
        <!-- </div> -->
      </button>
      <div *ngIf="this._notfication.getMessageNotReadLength() === 0">
          <div style="text-align: center; padding: 20px;">
              No hay notificaciones
          </div>
      </div>
      <button (click)="showMoreNotRead()" class="btn_gradient_app see_all_btn btn btn-primary" *ngIf="_notfication.canReadMoreMessageUnreaded" [disabled]="!_notfication.canReadMoreMessageUnreaded"  >
        <span *ngIf="this._notfication.loadingUnreaded">
            
        </span>
        <span  *ngIf="!this._notfication.loadingUnreaded">
          Ver más
        
        </span>
      </button>
    </div>
    <div class="dropdown-menu-footer footer">
      <button (click)="viewModalNotification()" class="btn btn-primary btn_gradient_app see_all_btn">
        Ver historial
      </button>
    </div>
    <app-modal-notifications *ngIf="modalMode" (SalidaClickMenu)="closeModalNotiifications()" ></app-modal-notifications>
  </div>
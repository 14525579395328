<div class="sh-pagetitle" *ngIf="bar">
  <div *ngIf="Ruta" class="sh-pagetitle-left">
    <div class="sh-pagetitle-icon">
      <!-- <ng-content select="[icon]"></ng-content> -->
      <img width="68%" [src]="iconDefault" alt="">
    </div>
    <div class="sh-pagetitle-title">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[subtitle]"></ng-content>
    </div>
  </div>
  <div *ngIf="rightSideItem === 'search'" class="input-group">
    <input #searchInput (keyup.enter)="emitClickMenuEvent('search', searchInput)" type="search" class="form-control" placeholder="Search">
    <span class="input-group-btn">
      <button class="btn" (click)="emitClickMenuEvent('search', searchInput)"><i class="fa fa-search"></i></button>
    </span>
  </div>
  <div *ngIf="rightSideItem === 'button' && aprobado" class="input-group" style="justify-content: flex-end;">
    <div class="button-container" *ngIf="titleButtonDefault">
      <button class="btn btn-primary mg-b-10" (click)="emitClickMenuEvent('button'); testEvent()"><i [class]="iconDefault"></i>{{ titleButtonDefault }}</button>
    </div>
  </div>
  <div *ngIf="rightSideItem === 'not set'" class="input-group">
    <div class="button-container">
      <!-- Without rightSideItem -->
    </div>
  </div>
</div>

<app-card [cardData]="cards"></app-card>

<div class="grid-charts">
  <!-- <app-charts [dataSimpleToChart]="empresaDataChart" [idChart]="configEmpresaChart"></app-charts> -->
  <!-- <app-charts [dataSimpleToChart]="productosDataChart" [idChart]="idProductosChart"></app-charts>
    <app-charts [dataDoubleChart]="cuponesDataChart" [idChart]="idCuponesChart"></app-charts> -->
  <div *ngIf="!charts.length" class="alerta">
    No posee datos para mostrar
  </div>
  
  <app-chart *ngFor="let chartItem of charts"
    [type]="'area'"
    [title]="chartItem.title"
    [data]="chartItem.data"
    [formatterText]="chartItem.formatter || formatterToInt"
  ></app-chart>
    <!-- (select)="" -->
</div>
<div class="espaciador"></div>

<!-- <app-map></app-map> -->
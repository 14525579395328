<div class="sh-headpanel header-bg" style="display: flex; align-items: center;">
  <app-nav-btns-left [navBtnsLeft]="btnsLeft"></app-nav-btns-left>
  
 
  <div style="display: flex; align-items: center; gap: 10px;">

    <app-notifications *ngIf="areRoutePromotions()"></app-notifications>
    <app-nav-btns-right [profileDdwnBtns]="profileDdwnBtns"></app-nav-btns-right>
  </div>
  
</div><!-- sh-headpanel -->

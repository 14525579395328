import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { Router } from '@angular/router';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { Icb_ComponentesAcciones } from 'src/app/models/tables';
import { dbFullDataBase, dbFullTable } from 'src/assets/lib/dbfullv2';
import { actions } from 'src/app/core/interfaces/actions.interface';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../../../environments/environment';

interface PageTitleDetails {
  Style?: string;
  Icon?: string;
  Title?: string;
}

interface MenuClickOutput {
  Type: string;
  Value?: any;
}

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit, OnChanges {

  @Input() rightSideItem!: string;
  @Input() Ruta: boolean = true;
  @Input() bar: boolean = true;
  @Input() Details: string = '';
  @Output() clickMenu = new EventEmitter<MenuClickOutput>();
  public cb_ComponentesAcciones: dbFullTable<Icb_ComponentesAcciones>;
  public mostrar: boolean = true;
  public idAction: string =""; 
  public aprobado: boolean = true;

  // styleDefault = '';
  iconDefault = '../../../../../assets/icons/consulta2.png';
  titleButtonDefault = 'Crear elemento';

  actions: actions[] = []

  constructor(private _SesionstorageService: SesionstorageService, private obsService: ObservableComponentsService, private router: Router, private auth: AuthService) {
    const db = new dbFullDataBase(environment.bd);
    this.cb_ComponentesAcciones = db.CreateChild("cb_ComponentesAcciones");
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.iconDefault = this.Details || this.iconDefault;
    // console.log(this.iconDefault)
  }

  ngOnInit(): void {
    this.initData();
    if (sessionStorage.getItem('actions')) { //Este método es para cuando el usuario recarga el navegador, porque se perdía el botón
      this.obtener();
    }
  }
    async initData() {
      const { Data } =  this._SesionstorageService.GetSesionStorage('login')

      const actions = await this.auth.retrieveActions(Data.idRol)
      if(actions){
        // console.table(actions)
        this.actions = actions.filter((e: any) => e.Tipo === 'Boton')
        this.initSubscriptions();
      }
    }

  testEvent(): void {
    this.obsService.paramData$.emit(true);
  }

  emitClickMenuEvent(type: string, value?: any): void {
    const output: MenuClickOutput = { Type: type, Value: value?.value }
    //console.log("typee: "+this.idAction);
    this.obsService.dataIdAction$.emit(this.idAction);
    this.clickMenu.emit(output);
  }

  initSubscriptions(): void {
    const availableRoutes = this.actions.map(e => {return e.Url});
    this.obsService.pageTitleOptions$.subscribe(route => {
      this.titleButtonDefault = this.routeDictionary(route);
      if (availableRoutes.includes(route)) {
        this.rightSideItem = 'button';
        const name = JSON.stringify(this.actions)
        const value = JSON.stringify(route)
        this._SesionstorageService.SetSesionStorage('actions', name);
        this._SesionstorageService.SetSesionStorage('route', value)
        this.obtener();
      } else {
        this.rightSideItem = 'not set';
      }
    });

  }


 
 

  obtener(){
    this.idRol()
    const aver =this._SesionstorageService.GetSesionStorage('login')
    // console.log(aver)
    const name = this._SesionstorageService.GetSesionStorage('actions');
    const value = this._SesionstorageService.GetSesionStorage('route');
    const index = name.findIndex((item: actions) => item.Url === value);
    const boton = name[index].Accion;
    this.rightSideItem = 'button';
    this.titleButtonDefault = boton;
  }

  idRol(){
    const aver =this._SesionstorageService.GetSesionStorage('login');
    const aprobado1 = aver.Data.idRol;
    if(aprobado1 === 'uiVGBPD5Jcw2cJV8'){
      this.aprobado = false;
    }else{
      this.aprobado = true;
    }

    // console.log(this.aprobado);
  }

  routeDictionary(route: string): string {
    let str: string;
    const currentRoute = this.actions.find(e => e.Url === route)
    if(!currentRoute) return '';
  const accion =  this.actions.filter(e => e.Url === route);
  this.idAction= accion[0].idAccion;
  return currentRoute.Accion;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IControl } from '../json-form/interfaces/IControl.interface';
import { IFormData } from 'src/app/models/tipo.input';
import { isArray } from 'lodash';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @Input() Field: {datoShow: string, value: string}[] = [];
  @Input() TitleShow: string = "";
  @Input() formData: IFormData = new IFormData();
  @Input() value: string | string[] = "";
  @Input() disabledInput : boolean | number =0;
  @Output() ValueSalida = new EventEmitter<string | string[]>();

  public selectedId: string = '';

  selecteds: string[] = [];

  constructor() {
    // console.log("SELECT MULTIPLE =======================================================================================")
  }
  
  ngOnInit() {
    this.calculateValue();
  }
  ngOnChanges() {
    this.calculateValue();
  }

  calculateValue() {
    try {

      const selectedsDefault = this.value instanceof Array ? this.value : JSON.parse(this.value);
      
      if( isArray(selectedsDefault) ) this.selecteds = selectedsDefault;
    }
    catch(err) {
      console.log("No se logró hacer el JSON.parse()");
    }
  }

  getValue() {

      return (this.Field.filter((f: any) => this.selecteds.some(v => v === f.value))).map((f: any) => f.datoShow).join(", ");
  }

  SelectItem(value: string) {
    if(this.disabledInput || this.disabledInput===1) return ;
      const index = this.selecteds.findIndex(v => v === value);

      if(index !== -1) {
        this.selecteds.splice(index, 1);
      }
      else {
        this.selecteds.push(value);
      }

      console.log(this.selecteds)
      this.ValueSalida.emit([...this.selecteds]);
  }

  isSelected(value: string) {
    return this.selecteds.includes(value);
  }
}
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="sh-footer" style="bottom: 0; position:relative;">
      <div>Copyright &copy; 2017. All Rights Reserved.</div>
      <div class="mg-t-10 mg-md-t-0">Designed by: <a href="http://thomas-talk.me">Thomas</a></div>
    </div>
  `
})
export class FooterComponent {}

import { CommonModule } from '@angular/common';
import { Component, type OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { SharedModule } from '../../shared/shared.module';
import { ITab } from '../../core/interfaces/ITabs.interface';
import { dbFullService } from 'src/app/services/dbFull.service';

@Component({
  selector: 'banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent {

  public componentsData: ITab[] = [
    {
      component: PrimaryBanners,
      title: 'Banners Primarios Landing Page'
    },
    {
      component: SecondaryBanners,
      title: 'Banners Secundarios Landing Page',
    },
    // {
    //   component: AlliedBrandBanners,
    //   title: 'Marcas aliadas'
    // },
    {
      component: BannersAppPrimary,
      title: 'Primarios App',
    },
    {
      component: BannersAppSecondary,
      title: 'Secundarios App',
    },
    {
      component: PopUpClubFibex,
      title: 'Pop up',
    }
    // {
    //   component: BannersOficinaMovil,
    //   title: 'Banners Oficina Móvil',
    // },
  ]

}

@Component({
  selector: 'banner-comp-structure',
  standalone: true,
  template:'',
  styleUrls: ['./banners.component.scss']
})
export class BannerCompStructure implements OnInit {
  public optionsDropdown: { id: number, name: string, icon: string, tableList?: string[], idAccion?: string }[] = [];
  public optionsMenu: { id: number, name: string, icon: string }[] = [];
  public tableList:string[] = ['cb_LandingBanner']
  public tableRelations:unknown[] = []
  public TableName:string = 'cb_LandingBanner'
  public attributes:{title:string,description:string} = { title: 'Banners', description: 'Listado control de los banners' }
  public filters: {name:string,value:string}[] = [
    { name: "type", value: "primary" },
    { name: "type", value: "secondary" }
  ]
  public fnDataName: string = '';

  constructor(
    private helper: HelperService,
    private auth: AuthService,
    private _SesionstorageService: SesionstorageService,
    @Inject('TABLE_NAME') private TABLE_NAME:string,
    @Inject('TABLE_LIST') private TABLE_LIST:string[],
    @Inject('FILTERS') private FILTERS: {name:string,value:string}[] | 'no-filter',
  ) {
    this.TableName = this.TABLE_NAME
    this.tableList = this.TABLE_LIST
    if (this.TableName === 'getImagePromo') this.fnDataName = this.TableName;
    if(FILTERS !== 'no-filter' && Array.isArray(FILTERS))  this.filters = this.FILTERS as {name:string,value:string}[]
  }

  ngOnInit(): void {
    this.initEstructure();
  }

  async initEstructure() {
    const { Data } = this._SesionstorageService.GetSesionStorage('login')
    const actions = await this.auth.retrieveActions(Data?.idRol)
    const options = actions.filter((e: any) => e.Componente === 'BannersComponent' && e.Tipo === 'Table')
    this.optionsMenu = options ? this.helper.sortOptions(options) : []
  }

}


@Component({
  selector: 'app-secondary-banners',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [filterBy]="filters" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [dragable]="false" [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide:"TABLE_NAME",useValue: "cb_LandingBanner"},
    {provide:"TABLE_LIST",useValue: ['cb_LandingBanner']},
    {provide: "FILTERS",useValue:[{ name: "type", value: "secondary" }]}
  ]
})
export class SecondaryBanners extends BannerCompStructure {  }

@Component({
  selector: 'app-primary-banners',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [filterBy]="filters" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [dragable]="false" [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide:"TABLE_NAME",useValue: "cb_LandingBanner"},
    {provide:"TABLE_LIST",useValue: ['cb_LandingBanner']},
    {provide: "FILTERS", useValue: [{ name: "type", value: "primary" }]}
  ]
})
export class PrimaryBanners extends BannerCompStructure { }

@Component({
  selector: 'app-banners-app-primary',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations"  [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide: 'TABLE_NAME',useValue: 'cb_AdvertisingBanner'},
    {provide: 'TABLE_LIST',useValue: ['cb_AdvertisingBanner']},
    {provide: 'FILTERS', useValue: 'no-filter'}
  ]
})
export class BannersAppPrimary extends BannerCompStructure {}

@Component({
  selector: 'app-banners-app-secondary',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations"  [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide: 'TABLE_NAME', useValue: 'cb_BannerPublic'},
    {provide: 'TABLE_LIST', useValue: ['cb_BannerPublic']},
    {provide: 'FILTERS', useValue: 'no-value'}
  ]
})
export class BannersAppSecondary extends BannerCompStructure { }

// @Component({
//   selector: 'app-banners-app-secondary',
//   standalone: true,
//   imports: [SharedModule, CommonModule],
//   template: `<app-main-crud [TableName]="TableName" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [optionsDropdown]="optionsMenu" [fnDataName]="fnDataName"></app-main-crud>`,
//   styleUrls: ['./banners.component.scss'],
//   providers: [
//     {provide: 'TABLE_NAME', useValue: 'getImagePromo'},
//     {provide: 'TABLE_LIST', useValue: ['getImagePromo']},
//     {provide: 'FILTERS', useValue: 'no-value'}
//   ]
// })


@Component({
  selector: 'app-popup-app-clubfibex',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [filterBy]="filters" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations" [dragable]="false" [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide:"TABLE_NAME",useValue: "cb_LandingBanner"},
    {provide:"TABLE_LIST",useValue: ['cb_LandingBanner']},
    {provide: "FILTERS",useValue:[{ name: "type", value: "popUp" }]}
  ]
})

export class PopUpClubFibex extends BannerCompStructure {  }

//export class BannersOficinaMovil extends BannerCompStructure { }

/* @Component({
  selector: 'app-allied-brand-banners',
  standalone: true,
  imports: [SharedModule, CommonModule],
  template: `<app-main-crud [TableName]="TableName" [filterBy]="filters" [attributes]="attributes" [tableList]="tableList" [tableRelations]="tableRelations"  [optionsDropdown]="optionsMenu"></app-main-crud>`,
  styleUrls: ['./banners.component.scss'],
  providers: [
    {provide:"TABLE_NAME",useValue: "cb_LandingBanner"},
    {provide:"TABLE_LIST",useValue: ['cb_LandingBanner']},
    {provide: "FILTERS", useValue: [{ name: "type", value: "alliedMark" }]}
  ]
})
export class AlliedBrandBanners extends BannerCompStructure { } */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {
  /**
   * Constructor del servicio
   */
  constructor() { }

  /**
   * Permite obtener la ubicación actual del usuario
   * @returns Ubicacion actual
   */
  public getUserLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      try {
        if('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve(position);
            },
            (err) => {
              reject(err);
            }
          )
        }
        else reject(new Error("Geolocation no disponible"));
      }
      catch(err) {
        reject(err);
      }
    })
  }
}
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ChartJsService } from 'src/app/services/chart-js.service';
import { ChartsData, MoreThanOneDataSetChart } from '../../interfaces/Charts';

Chart.register(...registerables)

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnChanges {
  @Input() dataSimpleToChart: any;
  @Input() dataDoubleChart: any;
  @Input() idChart: any[] = [];

  constructor( private chartService: ChartJsService ) {}
  
  ngOnChanges(): void {
    if( this.dataSimpleToChart instanceof Object && this.idChart.length > 0 ) {
      this.renderGenericChart(this.dataSimpleToChart);
    }
    
    if( this.dataDoubleChart instanceof Object && this.idChart.length > 0 ) {
      this.renderDoubleChart(this.dataDoubleChart)
    } 
  }

  /*
    @function renderGenericChart() permite generar una grafica que trabaje en funcion a un solo set de datos
    @function renderDoubleChart() permite generar una grafica que trabaje con dos set de datos

    @params { dataTotal } recibe la data utilizada para generar las diferentes graficas, verificar interfaces en caso de dudas respecto a la estructura de los datos
  */ 

  renderGenericChart(dataTotal: ChartsData[]) {
    try {
      const data = {
        labels: dataTotal.map(row => row.mes),
        datasets: [
          {
            label: this.idChart[0].title,
            data: dataTotal.map(row => row.cantidad),
            tension: .5,
            borderColor: this.chartService.getDataColors()[0],
            backgroundColor: this.chartService.getDataColors(70),
            fill: true,
            pointRadius: 10,
            pointHoverRadius: 15
          }
        ]
      }
  
      const options = {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
  
      new Chart(this.idChart[0].id, {type: this.idChart[0].typeChart, data, options})
    } catch (error) {
      console.log(error)
    }
  }

  renderDoubleChart( dataTotal: MoreThanOneDataSetChart ) {
    const labels = dataTotal.cuponesGenerados?.map((row: any) => row.mes);
    const data = {
      labels: labels,
      datasets: [
        {
          label: this.idChart[0].title,
          data: dataTotal.cuponesGenerados?.map((row: any) => row.cantidad),
          borderColor: this.chartService.getDataColors()[0],
          backgroundColor: this.chartService.getDataColors(60),
        },
        {
          label: this.idChart[1].title,
          data: dataTotal.cuponesUsados?.map((row: any) => row.cantidad),
          borderColor: this.chartService.getDataColors()[3],
          backgroundColor: this.chartService.getDataColors(60),
        }
      ]
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true
        }
      }
    }

    new Chart(this.idChart[0].id, {type: this.idChart[0].typeChart, data, options});
  }
}

<!-- <h1 class="titulo">Mapa Leaflet con angular</h1> -->
<div class="mt-4" style="display: flex; gap: 10px;" (focusin)="FocusInputSearch()" (focusout)="BlurInputSearch()">
    <button type="button" class="btn btn-outline-success mr-2" (click)="MyLocation()" [disabled]="disabledButton">Geolocalización</button>
    <div class="input-group" style="position: relative;">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"/></svg>
        </span>
      </div>
      <input type="text" class="form-control" placeholder="Buscar ubicación" aria-label="Buscar ubicación" aria-describedby="basic-addon1"
        (input)="onChangeSearch($event)">

      <div *ngIf="activeInputToSearch && (searched || searching)"
          class="dropdown-menu" style="position: absolute; top: 100%; left: 0; width: 100%; display: block; z-index: 10000;"
      >
        <div *ngIf="searching" style="text-align: center; padding: 10px 0px;">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <h6 *ngIf="!locationSearched.length && searched" class="dropdown-header">Sin resultados</h6>


        <div *ngIf="locationSearched.length && !searching">
          <h6 class="dropdown-header" style="border-bottom: 1px solid rgba(0,0,0,0.1);">Búsquedas relacionadas:</h6>

          <a *ngFor="let location of locationSearched" class="dropdown-item" href="#" style="border-bottom: 1px solid rgba(0,0,0,0.1);" (click)="$event.preventDefault(); SelectLocation(location)">
            <div>
              {{ location.name }}
            </div>
            <div style="font-size: 12px; color: rgba(0,0,0,0.4); overflow: hidden; text-overflow: ellipsis;">
              {{ location.display_name }}
            </div>
          </a>
        </div>
      </div>
    </div>
</div>
<hr>
<div class="map" #map_container></div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SesionstorageService } from '../services/sesionstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCanActiavteGuard implements CanActivate {
  constructor(
    private _SesionstorageService:SesionstorageService,
    private _router: Router,
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this._SesionstorageService.GetSesionStorage('login')
    if(user){
      this._router.navigate(['/dashboard/home']);
      return false
    };
    return true;
  }
  
}

import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericSnackbarService {

  public modalType: any
  public msgBoxArea: any
  public msgboxTimeout: any

  constructor() {
    this.msgBoxArea = document.querySelector("#msgbox-area");
    if (this.msgBoxArea === null) {
      this.msgBoxArea = document.createElement("DIV");
      this.msgBoxArea.setAttribute("id", "msgbox-area");
      this.msgBoxArea.classList.add(`msgbox-area`);
      document.body.appendChild(this.msgBoxArea);
    }
  }

  show(option: any, callback?: any) {
    if (option.msg === "" || option.msg === undefined || option.msg === null) {
      throw "Message is empty or not defined.";
    }

    if (option.closeLabel === undefined || option.closeLabel === null) {
      option.closeLabel = "Cerrar";
    }

    const msgboxBox = document.createElement("DIV");
    const msgboxContent = document.createElement("DIV");
    const msgboxCommand = document.createElement("DIV");
    const msgboxClose = document.createElement("A");
    // Content area of the message box
    msgboxContent.classList.add("msgbox-content");
    msgboxContent.innerText = option.msg;
    // Command box or the button container
    msgboxCommand.classList.add("msgbox-command");
    // Close button of the message box
    msgboxClose.classList.add("msgbox-close");
    msgboxClose.setAttribute("href", "#");
    msgboxClose.innerText = option.closeLabel;
    // Container of the Message Box element
    msgboxBox.classList.add("msgbox-box");
    msgboxBox.classList.add("alert");

    // Legend of snackbar types
    // Info --> 0
    // Success --> 1
    // Warning --> 2
    // Error --> 3
    const snackbarTypes: any = {
      0: 'alert_info',
      1: 'alert_success',
      2: 'alert_warning',
      3: 'alert_error'
    }
    let snackColor = snackbarTypes[option?.snackType] || 'alert_info'

    msgboxBox.classList.add(snackColor);
    msgboxBox.appendChild(msgboxContent);

    if (option.hideCloseButton === false
      || option.hideCloseButton === undefined) {
      // If the hideCloseButton flag is false, or if it is undefined
      // Append the close button to the container
      msgboxCommand.appendChild(msgboxClose);
      msgboxBox.appendChild(msgboxCommand);
    }

    this.msgBoxArea.appendChild(msgboxBox);
    msgboxClose.onclick = (evt) => {
      evt.preventDefault();
      if (msgboxBox.classList.contains("msgbox-box-hide")) {
        return;
      }
      clearTimeout(this.msgboxTimeout);
      this.msgboxTimeout = null;
      this.hide(msgboxBox, callback);
    };

    if (option.closeTime > 0) {
      this.msgboxTimeout = setTimeout(() => {
        this.hide(msgboxBox, callback);
      }, option.closeTime);
    }
  }

  hideMessageBox(msgboxBox: any) {
    return new Promise<void>(resolve => {
      msgboxBox.ontransitionend = () => {
        resolve();
      };
    });
  }

  async hide(msgboxBox: any, callback: any) {
    if (msgboxBox !== null) {
      // If the Message Box is not yet closed
      msgboxBox.classList.add("msgbox-box-hide");
    }
    await this.hideMessageBox(msgboxBox);
    this.msgBoxArea.removeChild(msgboxBox);
    clearTimeout(this.msgboxTimeout);
    if (typeof callback === "function") {
      // If the callback parameter is a function
      callback();
    }
  }

  runSnackBarNoCallback(msg: string, snackType: number, hideCloseButton?: boolean, closeTime?: number, closeLabel?: string) {
    let snackparams = {
      msg: msg,
      snackType: snackType,
      closeTime: closeTime,
      hideCloseButton: hideCloseButton,
      closeLabel: closeLabel
    }
    this.show(snackparams);
  }
}

// Example with callback
/*
  Legend of snackbar types
    Info --> 0
    Success --> 1
    Warning --> 2
    Error --> 3
    let snackparams = {
      msg: "Hello! I'm snackbar.",
      snackType: 2,
      closeTime: 2000,
      hideCloseButton: false,
      closeLabel: 'Cerrando'
    }
    this.show(snackparams, () => {
    console.log("I am the callback! Of course, you may add various javaScript codes to make the callback function colourful.");},);
*/

<div class='{{"extensible-select-input" + (opened ? " opened" : "")}}'>
    <input 
        readonly 
        type="text" 
        class="form-control" 
        (focus)="Focus($event)" 
        (blur)="OnBlur()" 
        (click)="Click($event)"
        [value]="getValue()"
    />
    <i class="aside-icon"></i>
    <div class="main-menu">
        <div *ngIf="!Field.length" class="menu-item-extensible-text">
            Sin contenido
        </div>
        <button type="button" class="{{'menu-item-extensible' + (isSelected(opt.value) ? ' selected' : '')}}" *ngFor="let opt of Field" (click)="SelectItem(opt.value)" (focus)="Focus($event)" (blur)="OnBlur()" >{{opt.datoShow}}</button>
    </div>
</div>
<div class="content">

  <div class="map-container" #mapdiv></div>
  
  <div class="search-container">
    <input  class="search-input" 
            placeholder="Busca un lugar..." 
            #txtQuery
            (keyup)="onQueryChanged(txtQuery.value)">
    <div class="search-results" *ngIf="placesData.length > 0">
  
      <ul class="list-group">
        <li *ngFor="let place of placesData" 
            class="list-item"
            [class.active]="selectedId === place.id">
          <h6 class="title-place"> {{ place.text_es }} </h6>
          <p class="text-place"> {{ place.place_name }} </p>
  
          <button class="direction" color="primary" mat-raised-button (click)="getSelectedPlace(place)">Dirección</button>
        </li>
      </ul>
    </div>
  
  </div>

  <button type="button" class="ubi btn btn-primary" (click)="goToOriginalLocation()">Ubicación principal</button>
</div>


<div class="bgfibexclub">
  <div class="signpanel-wrapper">
    <div class="signbox box-login">
      <div class="signbox-header">
        <!-- <h2>Club Fibex</h2> -->
        <!-- <p class="mg-b-0">El dashboard admin de Fibex Telecom</p> -->
        <img width="100%" src="../../../assets/fibexClub/fibexClubLogo.png" alt="">
      </div><!-- signbox-header -->
      <div class="signbox-body">
        <form [formGroup]="form" (ngSubmit)="loginv2(form)">
          <div class="form-group">
            <label for="login" class="form-control-label fw-600" style="font-weight: 600;">Usuario:</label>
            <input type="text" id="login" class="form-control" formControlName="login" placeholder="Ingresa tu usuario" [required]="true">
            <div *ngIf="loginControl?.invalid && (loginControl?.dirty || loginControl?.touched)" class="error-msg">
              <small *ngIf="loginControl?.errors?.['required']">Este campo es obligatorio.</small>
            </div>
          </div><!-- form-group -->
          <div class="form-group">
            <label for="password" class="form-control-label" style="font-weight: 600;">Contraseña:</label>
            <input type="password" id="password" class="form-control" formControlName="password" placeholder="Ingresa tu contraseña" [required]="true">
            <div *ngIf="passwordControl?.invalid && (passwordControl?.dirty || passwordControl?.touched)" class="error-msg">
              <small *ngIf="passwordControl?.errors?.['required']">Este campo es obligatorio.</small>
            </div>
          </div><!-- form-group -->
          <!-- <div class="form-group" style="display:flex; justify-content:center; ">
            <a href="#" style="font-weight: 600;">¿Se te olvidó tu contraseña?</a>
          </div> -->
          <!-- form-group -->
          <hr/>
          <button type="submit" class="btn btn-block text-light" style="background: #0793df; font-weight: 600;" [disabled]="form.invalid || isSubmitting">
            <span *ngIf="!isSubmitting">Ingresar</span>
            <span *ngIf="isSubmitting">Ingresando...</span>
          </button>
          <div *ngIf="errorMessage" class="error-msg">
            <small>{{ errorMessage }}</small>
          </div>
          <!-- Crear una nueva cuenta estaba comentado -->
          <!-- <div class="tx-center bg-white bd pd-10 mg-t-20" style="font-weight: 600;">¿No tienes una cuenta? <br> <a routerLink='/signup'>Crea una nueva cuenta</a></div> -->
        </form>
      </div><!-- signbox-body -->
    </div><!-- signbox -->
  </div><!-- signpanel-wrapper -->
</div>

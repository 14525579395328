import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class SeguridadDatos {
  key: string = environment.SecuryEncrypt
  constructor() { }

  //Encripta la los datos
  public encrypt(str: string) {
    let encrypted = CryptoJS.AES.encrypt(str, this.key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  Desencrypt2(str: any) {
    let decrypted = CryptoJS.AES.decrypt(str, this.key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted;
  }

  decrypt(str: string) {
    let decrypted = CryptoJS.AES.decrypt(str, this.key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    //console.log("decrypt", decrypted.toString())
    return decrypted.toString()
  }

  private EncrypObj(obj: any) {
    const newObj: any = {};
    for (let keyName in obj) {
      if (obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
        obj[keyName] = this.EncrypObj(obj[keyName]);
      }
      else newObj[keyName] = String(this.encrypt(obj[keyName]));
    }
    return newObj;
  }

  // Encripta los datos que que deben ser enviados en una peticion
  public EncrypDataHash(Datos: any) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.EncrypObj(Datos));
      } catch (error) {
        reject(error);
      }
    })
  }

  public DescrypValidateType<T>(Datos: any | T): any | T {
    try {
      let Data: any | T = CryptoJS.AES.decrypt(Datos.message, this.key, {
        keySize: 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      if (typeof Data == "string") {console.log("pasoporaquii"); return JSON.parse(Data) } else {console.log("nospaso"); return Data }
    } catch (error) {
      console.error(error)
    }
  }
}

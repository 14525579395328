<!-- <app-list-table-crud [tableArray]="tableArray" [tableRelations]="tableRelations" [TableName]="TableName" [Atributos]="Atributos"></app-list-table-crud> -->
<app-main-crud 
    [TableName]="TableName" 
    [filterBy]="filterBy" 
    [attributes]="attributes" 
    [tableList]="tableList" 
    [tableRelations]="tableRelations" 
    [optionsDropdown]="optionsMenu" 
    [beforeSubmit]="BeforeSubmit"
    (SalidaClickMenu)="ClickMenu($event)"
></app-main-crud>
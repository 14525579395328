import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { ISelectOptions } from "src/app/core/interfaces/actions.interface";
import { AuthService } from "src/app/services/auth.service";
import { HelperService } from "src/app/services/helper.service";
import { SesionstorageService } from "src/app/services/sesionstorage.service";

@Component({
  selector: 'app-featured-structure',
  standalone: true,
  template:'',
  styleUrls: [],
  imports: [CommonModule]
})
export class FeaturedStructureComponent implements OnInit {
  public optionsDropdown: { id: number, name: string, icon: string, tableList?: string[], idAccion?: string }[] = [];
  public optionsMenu: { id: number, name: string, icon: string }[] = [];
  public tableList:string[] = []
  public tableRelations:unknown[] = []
  public TableName:string = ''
  public attributes:{title:string,description:string} = { title: '', description: '' }
  public filters: {name:string,value:string}[] = [
    { name: "type", value: "primary" },
    { name: "type", value: "secondary" }
  ]
  public fnDataName: string = '';
  public canShowInputs = []

  constructor(
    private helper: HelperService,
    private auth: AuthService,
    private _SesionstorageService: SesionstorageService,
    @Inject('TABLE_NAME') private TABLE_NAME:string,
    @Inject('TABLE_LIST') private TABLE_LIST:string[],
    @Inject('TABLE_RELATIONS') private TABLE_RELATIONS:string[],
    @Inject('ATTRIBUTES') private ATTRIBUTES:{title:string,description:string},
  ) {
    this.TableName = this.TABLE_NAME;
    this.tableList = this.TABLE_LIST;
    this.tableRelations = this.TABLE_RELATIONS;
    this.attributes = this.ATTRIBUTES;
  }

  ngOnInit(): void {
    this.initEstructure();
  }

  async initEstructure() {
     const { Data } = this._SesionstorageService.GetSesionStorage('login')
    const actions = await this.auth.retrieveActions(Data?.idRol)
    const options = actions.filter((e: any) => e.Componente === 'MasterFeaturedPromosComponent' && e.Tipo === 'Table') 
    // const options: ISelectOptions[] = [
    //   {
    //   Accion: "ELIMINAR DESTACADO",
    //   Componente: "MasterPromotionsComponent",
    //   Tipo: "Table",
    //   Url: "promociones/consulta",
    //   idAccion: "ExBv_0W2dNWjkpqF3",
    //   idPermiso: "LFd7WNeeJFWtGbm13",
    //   idRol: "tMaCv6CZjU1yx_hp3",
    // }
  // ]
    this.optionsMenu = options ? this.helper.sortOptions(options) : []
  }

}

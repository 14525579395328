<app-select-list *ngIf="TipoComponente=='1'" 
    [defaultValue]="value" 
    [Field]="DataSendComponent" 
    [TitleSow]="TitleStr" 
    [idSelected]="idToSelect"
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-select-list>

<!-- Radio -->
<app-radio-list *ngIf="TipoComponente=='2'" 
    [defaultValue]="value" 
    [Field]="DataSendComponent" 
    [TitleSow]="TitleStr" 
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-radio-list>

<!-- Check -->
<app-check-list *ngIf="TipoComponente=='3'" 
    [value]="value" 
    [Field]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-check-list>

<!-- Select with Insert (ActualizarDatos)="ActualizarDatos($event)" -->
<app-extensible-select *ngIf="TipoComponente=='7'" 
    [value]="value" 
    [Field]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    [TableName]="TableName" 
    (AddItemData)="AddItemData($event)"
    (UpdateItemData)="UpdateItemData($event)"
    (RemoveItemData)="RemoveItemDato($event)"
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-extensible-select>

<!-- Multi Selct -->
<app-extensible-select *ngIf="TipoComponente=='8'" 
    [multiple]="true" 
    [value]="value" 
    [Field]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    [TableName]="TableName" 
    (AddItemData)="AddItemData($event)"
    (UpdateItemData)="UpdateItemData($event)"
    (RemoveItemData)="RemoveItemDato($event)"
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-extensible-select>

<!-- Card -->
<app-cards *ngIf="TipoComponente=='9'" 
    [value]="value" 
    [Fields]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-cards>

<!-- Tab -->
<app-taps *ngIf="TipoComponente=='10'" 
    [value]="value" 
    [Fields]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-taps>


<!-- Lista Tipo Chat -->
<app-list-table *ngIf="TipoComponente=='11'" 
    [value]="value" 
    [Fields]="DataSendComponent" 
    [TitleShow]="TitleStr" 
    (ValueSalida)="GetDataSelect($event)"
    [disabledInput]="disabledItem"
></app-list-table>

<!-- <app-list-select *ngIf="TipoComponente=='12'" 
    [Field]="DataSendComponent" 
    [TitleSow]="TitleStr" 
    [idSelected]="idToSelect"
    (ValueSalida)="GetDataSelect($event)"
></app-list-select> -->
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { TipoInput } from 'src/app/models/tipo.input';
import { IFormData } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-extensible-select',
  templateUrl: './extensible-select.component.html',
  styleUrls: ['./extensible-select.component.scss']
})
export class ExtensibleSelectComponent {
  @ViewChild("options_display", {static: true}) options_display!: ElementRef<HTMLDivElement>;
  @Input() disabledInput: boolean | number=0;
  @Input() Field: {datoShow: string, value: string, element: {[key: string]: any}}[] = [];
  @Input() TitleShow: string = "";
  @Input() formData: IFormData = new IFormData();
  @Input() value: string | string[] = "";
  @Input() TableName: string = "";
  @Input() multiple: boolean = false;
  @Output() AddItemData = new EventEmitter<IFormData["vars"]>();
  @Output() RemoveItemData = new EventEmitter<number>();
  @Output() UpdateItemData = new EventEmitter<{index: number, data: IFormData["vars"]}>
  @Output() ValueSalida = new EventEmitter<string | string[]>()
  // @Input() data: TipoInput = {} as any;
  // @Input() options: string[] = [];
  // @Input() value: any = "";

  public optionsDropdown = [
    {id:1, name: 'Eliminar', icon:'icon ion-trash-a'},
    // {id:2, name:'Editar', icon:'icon ion-edit'}
  ]
  
  public document = document;
  public typeSave: "Create" | "Update" = "Create";

  alert() {
    window.alert("YOGO")
  }
  
  constructor() {
    // console.log("SELECT MULTIPLE =======================================================================================")
  }

  opened: boolean = false;
  recupered: boolean = true;
  avariable: boolean = false;
  timer: NodeJS.Timer | null = null;

  modal: boolean = false;
  valueModal: string = "";
  errorModal: string = "";
  indexActiveElement: number | undefined = 0;
  public valueSelected?: string;
  public selectedElement: any;

  // HanderOnChange(event: any) {
  //   this.ValueSalida.emit()
  //   delete this.formData.error[this.data.Campo];
  //   this.formData.vars[this.data.Campo] = event.target.value;
  // }

  getValue() {
    if(this.multiple) {
      const values = this.value instanceof Array ? this.value : (typeof this.value === "string" && this.value ? [this.value] : []);

      return (this.Field.filter((f: any) => values.some(v => v === f.value))).map((f: any) => f.datoShow).join(", ");
    }
    return this.Field.find((f: any) => f.value === this.value)?.datoShow ?? "";
  }

  Focus() {
    this.opened = true;
    this.recupered = true;

    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.avariable = true;
    }, 500)
  }
  
  OnBlur() {
    this.recupered = false;

    setTimeout(() => {
      if(!this.recupered) {
        this.opened = false;
    
        if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.avariable = false;
        }, 500)
      }
    })
  }

  Click(ev: any) {
    if(this.avariable) {
      this.opened = !this.opened;
    }
  }

  onSelectAction(field: any, index: number, option: any) {
    // console.log("HERE FUEKD DASDASDASD:", option, field);
    this.selectedElement = option;
    this.selectedElement.element = field.element;
    
    switch(option.type) {
      case "ELIMINAR":
        // console.log("HERE DELETE")
        const elements: ChildNode[] = [];
        this.options_display.nativeElement.childNodes.forEach(element => {
          if(element.nodeName === "BUTTON") {
            elements.push(element);
          }
        });
        elements.splice(index+1, 1);

        // console.log("CHILDS:::", this.options_display.nativeElement.childNodes)
        // console.log("HERE DELETE::::", elements, (elements[index+1] || elements[index]));
        
        ((elements[index+1] || elements[index]) as any)?.focus();
        this.RemoveItemData.emit(index);
        break;

      case "EDITAR":
        this.OpenModal(index);
        if(!field.pk) {
          const valueTemporal = "@valor_temporal_" + Date.now();
          field.pk = {
            Campo: "@id_temporal",
            Valor: valueTemporal
          }
          option.element["@id_temporal"] = valueTemporal;
        }
        this.valueSelected = field.pk.Campo + "_" + field.pk.Valor;

        // console.log("CAMPO VALOR::::::::::::::::::::::::", this.valueSelected);
        // console.log("FIELD:", field);
        // console.log("OPTION:", option);
        break;
    }
  }

  SelectItem(value: string) {
    if(!this.multiple) {
      this.opened = false;
    }
    if(this.multiple) {
      let valueArray = this.value;

      if(!(valueArray instanceof Array)) {
        if(typeof valueArray === "string") {
          valueArray = [valueArray];
        }
        else valueArray = [];
      }

      const index = valueArray.findIndex(v => v === value);

      if(index !== -1) {
        valueArray.splice(index, 1);
      }
      else {
        valueArray.push(value);
      }

      this.ValueSalida.emit(valueArray);
    }
    else {
      this.ValueSalida.emit(value);
    }
    // this.formData.vars[this.data.Campo] = value;
  }

  OpenModal(index?: number) {
    setTimeout(() => {
      this.OnBlur();
      this.modal = true;
      this.valueModal = "";
      this.indexActiveElement = index;

      if(typeof index === "number") {
        this.typeSave = "Update";
      }
      else {
        this.typeSave = "Create";
        this.valueSelected = undefined;
      }
    })
  }

  CloseModal() {
    this.modal = false;
  }

  onChange(ev: any) {
    this.valueModal = ev.target.value;
    // console.log("VALUE MODAL:", this.valueModal);
  }

  ClickContainer(ev: any) {
    ev.stopPropagation();
  }

  AddValueForm(ev: any) {
    ev.preventDefault();

    if(this.valueModal) {
      // this.options.push(this.valueModal);
      this.CloseModal();
    }
    else this.errorModal = "Requerido*";
  }

  Salida(ev: any) {
    switch(ev.evento) {
      case "cerrar":
      case "guardar":
        this.CloseModal();
        break;
    }
  }

  ngOnInit() {
    // console.log(this.Field);
  }

  isSelected(value: string) {
    if(this.multiple && this.value instanceof Array) {
      return this.value.includes(value);
    }

    return this.value === value;
  }


  RegisterToSubmiInEnd = (vars: IFormData["vars"]) => {
    this.CloseModal();
    if(this.typeSave==="Create") {
      this.AddItemData.emit(vars);
    }
    else {
      if(typeof this.indexActiveElement === "number") {
        this.UpdateItemData.emit({index: this.indexActiveElement, data: vars});
      }
    }
    // this.ActualizarDatos.emit(vars);
  }
}

<app-new-user-sidebar-stepper
  [element]="element"
  [params]="local_params"
  [excelColumnsNames]="excelColumnsNames"
  [optionToExecute]="optionToExecute"
  [dataSelected]="dataSelected"
  [idEmpConvCreate]="idEmpConvCreate"
  [relations]="relations"
  [noClose]="noClose"
  [onSubmit]="onSubmit"
  [beforeSubmit]="beforeSubmit"
  (SalidaClickMenu)="SalidaClickMenu.emit($event)"
></app-new-user-sidebar-stepper>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isArray } from 'jquery';
import { AuthService } from 'src/app/core/services/auth.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { ilogin } from 'src/app/core/interfaces/Ilogin.interface';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LogsService } from 'src/app/services/logs.service';
import { CleanCacheService } from 'src/app/services/clean-cache.service';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { DecodedtokenService } from 'src/app/services/decodedtoken.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  form!: FormGroup;
  private _auth!: ilogin;
  public username: string = '';
  public password: string = '';
  public msgErr: string = '';
  public isSubmitting: boolean = false;
  public errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private dbFullService: dbFullService,
    private router: Router,
    private confServ: ConfigurationService,
    private helper: HelperService,
    private logs: LogsService,
    private cacheServ: CleanCacheService,
    private auth: AuthService,
    private _SesionstorageService:SesionstorageService,
    private _DecodedtokenService:DecodedtokenService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Inicializa el formulario de inicio de sesión.
   */
  initForm(): void {
    this.form = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * Realiza el inicio de sesión del usuario.
   * @param form El formulario con los datos de inicio de sesión del usuario.
   */
  // async login(form: FormGroup): Promise<void> {
  //   try {
  //     this.isSubmitting = true;
  //     this.errorMessage = '';
  //     const { login, password } = form.value;
      
  //     const sanitizedLogin: string = this.helper.sanitizeString(login);
  //     const sanitizedPassword: string = this.helper.sanitizeString(password);
      
  //     this.dbFullService.getLoginDataQuery('cb_EmpresaUser', sanitizedLogin, sanitizedPassword).then((res: any) => {
  //       console.log('cb_EmpresaUser res', res)
  //       if (!res || res.length <= 0) {
  //         this.dbFullService.getLoginDataQuery('cb_UserInterno', sanitizedLogin, sanitizedPassword).then((res: any) => {
  //           console.log('cb_UserInterno res', res)
  //           if (res.length > 0 && res[0].Status === '1') {
  //             this.GetTokenAuthJWT(sanitizedLogin,sanitizedPassword)
  //             this._auth = res[0];
  //             // console.log("Login get custom string")
  //             this.dbFullService.getPermissionsQuery(res[0].idRol).then(res => {
  //           // console.log("Authentication:", this._auth);
  //           this._SesionstorageService.SetSesionStorage('login',this.customStringify({
  //             ...this._auth,
  //             // admin: true
  //           }))
  //             // sessionStorage.setItem('login', this.customStringify({
  //             //   ...this._auth,
  //             //   admin: true
  //             // }));
  //             // this.createLog(res[0])
  //             this.confServ.loadConfigurationData(res).then(res=>{
  //               this.router.navigate(['/dashboard/home']);
  //             })
  //           })
  //           } else {
  //             this.msgErr = 'Clave Inválida'
  //             // console.log('Clave Inválida')
  //             this.errorMessage = 'Usuario o contraseña incorrectos.';
  //           }
  //         });
  //       } else {
  //         this.GetTokenAuthJWT(sanitizedLogin,sanitizedPassword)
  //         console.log(this._SesionstorageService.GetSesionStorage('tknlg'))
  //         this._auth = res[0];

  //         // this.createLog(res[0])
  //         // console.log(res[0])
  //         this.dbFullService.getPermissionsQuery(res[0].idRol).then(res => {
  //           // console.log("ROL:", res);
  //           // console.log("User cliente:", this._auth);
  //           this._SesionstorageService.SetSesionStorage('login',this.customStringify({
  //             ...this._auth,
  //             // admin: false
  //           }))
  //         // sessionStorage.setItem('login', this.customStringify({
  //         //   ...this._auth,
  //         //   // admin: ((res as any)[0].idRol) !== "uiVGBPD5Jcw2cJV8" // <== id del rol de usuario (no adminitrador)
  //         //   admin: false
  //         // }));
  //           this.confServ.loadConfigurationData(res).then(res=>{
  //             this.router.navigate(['/dashboard/home']);
  //           })
  //         })
  //       }
  //     })
  //   } catch (error) {
  //     console.error(error);
  //     this.errorMessage = 'Ha ocurrido un error al iniciar sesión. Por favor, intenta de nuevo más tarde.';
  //   } finally {
  //     this.isSubmitting = false;
  //   }
  // }

  async loginv2(form: FormGroup): Promise<void> {
    try {
      this.isSubmitting = true;
      this.errorMessage = '';
      const { login, password } = form.value;
      
      const sanitizedLogin: string = this.helper.sanitizeString(login);
      const sanitizedPassword: string = this.helper.sanitizeString(password);

      const jwt :string | null = await this.GetTokenAuthJWT(sanitizedLogin,sanitizedPassword)

      const user = this._DecodedtokenService.DecodedToken(jwt)

    //   console.log(user)

      if(user){

        const { Data, exp, iat } = user


        // if(new Date(exp) >)

        const permissions = await this.dbFullService.getPermissionsQuery(Data.idRol)

        // console.log(permissions)

        this._SesionstorageService.SetSesionStorage('login',this.customStringify({ ...user }))

        

        this.confServ.loadConfigurationData(permissions).then((res: any) => {
          this.router.navigate(['/dashboard/home']);
        })

      }else{
        this.msgErr = 'Clave Inválida'
        this.errorMessage = 'Usuario o contraseña incorrectos.';

      }

    } catch (error) {
      console.error(error);      
      this.errorMessage = 'Ha ocurrido un error al iniciar sesión. Por favor, intenta de nuevo más tarde.';

    } finally {

      this.isSubmitting = false;
    }
  }

  customStringify(obj: any): string {
    // const seen = new WeakSet();
    return JSON.stringify(obj
      // , (key, value) => {
      //   if (typeof value === 'object' && value !== null) {
      //     if (seen.has(value)) {
      //       return;
      //     }
      //     seen.add(value);
      //   }
      //   return value;
      // }
    );
  }

  async GetTokenAuthJWT(user:string,password:string){
    let Data ={
      login:user,
      password:password
    }
     return await this.dbFullService.GetTokenJWT(Data);
  }

  /**
   * Crea un registro de inicio de sesión en los logs del sistema.
   * @param loginParams Los parámetros del inicio de sesión.
   */
  async createLog(loginParams: any): Promise<void> {
    const { idUser, idUserEmp } = loginParams

    if (idUser) {
      const action = await this.helper.retrieveAction('Login')

      this.logs.createLog(idUser, action, 'Inicio de sesión').then(resp => {

      }).catch(error => console.log(error))

    }else if(idUserEmp){
      const action = await this.helper.retrieveAction('Login')

      this.logs.createLog(idUserEmp, action, 'Inicio de sesión').then(resp => {

      }).catch(error => console.log(error))
    }
  }

  get loginControl() {
    return this.form.get('login');
  }

  get passwordControl() {
    return this.form.get('password');
  }
}

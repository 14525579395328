import { Injectable } from '@angular/core';
import { SeguridadDatos } from './bscript.service';

@Injectable({
  providedIn: 'root'
})
export class SesionstorageService {

  constructor( private security: SeguridadDatos ) { }


  GetSesionStorage(str:string){

    let DataSesionS = sessionStorage.getItem(str);
    if(DataSesionS){
      let Desecripte =this.security.decrypt(DataSesionS)
      //console.log("GetSesionStorage",JSON.parse((Desecripte as any)));
      return JSON.parse((Desecripte as any))
    }
    return false;
    

  }

  SetSesionStorage(str:string,data:string){
    // console.log("SetSesionStorage");
    // console.log(data);
    let dataEncript= this.security.encrypt(data);
    sessionStorage.setItem(str,dataEncript);
  }

  ClearSesionStorage(){
    sessionStorage.clear();
  }
}
